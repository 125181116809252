<template>
  <v-card class="br-15 pa-3 px-5" elevation="0">
	  <InfoBox>
	  <span class="titulo">Resumo dos investimentos:</span> Nesta tabela é possivel avaliar a evolução dos valores de investimentos do ano atual comparando com o ano anterior, nas visões: PDV, Tablóide, Campanha e Ecommerce.</InfoBox>
    <h2 class="text-center" style="font-weight: normal">
      Resumo dos investimentos
    </h2>

    <div style="height: 247px">
      <template>
        <v-simple-table
          dense
          class="mt-3 tabela-resumo-investimentos"
          v-if="!error && !empty && tableData.length > 0"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center v-data-table-header th-separador">
                  Ferramentas
                </th>
                <th class="text-center v-data-table-header th-separador">
                  {{ ano }}
                </th>
                <th class="text-center v-data-table-header th-separador">
                  {{ ano - 1 }}
                </th>
                <th class="text-center v-data-table-header">Evolução</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in tableData"
                :key="index"
                class="text-center"
              >
                <td>{{ item.visibilidade }}</td>
                <td class="text-center">
                  <formataReal :valor="item.ano_atual" />
                </td>
                <td class="text-center">
                  <formataReal :valor="item.ano_anterior" />
                </td>
                <td class="text-center">{{ calcEvolucao(index) }}%</td>
              </tr>

              <tr class="table-footer">
                <td class="text-center bg-1">Total por período</td>
                <td class="text-center bg-2">
                  <formataReal :valor="ttVisibilidade.ano_atual" />
                </td>
                <td class="text-center bg-1">
                  <formataReal :valor="ttVisibilidade.ano_anterior" />
                </td>
                <td class="text-center bg-2">
                  {{ ttVisibilidade.percent.toLocaleString() }}%
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
      <Overlay :overlay="overlay" :error="error" :empty="empty"></Overlay>
    </div>
  </v-card>
</template>

<script>
import Overlay from "./Overlay.vue";
import InfoBox from "./InfoBox.vue";

export default {
  components: {
    Overlay,
    InfoBox
},
  props: {
    ano: Number,
    fornecedor: Object,
  },
  data() {
    return {
      overlay: true,
      error: false,
      empty: false,
      tableData: [],
    };
  },
  computed: {
    ttVisibilidade() {
      if (this.tableData.length == 0) return {};
      const _tt = this.tableData.reduce(function (total, num) {
        return {
          ano_atual: parseFloat(total.ano_atual) + parseFloat(num.ano_atual),
          ano_anterior:
            parseFloat(total.ano_anterior) + parseFloat(num.ano_anterior),
        };
      });

      _tt["percent"] =
        _tt.ano_anterior > 0
          ? Math.round((_tt.ano_atual / _tt.ano_anterior - 1) * 100)
          : 0;

      return _tt;
    },
  },

  methods: {
    calcEvolucao(index) {
      const _row = this.tableData[index];
      const _calc =
        _row.ano_anterior > 0
          ? (parseFloat(_row.ano_atual) / parseFloat(_row.ano_anterior) - 1) *
            100
          : 0;
      return Math.round(_calc);
    },
    async getDados() {
      this.overlay = true;
      this.error = false;
      this.empty = false;

      const response = await this.$http().post(
        "/simulacao/resumo/resumoInvestimento",
        {
          data: {
            id_fornecedor: this.fornecedor.value,
            ano_atual: this.ano,
            ano_anterior: this.ano - 1,
          },
        }
      );

      //console.log("response=>=>", response);

      if ((response || { status: 500 }).status != 200) {
        this.overlay = false;
        this.empty = true;
        return;
      }

      this.tableData = response.data;

      this.overlay = false;
    },
  },
  watch: {
    $props: {
      handler() {
        //console.log("chamou");
        if (this.ano != null) this.getDados();
      },
      deep: true,
      immediate: false,
    },
  },
};
</script>

<style scoped>
.tabela-resumo-investimentos {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #eaeaea;
  color: #808080 !important;
}
.tabela-resumo-investimentos td,
.tabela-resumo-investimentos th {
  padding: 0.8rem !important;
}
</style>
