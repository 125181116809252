<template>
  <v-container fluid>
    <v-card class="px-5 pt-3 pb-0 br-15 w-100" elevation="0">
      <v-row>
        <v-col class="flex-grow-1">
          <v-toolbar-title class="page-title">Inventário</v-toolbar-title>
        </v-col>
        <v-col cols="3">
          <v-text-field
            class="input-button input-cinza"
            label="Pesquisar"
            v-model="busca"
            outlined
            dense
            hide-details
          >
            <template>
              <v-icon slot="append" color="white">mdi-magnify</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="3" class="pb-0">
          <MesInput
            label="Mês"
            class="pb-0"
            v-model="mesFiltro"
            required
            hide-details
          ></MesInput>
        </v-col>
        <div class="d-flex py-3 px-4">
          <v-btn class="new-button" color="primary" @click="exportar()">
            <v-icon left>mdi-microsoft-excel</v-icon> Exportar
          </v-btn>
          <v-btn
            class="ml-5 new-button"
            color="primary"
            @click="abrirImportacao()"
          >
            <v-icon left>mdi-upload</v-icon> Importar
          </v-btn>
        </div>
      </v-row>
    </v-card>
    <v-card class="px-5 py-5 br-15 w-100 mt-5" elevation="0">
      <v-data-table
        class="elevation-0 tabela"
        :headers="headers"
        :items="dados"
        :items-per-page="10"
        :footer-props="tableConfig"
        :header-props="{ sortIcon: 'mdi-chevron-down' }"
        :options.sync="pagination"
        show-select
        multi-sort
      >
        <template v-slot:[`item.mes`]="{ item }">
          {{ $moment(item.mes).format("MM/YYYY") }}
        </template>
        <template v-slot:[`item.semana_1`]="{ item }">
          <span v-if="!item.semana_1" style="color: #ccc !important">{{
            item.quantidade
          }}</span>
          <span v-else>{{ item.semana_1 }}</span>
        </template>
        <template v-slot:[`item.semana_2`]="{ item }">
          <span v-if="!item.semana_2" style="color: #ccc !important">{{
            item.quantidade
          }}</span>
          <span v-else>{{ item.semana_2 }}</span>
        </template>
        <template v-slot:[`item.semana_3`]="{ item }">
          <span v-if="!item.semana_3" style="color: #ccc !important">{{
            item.quantidade
          }}</span>
          <span v-else>{{ item.semana_3 }}</span>
        </template>
        <template v-slot:[`item.semana_4`]="{ item }">
          <span v-if="!item.semana_4" style="color: #ccc !important">{{
            item.quantidade
          }}</span>
          <span v-else>{{ item.semana_4 }}</span>
        </template>
        <template v-slot:[`item.semana_5`]="{ item }">
          <span v-if="!item.semana_5" style="color: #ccc !important">{{
            item.quantidade
          }}</span>
          <span v-else>{{ item.semana_5 }}</span>
        </template>
      </v-data-table>
    </v-card>
    <importacao ref="importacao" @filtrar="filtrar"></importacao>
    <v-overlay :value="carregando">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import importacao from "./importacao.vue";
export default {
  components: { importacao },
  data() {
    return {
      headers: [
        {
          text: "Ativação",
          value: "ativacao",
          align: "center",
          class: "th-separador",
        },
        {
          text: "Categoria",
          value: "categoria",
          align: "center",
          class: "th-separador",
        },
        {
          text: "Bandeira",
          value: "bandeira",
          align: "center",
          class: "th-separador",
        },
        {
          text: "Quantidade",
          value: "quantidade",
          align: "center",
          class: "th-separador",
        },
        {
          text: "Semana 1",
          value: "semana_1",
          align: "center",
          class: "th-separador",
        },
        {
          text: "Semana 2",
          value: "semana_2",
          align: "center",
          class: "th-separador",
        },
        {
          text: "Semana 3",
          value: "semana_3",
          align: "center",
          class: "th-separador",
        },
        {
          text: "Semana 4",
          value: "semana_4",
          align: "center",
          class: "th-separador",
        },
        {
          text: "Semana 5",
          value: "semana_5",
          align: "center",
          class: "th-separador",
        },
        {
          text: "Mês",
          value: "mes",
          align: "center",
          class: "th-separador",
        },
      ],
      dados: [],
      mesFiltro: this.$moment().format("YYYY-MM"),
      menuProps: {
        bottom: true,
        offsetY: true,
        contentClass: "select-outline",
        transition: "slide-y-transition",
      },
      pagination: {},
      busca: "",
      carregando: false,
      tableConfig: {
        itemsPerPageOptions: [10, 15, 25, 50, 100],
        itemsPerPageText: "Linhas por páginas",
        ofText: "de",
        pageText: "{0}-{1} de {2}",
      },
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    mesFiltro() {
      this.filtrar();
    },
    busca(v) {
      setTimeout(() => {
        if (v == this.busca) {
          this.filtrar();
        }
      }, 1500);
    },
  },
  methods: {
    abrirImportacao() {
      this.$refs.importacao.abrirImportacao();
    },
    async exportar() {
      this.carregando = true;
      var data = { mes: this.mesFiltro + "-01" };
      const response = await this.$http().post(
        "/planejamentoEcommerce/inventario/exportar",
        {
          data: data,
        }
      );

      if (response.data.url) {
        window.location = this.$http("baseURL") + response.data.url;
      }
      this.carregando = false;
    },
    async filtrar() {
      this.carregando = true;

      var data = { mes: this.mesFiltro + "-01" };

      data.pagination = this.pagination;
      data.busca = this.busca;

      const response = await this.$http().post(
        "/planejamentoEcommerce/inventario/lista",
        {
          data: data,
        }
      );
      if (response.status == 200) {
        this.dados = response.data.data.lista;
        this.pagination.total = response.data.data.total;
      }
      this.carregando = false;
    },
  },
};
</script>
