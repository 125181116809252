var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-5 py-3 br-15",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-toolbar-title',{staticClass:"page-title"},[_vm._v("Acordos Comerciais")])],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[(_vm.usuarioTipo == 'rd')?_c('v-btn',{staticClass:"new-button mx-3",attrs:{"color":"primary"},on:{"click":_vm.criarAcordo}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-view-grid-plus")]),_vm._v(" Criar acordo ")],1):_vm._e(),_c('v-btn',{staticClass:"new-button mx-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.exportar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" Exportar ")],1),_c('v-btn',{staticClass:"new-button ml-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.exportarProdutos()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" Exportar Produtos ")],1)],1)],1),_c('Filtro',{ref:"filtro",on:{"filtrar":_vm.filtrar,"limpar":_vm.limpar}})],1),(_vm.usuarioTipo == 'rd')?_c('Indicadores',{staticClass:"my-3",attrs:{"indicadores":_vm.indicadores,"filtro":_vm.$refs.filtro}}):_vm._e(),_c('v-card',{staticClass:"br-15 pa-5 involucroEcommerce",attrs:{"elevation":"0"}},[_c('v-data-table',{staticClass:"tabela paginacao-esquerda flex-table",attrs:{"headers":_vm.headers,"header-props":{
                dark: true,
            },"server-items-length":_vm.totalAcordos,"items":_vm.acordos,"items-per-page":5,"item-key":"id_acordo","footer-props":{
                'items-per-page-options': [5, 10, 15, 25, 50, 100, 500, -1],
                itemsPerPageText: 'Linhas por páginas',
                ofText: 'de',
                pageText: '{0}-{1} de {2}',
            },"single-select":_vm.singleSelect,"options":_vm.pagination,"show-select":""},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:`item.data-table-select`,fn:function({
                    item,
                    isSelected,
                    select,
                }){return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":isSelected,"color":"primary","disabled":(!_vm.fnLiberar &&
                            !_vm.statusAcesso(
                                item.id_status,
                                'aprovar_reprovar'
                            )) ||
                        (_vm.fnLiberar &&
                            (!_vm.statusAcesso(item.id_status, 'liberar') ||
                                item.mes_acordo != _vm.mesAtual)),"off-icon":(!_vm.fnLiberar &&
                            !_vm.statusAcesso(
                                item.id_status,
                                'aprovar_reprovar'
                            )) ||
                        (_vm.fnLiberar &&
                            (!_vm.statusAcesso(item.id_status, 'liberar') ||
                                item.mes_acordo != _vm.mesAtual))
                            ? '$checkboxLocked'
                            : 'mdi-checkbox-blank-outline',"on-icon":(!_vm.fnLiberar &&
                            !_vm.statusAcesso(
                                item.id_status,
                                'aprovar_reprovar'
                            )) ||
                        (_vm.fnLiberar &&
                            (!_vm.statusAcesso(item.id_status, 'liberar') ||
                                item.mes_acordo != _vm.mesAtual))
                            ? '$checkboxLocked'
                            : 'mdi-checkbox-marked'},on:{"input":function($event){return select($event)}}})]}},_vm._l((_vm.headers.filter((header) =>
                    header.hasOwnProperty('formatter')
                )),function(header){return {key:`item.${header.value}`,fn:function({ header, value }){return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:`item.calories`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":_vm.getColor(item.calories),"dark":""}},[_vm._v(" "+_vm._s(item.calories)+" ")])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-menu',{attrs:{"offset-y":"","min-width":"180","content-class":" acordo-menu"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-1",staticStyle:{"min-width":"unset"},attrs:{"dark":"","color":"#f2f2f2","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#CCC"}},[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('v-card',[_c('v-list',{attrs:{"dense":"","nav":""}},[(
                                    _vm.statusAcesso(
                                        item.id_status,
                                        'aprovar_reprovar'
                                    ) && _vm.fnAprovar
                                    && _vm.usuarioTipo == 'rd'
                                )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.reprovar(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("$documentRemove")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Reprovar Acordo")])],1)],1):_vm._e(),(
                                    _vm.statusAcesso(
                                        item.id_status,
                                        'aprovar_reprovar'
                                    ) && _vm.fnAprovar
                                    && _vm.usuarioTipo == 'rd'

                                )?_c('v-list-item',{staticClass:"hover-red",attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.aprovar(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("$documentCheck")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Aprovar Acordo")])],1)],1):_vm._e(),(
                                    item.mes_acordo >= _vm.mesAtual &&
                                    _vm.statusAcesso(item.id_status, 'editar') 
                                    &&  _vm.usuarioTipo == 'rd'
                                )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.formulario.editar(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Editar Acordo")])],1)],1):_vm._e(),( _vm.usuarioTipo == 'rd')?_c('v-list-item',{staticClass:"pl-2 mr-0",attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.historico(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-file-document-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Histórico")])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.dialogResumo(item, true)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-file-document-edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Exibir Acordo")])],1)],1),(
                                    item.termino == item.mes_acordo &&
                                    item.termino >= _vm.mesAnterior &&
                                    _vm.statusAcesso(item.id_status, 'renovar')
                                    && _vm.usuarioTipo == 'rd'
                                )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.renovacao(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-update")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Renovar Acordo")])],1)],1):_vm._e(),(item.obs_departamento  && _vm.usuarioTipo == 'rd')?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.dialogDepartamento(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-clipboard-text-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Obs. Departamento")])],1)],1):_vm._e(),(item.obs_contrato)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.dialogContrato(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-clipboard-text")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Obs. Contrato")])],1)],1):_vm._e(),(
                                    _vm.statusAcesso(item.id_status, 'cancelar')
                                    && _vm.usuarioTipo == 'rd'
                                )?_c('v-list-item',{staticClass:"hover-red",attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.cancelar(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("$documentRemove")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cancelar Acordo")])],1)],1):_vm._e(),(
                                    _vm.fnLiberar &&
                                    _vm.statusAcesso(
                                        item.id_status,
                                        'liberar'
                                    ) && item.mes_acordo == _vm.mesAtual
                                    && _vm.usuarioTipo == 'rd'

                                )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.dialogoLiberarAcordos(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("$documentCheck")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Liberar Acordo")])],1)],1):_vm._e()],1)],1)],1)]}}],null,true),model:{value:(_vm.acordoSelecionado),callback:function ($$v) {_vm.acordoSelecionado=$$v},expression:"acordoSelecionado"}}),(  _vm.usuarioTipo == 'rd')?_c('div',{staticClass:"table-footer-prepend d-flex pl-2 pt-4 align-center justify-end"},[(!_vm.fnLiberar)?_c('v-btn',{staticClass:"new-button ml-5",attrs:{"disabled":!_vm.acordoSelecionado.filter((e) =>
                        _vm.statusAcesso(e.id_status, 'aprovar_reprovar')
                    ).length || !_vm.fnAprovar,"color":"primary"},on:{"click":function($event){return _vm.$refs.dialogo.aprovar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check-circle")]),_vm._v(" Aprovar Acordos ")],1):_vm._e(),(!_vm.fnLiberar)?_c('v-btn',{staticClass:"new-button ml-5",attrs:{"disabled":!_vm.acordoSelecionado.filter((e) =>
                        _vm.statusAcesso(e.id_status, 'aprovar_reprovar')
                    ).length || !_vm.fnAprovar,"color":"error"},on:{"click":function($event){return _vm.$refs.dialogo.reprovar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle")]),_vm._v(" Reprovar Acordos ")],1):_vm._e(),(_vm.fnLiberar)?_c('v-btn',{staticClass:"new-button ml-5",attrs:{"disabled":!_vm.acordoSelecionado.filter(
                        (e) =>
                            _vm.statusAcesso(e.id_status, 'liberar') &&
                            e.mes_acordo == _vm.mesAtual &&
                                    _vm.usuarioTipo == 'rd' 
                    ).length,"color":"primary"},on:{"click":function($event){return _vm.dialogoLiberarAcordos()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Liberar acordos ")],1):_vm._e()],1):_vm._e()],1),_c('dialogo',{ref:"dialogo",on:{"aprovarAcordo":_vm.aprovarAcordo,"liberarAcordo":_vm.liberarAcordo,"filtrar":_vm.filtrar,"renovar":_vm.renovar}}),_c('formulario',{ref:"formulario",on:{"filtrar":_vm.filtrar}}),_c('v-overlay',{attrs:{"value":_vm.carregando}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }