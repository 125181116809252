<template>
    <div>
        <div class="resumo">
            <div class="resumo-title">Resumo do acordo</div>

            <div class="resumo-conteudo">
                <div class="resumo-item">
                    <span class="titulo">Cadastro do Acordo:</span>
                    <p>
                        Periodo de
                        <strong>{{ formatarMes(value.mesInicial) }}</strong> até
                        <strong>{{ formatarMes(value.mesFinal) }}</strong>
                    </p>
                    <p>
                        Espaço: <strong>{{ espaco }}</strong>
                    </p>
                    <p>
                        Plano: <strong>{{ value.plano }}</strong>
                    </p>
                    <p>
                        Bandeira:
                        <strong>{{
                            value.bandeira && value.bandeira.join(", ")
                        }}</strong>
                    </p>
                    <!-- <p>
                        Categoira: <strong>{{ categoria }}</strong>
                    </p>
                    <p>
                        Device: <strong>{{ device }}</strong>
                    </p> -->
                </div>
                <v-divider />
                <div class="resumo-item">
                    <span class="titulo">Seleção de Produtos:</span>
                    <p>
                        Fornecedor: <strong>{{ value.fornecedor }}</strong>
                    </p>
                    <p>
                        Produtos:
                        <strong>{{ produtosSelecionados }} selecionados</strong>
                    </p>
                </div>
                <v-divider />
                <div class="resumo-item">
                    <p class="titulo">Valores:</p>
                    <p>
                        Total de investimento:
                        <strong> <formataReal :valor="totalCusto" /></strong>
                    </p>
                    <p>
                        Desconto:
                        <strong><formataReal :valor="totalDesconto" /></strong>
                    </p>
                    <p>
                        Total com desconto:
                        <strong
                            ><formataReal :valor="totalInvestimento"
                        /></strong>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["value"],
    inject: ["listaCampos"],
    async created() {
        var lista = await this.listaCampos;
        this.lista.espaco = lista.espaco;
        this.lista.categorias = lista.categorias;
        this.lista.device = lista.device;
    },
    data() {
        return {
            lista: {
                espaco: [],
            },
        };
    },
    computed: {
        espaco() {
            if (this.value.id_espaco && this.lista.espaco) {
                const espaco = this.lista.espaco.find(
                    (e) => e.value === this.value.id_espaco
                );
                if (espaco) {
                    return espaco.text;
                }
            }
            return "";
        },
        categoria() {
            if (this.value.id_categoria && this.lista.categorias) {
                const categoria = this.lista.categorias.find(
                    (e) => e.value === this.value.id_categoria
                );
                if (categoria) {
                    return categoria.text;
                }
            }
            return "";
        },
        device() {
            if (this.value.id_device && this.lista.device) {
                const device = this.lista.device.find(
                    (e) => e.value === this.value.id_device
                );
                if (device) {
                    return device.text;
                }
            }
            return "";
        },
        produtosSelecionados() {
            if (this.value.produtos) {
                return this.value.produtos.length;
            }
            return "";
        },
        totalCusto() {
            return this.value.desconto.reduce(
                (total, item) => total + item.valor,
                0
            );
        },
        totalDesconto() {
            return this.value.desconto.reduce(
                (total, item) => total + item.total,
                0
            );
        },
        totalInvestimento() {
            return this.value.desconto.reduce(
                (total, item) => total + +item.investimento,
                0
            );
        },
    },

    methods: {
        formatarMes(mes) {
            return this.$moment(mes, "YYYY-MM")
                .format("MMMM [de] YYYY")
                .capitalize();
        },
    },
};
</script>
<style lang="scss">
.resumo {
    .resumo-title {
        font-size: 1.1rem;
        background: var(--v-primary-base);
        text-align: center;
        padding: 10px;
        color: #fff;
        font-weight: 500;
        border-radius: 8px 8px 0 0;
    }
    .resumo-conteudo {
        display: grid;
        grid-template-columns: 1fr 20px 1fr 20px 1fr;
        grid-gap: 15px;
        align-items: center;
        padding: 20px;
        border-radius: 0 0 8px 8px;
        font-weight: 500;
        font-size: 0.8rem !important;
        color: #808080;
        border: 1px solid #ddd;
        p {
            margin: 0;
        }
        .resumo-item {
            width: 100%;
            height: 100%;
            padding: 10px 20px;
            border-radius: 8px;
            background: #eaeaea;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .titulo {
                font-weight: bold;
                color: var(--v-primary-base);
            }
        }
    }
}
</style>