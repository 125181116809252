var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-3"},[_c('v-expand-transition',[_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[_c('div',{on:{"click":function($event){return _vm.alterarStatus(2)}}},[_c('Indicador',{class:{
              active: _vm.status.includes(2),
            },attrs:{"titulo":"Ativações Ok","valor":_vm.indicadores.status_ok,"icone":"mdi-check-circle","color":"success"}})],1)]),_c('v-col',{attrs:{"md":"3"}},[_c('div',{on:{"click":function($event){return _vm.alterarStatus(1)}}},[_c('Indicador',{class:{
              active: _vm.status.includes(1),
            },attrs:{"titulo":"Ativações Pendentes","valor":_vm.indicadores.status_pendente,"icone":"$alertCircleCheckOutline","color":"warning"}})],1)]),_c('v-col',{attrs:{"md":"3"}},[_c('div',{on:{"click":function($event){return _vm.alterarStatus(3)}}},[_c('Indicador',{class:{
              active: _vm.status.includes(3),
            },attrs:{"titulo":"Ativações Canceladas","valor":_vm.indicadores.status_cancelado,"icone":"mdi-text-box-remove-outline","color":"error"}})],1)]),_c('v-col',{attrs:{"md":"3"}},[_c('div',{on:{"click":function($event){return _vm.alteraAtivacao(1)}}},[_c('Indicador',{class:{
              active: _vm.ativacao.includes(1),
            },attrs:{"titulo":"Ofertas do dia","valor":_vm.indicadores.orferta_dia,"icone":"mdi-tag-multiple-outline","color":"warning"}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }