// computed.js
import moment from "moment";
moment.locale("pt-br");

export default {
  calculaSemanas(mes) {
    const primeiroDiaMes = moment(mes).startOf("month");
    const ultimoDiaMes = moment(mes).endOf("month");

    const primeiroDiaIso = moment(primeiroDiaMes).isoWeekday();
    const ultimoDiaIso = moment(ultimoDiaMes).isoWeekday();

    let inicio;
    if (primeiroDiaIso >= 5) {
      const diasAdicionar = 8 - primeiroDiaIso;
      inicio = moment(primeiroDiaMes).add(diasAdicionar, "days");
    } else {
      inicio = moment(primeiroDiaMes).startOf("isoWeek");
    }
    let fim;
    if (ultimoDiaIso >= 4) {
      fim = moment(ultimoDiaMes).endOf("isoWeek");
    } else {
      fim = moment(ultimoDiaMes).subtract(ultimoDiaIso, "days");
    }

    const semanas = [];
    while (inicio.isSameOrBefore(fim)) {
      semanas.push({
        inicio: inicio,
        fim: moment(inicio).add(6, "days")
      });
      inicio = moment(inicio).add(7, "days");
    }

    return semanas;
  }
};
