<template>
    <v-container fluid>
        <v-card class="px-5 py-3 br-15 w-100" elevation="0">
            <v-row class="">
                <v-col class="flex-grow-1">
                    <v-toolbar-title class="page-title"
                        >Pesquisas</v-toolbar-title
                    >
                </v-col>
                <v-col cols="3">
                    <v-text-field
                        class="input-button input-cinza"
                        label="Pesquisar"
                        v-model="busca"
                        outlined
                        dense
                        hide-details
                    >
                        <template>
                            <v-icon slot="append" color="white"
                                >mdi-magnify</v-icon
                            >
                        </template>
                    </v-text-field>
                </v-col>
 
                <div class="d-flex py-3 px-4">
                    <v-btn
                        color="primary"
                        class="new-button mr-3"
                        @click="$refs.formulario.adicionar()"
                    >
                        <v-icon>mdi-plus</v-icon>
                        Adicionar
                    </v-btn>
                </div>
            </v-row>
        </v-card>
        <Formulario ref="formulario" @filtrar="filtrar"></Formulario>

        <v-card class="pa-5 my-5 br-15 w-100" elevation="0">
            <v-data-table
                :headers="headers"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100],
                    itemsPerPageText: 'Linhas por páginas',
                    ofText: 'de',
                    pageText: '{0}-{1} de {2}',
                }"
                :search="busca"
                :items="pesquisas"
                class="elevation-0 tabela"
            >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-menu
                        offset-y
                        min-width="180"
                        content-class=" acordo-menu"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                dark
                                color="#f2f2f2"
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="px-1"
                                style="min-width: unset"
                            >
                                <v-icon color="#CCC">mdi-menu</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-list dense nav>
                                <v-list-item
                                    link
                                    @click="$refs.formulario.exibir(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-file-document-edit</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Exibir pesquisa</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    link
                                    @click="$refs.formulario.replicar(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-autorenew</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Replicar
                                            pesquisa</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    link
                                    @click="dialogoEditarPeriodo(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-file-document-edit</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Editar período</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item link @click="exportar(item)">
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-microsoft-excel</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Exportar
                                            respostas</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card>
        <v-overlay :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-dialog content-class="br-15" v-model="dialog" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title> Editar período </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialog = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-5">
                    <v-row>
                        <v-col cols="6" class="pb-0">
                            <DataInput
                                v-model="novoPeriodo.data_inicio"
                                :min="new Date().toISOString().substring(0, 10)"
                                type="date"
                                label="Data de Início"
                                outlined
                                dense
                            ></DataInput>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                            <DataInput
                                v-model="novoPeriodo.data_fim"
                                type="date"
                                label="Data final"
                                :min="novoPeriodo.data_inicio"
                                outlined
                                dense
                            ></DataInput>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="new-button px-6 mb-3 mr-2"
                        @click="editarPeriodo()"
                    >
                        Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import Formulario from "./Formulario.vue";
export default {
    components: {
        Formulario,
    },

    data() {
        return {
            app: true,
            pesquisas: [],
            carregando: false,
            dialog: false,
            novoPeriodo: {
                id_pesquisa: null,
                data_inicio: null,
                data_fim: null,
            },
            busca: "",
            headers: [
                {
                    text: "",
                    value: "actions",
                    class: "th-separador",
                    width: "50px",
                },
                { text: "Título", value: "titulo", class: "th-separador" },
                {
                    text: "Filiais",
                    value: "filiais",
                    class: "th-separador",
                    align: "center",
                    width: "100px",
                },
                {
                    text: "Repostas",
                    value: "respostas",
                    class: "th-separador",
                    align: "center",
                    width: "100px",
                },
                {
                    text: "Perguntas",
                    value: "perguntas",
                    class: "th-separador",
                    align: "center",
                    width: "100px",
                },

                {
                    text: "Data inicio",
                    value: "data_inicio",
                    class: "th-separador",
                    align: "center",
                    width: "100px",
                },
                {
                    text: "Data fim",
                    value: "data_fim",
                    class: "th-separador",
                    align: "center",
                    width: "100px",
                },
                // { text: 'Ações', value: 'actions', sortable: false },
            ],
        };
    },
    created() {
        this.filtrar();
    },
    methods: {
        dialogoEditarPeriodo(pesquisa) {
            this.dialog = true;
            this.novoPeriodo.id_pesquisa = pesquisa.id_pesquisa;
            this.novoPeriodo.data_inicio = this.$moment(
                pesquisa.data_inicio,
                "DD/MM/YYYY"
            ).format("YYYY-MM-DD");
            this.novoPeriodo.data_fim = this.$moment(
                pesquisa.data_fim,
                "DD/MM/YYYY"
            ).format("YYYY-MM-DD");
        },
        async filtrar() {
            try {
                const { data } = await this.$http().post(
                    "/app/pesquisas/lista"
                );

                this.pesquisas = data.map((pesquisa) => {
                    pesquisa.data_inicio = this.$moment(
                        pesquisa.data_inicio
                    ).format("DD/MM/YYYY");
                    pesquisa.data_fim = this.$moment(pesquisa.data_fim).format(
                        "DD/MM/YYYY"
                    );
                    return pesquisa;
                });
            } catch (error) {
                console.log(error);
            }
        },
        async editarPeriodo() {
            this.carregando = true;

            const data = {
                id_pesquisa: this.novoPeriodo.id_pesquisa,
                data_inicio: this.novoPeriodo.data_inicio,
                data_fim: this.novoPeriodo.data_fim,
            };

            const response = await this.$http().post(
                "/app/pesquisas/editarPeriodo",
                data
            );

            if (response.status == 200 && response.data.success == true) {
                this.$dialog.notify.success("Período editado com sucesso", {
                    position: "top-right",
                    timeout: 5000,
                });
                this.dialog = false;
                this.filtrar();
            } else {
                const mensagem =
                    response.data.mensagem || "Falha ao editar período";
                this.$dialog.notify.error(mensagem, {
                    position: "top-right",
                    timeout: 5000,
                });
            }
            this.carregando = false;
        },
        async exportar(pesquisa) {
            this.carregando = true;
            try {
                const { data } = await this.$http().post(
                    "/app/pesquisas/exportar",
                    {
                        idPesquisa: pesquisa.id_pesquisa,
                    }
                );
                if (data.url) {
                    window.location = this.$http("baseURL") + data.url;
                }
                console.log(data);
            } catch (error) {
                this.$dialog.notify.error("Falha ao exportar respostas", {
                    position: "top-right",
                    timeout: 5000,
                    width: "",
                    style: `width: ${window.innerWidth * 0.8}px`,
                });
            }
            this.carregando = false;
        }
    },
};
</script>
