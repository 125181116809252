<template>
  <div>
    <v-dialog
      content-class="br-15 h-100"
      v-model="dialogo"
      persistent
      scrollable
    >
      <v-card class="h-100" height="90vh">
        <v-toolbar
          elevation="0"
          dark
          class="primary-degrade white--text fix-flex"
        >
          <v-toolbar-title
            >{{ pesquisaExibicao ? "Visualizar" : "Criar" }} pesquisa</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            fab
            width="24px"
            height="24px"
            elevation="0"
            light
            @click="dialogo = false"
          >
            <v-icon size="18px" color="primary">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-3 pb-0">
          <v-form ref="formPergunta" class="h-100">
            <v-row class="h-100">
              <v-col cols="6" class="px-4 py-6">
                <!-- <h3 color="primary">Definições</h3> -->
                <v-toolbar-title class=" mb-4"
                  >Configurações da pesquisa
                </v-toolbar-title>

                <v-row>
                  <v-col cols="6" class="pb-0">
                    <DataInput
                      v-model="pesquisa.data_inicio"
                      :min="new Date().toISOString().substring(0, 10)"
                      type="date"
                      label="Data de Início"
                      outlined
                      :disabled="bloquearEdicao"
                      dense
                    ></DataInput>
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    <DataInput
                      v-model="pesquisa.data_fim"
                      type="date"
                      label="Data final"
                      :min="pesquisa.dataInicial"
                      outlined
                      dense
                    ></DataInput>
                  </v-col>

                  <v-col cols="6" class="py-0">
                    <v-autocomplete
                      label="Filial"
                      v-model="filtro.id_filial"
                      :items="lista.filial"
                      :menu-props="menuProps"
                      class="input-cinza"
                      multiple
                      outlined
                      dense
                    >
                      <template v-slot:selection="{ item, index }">
                        <div
                          v-if="filtro.id_filial.length === 1 && index === 0"
                          class="autocomplete-selecao"
                        >
                          {{ item.text }}
                        </div>
                        <span
                          v-if="filtro.id_filial.length > 1 && index === 0"
                          class="grey--text caption mr-1"
                          >{{ filtro.id_filial.length }} selecionados</span
                        >
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-autocomplete
                      label="Região"
                      v-model="filtro.regiao"
                      :items="lista.regiao"
                      :menu-props="menuProps"
                      class="input-cinza"
                      multiple
                      outlined
                      dense
                    >
                      <template v-slot:selection="{ item, index }">
                        <div
                          v-if="filtro.regiao.length === 1 && index === 0"
                          class="autocomplete-selecao"
                        >
                          {{ item.text }}
                        </div>
                        <span
                          v-if="filtro.regiao.length > 1 && index === 0"
                          class="grey--text caption mr-1"
                          >{{ filtro.regiao.length }} selecionados</span
                        >
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-autocomplete
                      label="Bandeira"
                      v-model="filtro.bandeira"
                      :items="[`DROGASIL`, 'RAIA']"
                      :menu-props="menuProps"
                      class="input-cinza"
                      multiple
                      outlined
                      dense
                    >
                      <template v-slot:selection="{ item, index }">
                        <div
                          v-if="filtro.bandeira.length === 1 && index === 0"
                          class="autocomplete-selecao"
                        >
                          {{ item }}
                        </div>
                        <span
                          v-if="filtro.bandeira.length > 1 && index === 0"
                          class="grey--text caption mr-1"
                          >{{ filtro.bandeira.length }} selecionados</span
                        >
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-autocomplete
                      label="Cluster"
                      :items="lista.cluster"
                      v-model="filtro.id_cluster"
                      :menu-props="menuProps"
                      class="input-cinza"
                      multiple
                      outlined
                      dense
                    >
                      <template v-slot:selection="{ item, index }">
                        <div
                          v-if="filtro.id_cluster.length === 1 && index === 0"
                          class="autocomplete-selecao"
                        >
                          {{ item.text }}
                        </div>
                        <span
                          v-if="filtro.id_cluster.length > 1 && index === 0"
                          class="grey--text caption mr-1"
                          >{{ filtro.id_cluster.length }} selecionados</span
                        >
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      label="Título da pesquisa"
                      class="input-cinza"
                      :rules="rules.titulo"
                      v-model="pesquisa.titulo"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-textarea
                      placeholder="Uma breve descrição da pesquisa."
                      class="input-cinza"
                      v-model="pesquisa.descricao"
                      outlined
                      dense
                    ></v-textarea>
                  </v-col>
                  <v-col>
                    <p>
                      Esta pesquisa será enviada para
                      <span>{{ quantidadeDeLojas }}</span> filiais
                    </p>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <!-- <v-btn
                    @click="salvar"
                    color="primary"
                    class="new-button px-6"
                  >
                    <v-icon class="mr-2">mdi-check-circle</v-icon>
                    Exportar filiais 
                  </v-btn>
                  <v-btn
                    @click="salvar"
                    color="primary"
                    class="new-button px-6 mx-4"
                  >
                    <v-icon class="mr-2">mdi-check-circle</v-icon>
                    Importar Filiais
                  </v-btn> -->
                    <v-btn
                    v-if="!pesquisaExibicao"
                      @click="confirmarSalvamento"
                      color="primary"
                      class="new-button px-6"
                    >
                      <v-icon class="mr-2">mdi-check-circle</v-icon>
                      Salvar pesquisa
                    </v-btn>
                  </v-col>
                  <!-- <v-col cols="12"> </v-col> -->
                </v-row>
              </v-col>

              <v-col cols="6" class="pa-5" style="background-color: #f2f2f2">
                <v-alert v-if="bloquearEdicao" type="error">
                  <p>
                    Não é possível alterar as perguntas de pesquisas ativas.
                  </p>
                </v-alert>
                <v-alert v-if="perguntas.length === 0" type="error">
                  <p>
                    Adicione pelo menos uma pergunta para esta pesquisa.
                  </p>
                </v-alert>
                <v-btn
                  @click="novaPergunta"
                  color="primary"
                  dark
                  absolute
                  class="mb-10"
                  v-if="!bloquearEdicao"
                  bottom
                  right
                  fab
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <draggable
                  ghost-class="ghost"
                  v-model="perguntas"
                  class="w-100"
                  v-bind="dragOptions"
                  @start="drag = true"
                  @end="drag = false"
                  handle=".handle-pergunta"
                  :group="{ name: 'pergunta' }"
                  :disabled="bloquearEdicao"
                >
                  <transition-group
                    type="transition"
                    :name="!drag ? 'flip-list' : null"
                  >
                    <v-card
                      elevation="2"
                      class="list-group-item mb-3 rounded-lg"
                      v-for="element in listaPesquisas"
                      :key="element.ordem"
                    >
                      <v-card-text>
                        <div class="d-flex align-start">
                          <v-file-input
                            v-if="!bloquearEdicao"
                            v-model="element.imagem"
                            label="Imagem de apoio (não obrigatória)"
                            class="input-cinza mt-3"
                            @change="onFileChange"
                            :disabled="bloquearEdicao"
                            outlined
                            dense
                          ></v-file-input>    
                          <v-icon
                            v-if="bloquearEdicao && element.imagem"
                            class=" mr-4 mt-6"
                            color="#bbb"
                            style="cursor: pointer;"
                          >
                            mdi-image
                          </v-icon>  
                          <v-textarea
                            v-if="bloquearEdicao && element.imagem"
                            placeholder="Imagem de apoio (não obrigatória)"
                            v-model="element.imagem"
                            class="lined-input "
                            :disabled="bloquearEdicao"
                            required
                            rows="1"
                            auto-grow
                          ></v-textarea>                 
                        </div>  
                        <div class="d-flex align-start">
                          <v-icon
                            class="handle-pergunta mr-4 mt-6"
                            color="#bbb"
                            style="cursor: move;"
                          >
                            mdi-swap-vertical
                          </v-icon>
                          <v-textarea
                            placeholder="Titulo da pergunta"
                            v-model="element.titulo"
                            class="lined-input "
                            :rules="rules.titulo"
                            :disabled="bloquearEdicao"
                            required
                            rows="1"
                            auto-grow
                          ></v-textarea>
                          <v-col cols="4" class="pb-0">
                            <v-select
                              label="Tipo de pergunta"
                              :items="tiposPerguntas"
                              :disabled="bloquearEdicao"
                              v-model="element.type"
                              class="input-cinza ml-2"
                              outlined
                              :menu-props="menuProps"
                              dense
                              hide-details
                            >
                            </v-select>
                          </v-col>
                          <v-btn
                            @click="removerPergunta(perguntas, element.ordem)"
                            :disabled="bloquearEdicao"
                            icon
                            class="ml-2"
                          >
                            <v-icon color="error">
                              mdi-close-circle-outline
                            </v-icon>
                          </v-btn>
                        </div>

                        <div
                          class="opcoes"
                          v-if="
                            ['multipla_escolha', 'unica_escolha'].includes(
                              element.type
                            )
                          "
                        >
                          <!-- Alert if theres no option -->
                          <v-alert
                            v-if="element.options.length == 0"
                            type="error"
                            class="mt-2"
                            dense
                            outlined
                            text
                          >
                            Adicione opções para esta pergunta.
                          </v-alert>
                          <draggable
                            v-model="element.options"
                            v-bind="dragOptions"
                            @start="dragOpcoes = true"
                            @end="dragOpcoes = false"
                            :disabled="bloquearEdicao"
                            handle=".handle-opcao"
                            :group="{ name: `opcao-${element.ordem}` }"
                          >
                            <transition-group
                              type="transition"
                              :name="!dragOpcoes ? 'flip-list' : null"
                            >
                              <div
                                class="d-flex mb-2 align-start"
                                v-for="(item, index) in listaOpcoes(element)"
                                :key="item.ordem"
                              >
                                <v-icon
                                  class="mt-2 ml-5 mx-2 handle-opcao"
                                  color="#bbb"
                                  style="cursor: move;"
                                >
                                  mdi-swap-vertical
                                </v-icon>
                                <v-checkbox
                                  v-if="element.type == 'multipla_escolha'"
                                  dense
                                  hide-details
                                  disabled
                                  class="mt-2 pt-0"
                                ></v-checkbox>
                                <v-radio-group
                                  v-if="element.type == 'unica_escolha'"
                                  class="mt-2 pt-0"
                                  dense
                                  hide-details
                                >
                                  <v-radio disabled></v-radio>
                                </v-radio-group>
                                <v-text-field
                                  v-model="item.titulo"
                                  class="lined-input "
                                  :placeholder="'Opção ' + (index + 1)"
                                  :disabled="bloquearEdicao"
                                  dense
                                  hide-details
                                ></v-text-field>
                                <v-btn
                                  @click="removerOpcao(element, index)"
                                  :disabled="bloquearEdicao"
                                  icon
                                >
                                  <v-icon color="#bbb">
                                    mdi-close-circle-outline
                                  </v-icon>
                                </v-btn>
                              </div>
                            </transition-group>
                          </draggable>
                        </div>
                        <div class="d-flex justify-space-between mt-">
                          <div class="adicionar">
                            <v-btn
                              @click="adicionarOpcao(element)"
                              :disabled="bloquearEdicao"
                              v-if="
                                ['multipla_escolha', 'unica_escolha'].includes(
                                  element.type
                                )
                              "
                            >
                              <v-icon>mdi-plus</v-icon>
                              Adicionar opção
                            </v-btn>
                          </div>
                          <div class="obrigatorio">
                            <v-switch
                              v-model="element.usar_imagem_resposta"
                              label="Imagem resposta"
                              class="ma-0"
                              hide-details
                              :disabled="bloquearEdicao"
                              inset
                              dense
                            >
                            </v-switch>
                          </div>
                          <div class="obrigatorio">
                            <v-switch
                              v-model="element.obrigatoria"
                              label="Resposta obrigatória"
                              class="ma-0"
                              hide-details
                              :disabled="bloquearEdicao"
                              inset
                              dense
                            >
                            </v-switch>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </transition-group>
                </draggable>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <!-- loading -->
      <v-overlay :value="carregando" :z-index="500">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
    <v-dialog
      v-model="dialogoAlerta"
      content-class="br-15"
      persistent
      scrollable
      width="600"
      :z-index="300"
    >
      <v-card>
        <v-toolbar
          elevation="0"
          dark
          class="primary-degrade white--text fix-flex"
        >
          <v-toolbar-title>Confirmar </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            fab
            width="24px"
            height="24px"
            elevation="0"
            light
            @click="dialogoAlerta = false"
          >
            <v-icon size="18px" color="primary">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          {{ textoAlerta }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="new-button ml-auto mb-2 mr-2 px-3"
            color="error"
            @click="dialogoAlerta = false"
          >
            <v-icon class="mr-3">mdi-check-circle</v-icon>
            Não, não salvar
          </v-btn>
          <v-btn class="new-button mb-2 px-3" color="primary" @click="salvar()">
            <v-icon class="mr-3">mdi-check-circle</v-icon>
            Sim, salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import useFiltros from "../../../hooks/useFiltros";
var smartId = require("smart-id");
export default {
  name:"PesquisaFormulario",
  setup()  {
    
    const {
      isLoading,
      isError,
      isFetching,
      lista,
      error,
      refetch,
    } = useFiltros("/app/pesquisas/listaCampos");
    return {
      isLoading,
      isError,
      isFetching,
      lista,
      error,
      refetch,
    };
  },
  components: {
    draggable,
  },
  data() {
    return {
      dialogo: false,
      carregando: false,
      dialogoAlerta: false,
      pesquisa: {},
      drag: false,
      dragOpcoes: false,
      textoAlerta: "",

      rules: {
        titulo: [(v) => !!v || "O campo  é obrigatório"],
        // validate options for v-select
      },
      tiposPerguntas: [
        {
          value: "texto",
          text: "Texto",
        },
        {
          value: "multipla_escolha",
          text: "Multipla escolha",
        },
        {
          value: "unica_escolha",
          text: "Escolha única",
        },
      ],
      menuProps: {
        bottom: true,
        offsetY: true,
        contentClass: "select-outline",
        transition: "slide-y-transition",
      },
      filtro: {
        id_filial: [],
        id_cluster: [],
        regiao: [],
        bandeira: [],
      },
      perguntas: [],
    };
  },
  methods: {
    adicionar() {
      this.dialogo = true;
      this.pesquisa = {
        data_fim: new Date().toISOString().substring(0, 10),
        data_inicio: new Date().toISOString().substring(0, 10),
        descricao: "",
        titulo: "",
        imagem:null,
        id_pesquisa: null,
      };
      this.filtro.id_filial = [];
      this.perguntas = [this.perguntaEmBranco()];
      this.$refs.formPergunta.resetValidation();
    },
    async replicar(pesquisa) {
      this.dialogo = true;
      this.carregando = true;
      try {
        const { data } = await this.$http().get(
          `/app/pesquisas/detalhe/${pesquisa.id_pesquisa}`
        );

        this.pesquisa = {
          data_fim: data.data_fim,
          data_inicio: data.data_inicio,
          descricao: data.descricao,
          titulo: data.titulo,
        };
        this.perguntas = data.perguntas;
      } catch (error) {
        this.$dialog.notify.error("Falha ao carregar dados da pesquisa.", {
          position: "top-right",
          timeout: 5000,
          width: "",
          style: `width: ${window.innerWidth * 0.8}px`,
        });
      }
      this.carregando = false;
    },
    async exibir(pesquisa) {
      this.dialogo = true;
      this.carregando = true;
      try {
        const { data } = await this.$http().get(
          `/app/pesquisas/detalhe/${pesquisa.id_pesquisa}`
        );

        this.pesquisa = {
          data_fim: data.data_fim,
          data_inicio: data.data_inicio,
          descricao: data.descricao,
          titulo: data.titulo,
          id_pesquisa: data.id_pesquisa,
        };
        this.filtro.id_filial = data.id_filiais;
        this.perguntas = data.perguntas;
      } catch (error) {
        this.$dialog.notify.error("Falha ao carregar dados da pesquisa.", {
          position: "top-right",
          timeout: 5000,
          width: "",
          style: `width: ${window.innerWidth * 0.8}px`,
        });
      }
      this.carregando = false;
    },
    adicionarOpcao(element) {
      // console.log(element);
      element.options.push({
        titulo: "",
        ordem: element.options.length,
      });
    },

    confirmarSalvamento() {
      this.textoAlerta =
        "Atenção, após salvar a pesquisa não será possível alterar as perguntas. Deseja continuar?";

      if (!this.$refs.formPergunta.validate()) return;

      if (this.perguntas.length == 0)
        return this.$dialog.notify.error("Adicione pelo menos uma pergunta.", {
          position: "top-right",
          timeout: 5000,
          width: "",
        });
      if (
        this.perguntas.some(
          (pergunta) =>
            pergunta.type !== "texto" && pergunta.options.length == 0
        )
      )
        return this.$dialog.notify.error(
          "Adicione pelo menos uma opção para cada pergunta.",
          {
            position: "top-right",
            timeout: 5000,
            width: "",
          }
        );

      this.dialogoAlerta = true;
    },
    async salvar() {
      this.dialogoAlerta = false;

      this.carregando = true;

      if (this.pesquisaExibicao) {
        await new Promise((resolve) => setTimeout(resolve, 5000));

        this.$dialog.notify.error("Erro ao editar pesquisa.", {
          position: "top-right",
          timeout: 5000,
          width: "",
        });
        this.carregando = false;
        return;
      }

      // var data = {}

      // var perguntas = this.perguntas.map(item => {
      //   if (item.imagem) {
          
      //   }
      //   return {
      //     id: smartId.make(),
      //     ...item,
      //   }
      // })


      try {
        this.$refs.formPergunta.validate();
        var hashId = smartId.make()
        for(var i = 0;i < this.perguntas.length;i++) {
          if (this.perguntas[i].imagem) {
            var formData = new FormData();
            formData.append("files", this.perguntas[i].imagem);
            formData.append("hashId",hashId)
            const responseImagem = await this.$http().post("/app/pesquisas/salvarImagem", formData);
            console.log(responseImagem)
            if (responseImagem.data.sucesso == 1) {
              this.perguntas[i].imagem = responseImagem.data.imagem
            } else {
              throw "erroImagem";
            }
          }
        }
        this.pesquisa.hashId = hashId
        var data = {
          pesquisa: this.pesquisa,
          filiais: this.filtrarFiliais().map((item) => item.value),
          perguntas: this.perguntas,
        };

        const response = await this.$http().post("/app/pesquisas/salvar", {
          data: data,
        });

        this.$emit("filtrar");
        this.dialogo = false;
      } catch (error) {
        this.$dialog.notify.error("Falha ao salvar pesquisa.", {
          position: "top-right",
          timeout: 5000,
          width: "",
        });
      } finally {
        this.carregando = false;
      }
    },
    perguntaEmBranco() {
      return {
        type: "texto",
        titulo: "",
        obrigatoria: false,
        usar_imagem_resposta: false,
        options: [
          {
            titulo: "",
            ordem: 0,
          },
        ],
      };
    },
    filtrarFiliais() {
      const lojasAfetadas = this.lista.filial.filter((loja) => {
        /// Verifica se
        const includeFiliais =
          this.filtro.id_filial.length > 0
            ? this.filtro.id_filial.includes(loja.value)
            : true;
        const includeRegioes =
          this.filtro.regiao.length > 0
            ? this.filtro.regiao.includes(loja.regiao)
            : true;
        const includeClusters =
          this.filtro.id_cluster.length > 0
            ? this.filtro.id_cluster.includes(loja.id_cluster)
            : true;
        const includeBandeira =
          this.filtro.bandeira.length > 0
            ? this.filtro.bandeira.includes(loja.bandeira)
            : true;
        return includeFiliais && includeRegioes && includeBandeira && includeClusters;
      });
      return lojasAfetadas;
    },
    novaPergunta() {
      this.perguntas.push(this.perguntaEmBranco());
    },
    removerPergunta(element, index) {
      element.splice(index, 1);
    },
    removerOpcao(element, index) {
      element.options.splice(index, 1);
    },
    listaOpcoes(element) {
      if (!element.options) return [];
      return element.options.map((item, index) => {
        item.ordem = index;
        return item;
      });
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    pesquisaExibicao() {
      return this.pesquisa.id_pesquisa;
    },
    bloquearEdicao() {
      if (!this.pesquisaExibicao) return false;
      // se a data inicial da pesquisa for menor que a data atual, bloqueia a edição
      if (this.pesquisa.data_inicio) {
        const dataInicio = new Date(this.pesquisa.data_inicio);
        const dataAtual = new Date();
        return dataInicio < dataAtual;
      }
      return false;
    },

    listaPesquisas() {
      return this.perguntas.map((item, index) => {
        item.ordem = index;
        return item;
      });
    },
    quantidadeDeLojas() {
      return this.filtrarFiliais().length;
    },
    listarFiliaisSelecionadas() {
      return this.filtrarFiliais();
    },
  },
};
</script>
<style>
.lined-input .v-input__slot:before {
  display: block;
  border-color: rgba(0, 0, 0, 0.12);
}
.lined-input .v-input__slot:after {
  display: block;
}
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #a9e9cc;
}

.list-group {
  min-height: 20px;
}

.handle-pergunta,
.handle-opcao {
  cursor: move;
}
</style>
