<template>
  <div class="my-3">
    <v-expand-transition>
      <v-row>
        <v-col md="3">
          <div @click="alterarStatus(2)">
            <Indicador
              titulo="Ativações Ok"
              :valor="indicadores.status_ok"
              icone="mdi-check-circle"
              color="success"
              :class="{
                active: status.includes(2),
              }"
            />
          </div>
        </v-col>
        <v-col md="3">
          <div @click="alterarStatus(1)">
            <Indicador
              titulo="Ativações Pendentes"
              :valor="indicadores.status_pendente"
              icone="$alertCircleCheckOutline"
              color="warning"
              :class="{
                active: status.includes(1),
              }"
            />
          </div>
        </v-col>
        <v-col md="3">
          <div @click="alterarStatus(3)">
            <Indicador
              titulo="Ativações Canceladas"
              :valor="indicadores.status_cancelado"
              icone="mdi-text-box-remove-outline"
              color="error"
              :class="{
                active: status.includes(3),
              }"
            />
          </div>
        </v-col>

        <v-col md="3">
          <div @click="alteraAtivacao(1)">
            <Indicador
              titulo="Ofertas do dia"
              :valor="indicadores.orferta_dia"
              icone="mdi-tag-multiple-outline"
              color="warning"
              :class="{
                active: ativacao.includes(1),
              }"
            />
          </div>
        </v-col>
      </v-row>
    </v-expand-transition>
  </div>
</template>

<script>
import Indicador from "./_components/Indicador.vue";

export default {
  components: {
    Indicador,
  },
  props: ["indicadores", "filtro"],
  computed: {
    status: function () {
      return this.filtro?.filtro?.id_status_planejamento || [];
    },
    ativacao: function () {
      return this.filtro?.filtro?.id_ativacao || [];
    },
  },
  data() {
    return {
      expand: true,
      expandIcon: "mdi-chevron-up",
    };
  },
  methods: {
    alterarStatus: function (status) {
        
      if (this.status.includes(status)) {
        this.filtro.filtro.id_status_planejamento = this.status.filter(
          (s) => s != status
        );
      } else {
        this.filtro.filtro.id_status_planejamento = [status];
      }
      this.filtro.filtrar();
    },
    alteraAtivacao: function (ativacao) {
      if (this.ativacao.includes(ativacao)) {
        this.filtro.filtro.id_ativacao = this.ativacao.filter(
          (s) => s != ativacao
        );
      } else {
        this.filtro.filtro.id_ativacao = [ativacao];
      }
      this.filtro.filtrar();
    },
  },
};
</script>