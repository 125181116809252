<template>

  <v-card-text class="mt-4">   
      
      <v-row no-gutters class="mb-2">
          <v-col cols="12" sm="12" md="6" lg="8" xl="8">
              <v-toolbar-title class="ml-2 mt-2">Fornecedor: {{ fornecedor.nome }}
                
              </v-toolbar-title>
              
          </v-col>
      </v-row>        
      <v-row no-gutters class="mb-2">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-row no-gutters>
              
              <v-col cols="4">
                <v-btn
                  class=" my-1"
                  color="primary"                     
                  @click="exportar()"   
                  >
                  <v-icon left>mdi-microsoft-excel</v-icon> Exportar                    
                </v-btn>           
                
              </v-col>               
              <v-col cols="8"  >   
                <v-text-field
                  label="Mês"
                  v-model="mes"
                  :rules="regra.mes"
                  required
                  outlined
                  dense
                  type="month"
                ></v-text-field>
              </v-col>               
            </v-row>
            <v-data-table
                  :headers="headers"
                  :items="logguias"
                  :items-per-page="10"
                  :search="busca"
                  :footer-props="{
                      'items-per-page-options': [5, 10, 15, 25, 50, 100]
                  }"
                  :options.sync="pagination"
                  multi-sort
                  class="elevation-0"
              >   
              <template v-slot:[`item.botao`]="{ item }">
                
                <v-icon
                  
                  small
                  class="mr-2"
                  color="primary"
                  @click="baixar(item)"
                  title="Baixar arquivo"
                >
                mdi-download
                </v-icon>
                
              </template>                  
              </v-data-table>
          </v-col>
      </v-row>  
      <v-overlay :value="carregando" :z-index="300">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      
    </v-card-text>  
  
  </template>
  
  <script>
  import moment from 'moment'
  export default {
    name: 'ControleEnvioGuia',
    props: ['fornecedor'],
    data() {
      return {
        mes: null,
        busca: '',
        headers: [
          { text: 'Usuario', value: 'usuario', align: 'center'},
          { text: 'Data Envio', value: 'datahora_envio', align: 'center'},
          { text: 'Data Recebimento', value: 'datahora_recebimento', align: 'center'},
          { text: 'Arquivo', value: 'filename', align: 'center'},
          { text: "", value: "botao", align: "center", sortable: false },
        ],
        url: this.$http("baseURL"),
        pagination: {}, 
        carregando: false, 
        logguias: [],
        regra: {
          mes: [(v) => !!v || "O campo mês é obrigatório."],
        },
      }
    },
    watch: {
      fornecedor(newFornecedor,odlFornecedor) {
       this.buscar()
      }
    },
    methods: {
      async buscar() {
        this.carregando = true
        this.pagination.page = 1
        let data = {exportar: false,id_fornecedor:this.fornecedor.id_fornecedor,mes:this.mes}
        var response = await this.$http().post('/fornecedor/logguiaslista',{data})   
        if (response.status == 200) {
          // console.log(response.data)
          this.logguias = response.data.map(i => {
            return {
              ...i,
              datahora_envio: moment(i.datahora_envio).format('DD/MM/YYYY HH:mm:ss'),
              datahora_recebimento: i.datahora_recebimento ? moment(i.datahora_recebimento).format('DD/MM/YYYY HH:mm:ss') : ''
            }
          })           
        } else {
          this.logguias = []
        } 
        this.carregando = false
      },
      baixar(item) {
        window.open(item.url, "_blank");
      },
      async exportar() {
        this.carregando = true
        this.pagination.page = 1
        let data = {exportar: true,id_fornecedor:this.fornecedor.id_fornecedor,mes:this.mes}
        var response = await this.$http().post('/fornecedor/logguiaslista',{data})   
        window.location = response.data.url
        this.carregando = false
      },
    },
    mounted() {
      this.mes = this.$moment().format("YYYY-MM");
      console.log(this.fornecedor)
      this.buscar()
    }                
  }
  </script>
  
  <style>
  .ajusteBotoes {
    display: flex;
    flex-direction: column;
    align-content: space-around;
  }
  </style>