let servidor; 
import 'dotenv/config'
import axios from 'axios'
import store from '@/store'

try {
   servidor = require('./servidor')
} catch (error) {
    servidor = {
        node_env: 'development'
    }
}

var baseURL = 'https://api-rd.homo.ipdvonline.com.br';
///////////
// Vamos dar preferência para as variáveis de ambiente ao invés de usar o arquivo de configuração
/////////
if(servidor.node_env == 'devla'){

    baseURL = process.env.VUE_APP_DEVLA_URL ||  'http://raia-api-la.ipdvlnx.com.br:3990'
}

if(servidor.node_env == 'development'){

    baseURL = process.env.VUE_APP_DEVELOPMENT_URL ||  'http://localhost:3800'
}

if(servidor.node_env == 'production_local'){

    baseURL = 'http://localhost:3300'
}

if(servidor.node_env == 'test'){

    baseURL = process.env.VUE_APP_TEST_URL ||  'http://raia-api.readpdv.com.br'
}

if(servidor.node_env == 'production'){

    baseURL = process.env.VUE_APP_PRODUCTION_URL ||  'https://api-trade.rdtrade.com.br'
}

baseURL =  process.env.VUE_APP_BASE_URL || baseURL

export const http = (tipo = 'http') => {
    console.log(baseURL);
    console.log('env', process.env.VUE_APP_BASE_URL);
    var http = axios.create({
        baseURL: baseURL,
        params: {
            x: Date.now()
        },
        headers: {
            token: store.getters.getToken
        },
    })

    http.interceptors.response.use((response) => {

        return response
    }, (error) => {
        error.erro = true;

        if (error.response?.status == 401) {
            store.dispatch('sessaoExpirou')
            return
        }
        if(error.response){
            error.response.erro = true;
            return error.response;
        }
        return error
    })

    if(tipo == 'http') {

        return http
    }
    if(tipo == 'baseURL') {

        return baseURL
    }    
}