<template>
    <v-form ref="form_4">
        <v-row no-gutters class="mt-4 mb-4 ">
            <v-col cols="6" class="px-2">
                <v-text-field
                    v-model="buscaProduto"
                    label="Pesquisar"
                    class="input-cinza"
                    dense
                    outlined
                    hide-details
                >
                    <template v-slot:append>
                        <div class="botao-pesquisa">
                            a <v-icon>mdi-magnify</v-icon>
                        </div>
                    </template>
                </v-text-field>
            </v-col>

            <v-col cols="3"  offset="3" class="px-2">
                <v-btn
                    class="ml-auto mb-1 d-block new-button"
                    color="primary"
                    @click="abrirImportacao()"
                >
                    <v-icon left>mdi-upload</v-icon> Importar Base de Produtos
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            v-model="produtoSelecionado"
            :headers="listaHeaderProduto"
            :items="produto"
            :search="buscaProduto"
            item-key="id_produto"
            class="tabela paginacao-esquerda"
            :items-per-page="5"
            :footer-props="{
                'items-per-page-options': [5, 10, 15, 25, 50, 100],
                itemsPerPageText: 'Linhas por páginas',
                ofText: 'de',
                pageText: '{0}-{1} de {2}',
            }"
            :options.sync="pagination"
            show-select
        >
            <!-- <template v-slot:[`item.vb`]="{ item }">
                <v-text-field
                    :ripple="false"
                    dense
                    outlined
                    v-mask="'####'"
                    v-model="item.vb"
                    hide-details
                ></v-text-field>
            </template> -->
        </v-data-table>
        <div
            class="
                table-footer-prepend
                d-flex
                pl-2
                pr-0
                mr-0
                pt-6
                
                align-center
                justify-end
            "
        >
            <v-row no-gutters class="mb-3 mt-1">
                <v-col cols="12" class="px-2 ml-auto" align="end">
                    <v-btn
                        class="ml-4 mb-1 new-button"
                        color="error"
                        @click="limpaProdutos(true)"
                    >
                        <v-icon left>mdi-trash-can</v-icon> Limpar Produtos
                    </v-btn>

                    <v-btn
                        class="ml-4 mb-1 new-button"
                        color="primary"
                        @click="carregarProduto()"
                    >
                        <v-icon left>mdi-arrow-up</v-icon> Carregar Produtos
                        Salvos
                    </v-btn>
                    <v-btn
                        class="ml-4 mb-1 new-button"
                        color="primary"
                        @click="salvarProduto()"
                    >
                        <v-icon left>mdi-arrow-down</v-icon> Salvar Produtos
                        Selecionados
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-data-table
            :headers="listaHeaderProdutoSalvo"
            :items="value.produtos"
            item-key="nome"
            class="elevation-0 tabela mt-4"
            :footer-props="{
                'items-per-page-options': [5, 10, 15, 25, 50, 100],
                itemsPerPageText: 'Linhas por páginas',
                ofText: 'de',
                pageText: '{0}-{1} de {2}',
            }"
            disable-sort
        >
        </v-data-table>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 6"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>

        <v-overlay :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <importar-produtos
            ref="importarProdutos"
            @importar="importar"
            @exportarModelo="exportarModelo"
        ></importar-produtos>
        <div v-if="dialogoCamposFaltando" class="dialogo-erro" width="700px">
            <v-card class="br-15">
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        Por favor retorne as etapas anteriores e preencha os
                        seguintes campos:</v-toolbar-title
                    >
                </v-toolbar>
                <v-card-text class="pa-5">
                    <v-list>
                        <v-list-item
                            v-for="campo in camposFaltando"
                            :key="campo"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    campo
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </div>

        <v-overlay
            :value="semAcesso"
            :absolute="true"
            :opacity="0.1"
            :z-index="3"
        ></v-overlay>
        <v-overlay
            :value="mostraOverlay"
            :absolute="true"
            :opacity="0.6"
            :z-index="3"
        ></v-overlay>
    </v-form>
</template>

<script>
import importarProdutos from "../formulario/importarProdutos.vue";

export default {
    components: {
        importarProdutos,
    },
    props: ["value"],
    data() {
        return {
            acordo: {},
            produto: [],
            produtoServidor: [],
            produtoSelecionado: [],
            somaProdutoSalvo: [],
            buscaProduto: "",
            dialogoCamposFaltando: false,
            vb: "",
            listaHeaderProduto: [
                {
                    text: "Código",
                    value: "codigo",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Marca",
                    value: "marca",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Descrição",
                    value: "nome",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Ativo/Inativo",
                    value: "ativo_inativo",
                    align: "center",
                    class: "th-separador",
                },
                { text: "Curva do produto", value: "curva", align: "center" },
            ],
            listaHeaderProdutoSalvo: [
            {
                    text: "Código",
                    value: "codigo",
                    align: "center",
                    class: "th-separador",
                    width: "150px",
                },
                { text: "Produtos Salvos", value: "nome", align: "center" },
                // { text: "Quantidade de VB", value: "vb", align: "center" },
            ],
            carregando: false,
            pagination: {},
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
            semAcesso: false,
        };
    },
    computed: {
        camposFaltando() {
            const campos = [];
            if (!this.value.id_fornecedor) {
                campos.push("Fornecedor");
            }
            return campos;
        },
        mostraOverlay() {
            if (this.camposFaltando.length > 0) {
                return true;
            }
            return false;
        },
    },
    methods: {
        setSemAcesso(semAcesso) {
            this.semAcesso = semAcesso;
        },
        async buscaProdutos() {
            if (this.camposFaltando.length > 0) {
                this.dialogoCamposFaltando = true;
                return;
            }
            if (!this.value.fnEditar && this.produto.length > 0) {
                this.dialogoCamposFaltando = false;
                return;
            }
            if(this.produtoServidor.length > 0){
                return
            }
            this.produtoSelecionado = [];

            this.dialogoCamposFaltando = false;
            this.carregando = true;

            var data = {
                id_fornecedor: this.value.id_fornecedor,
            };

            if (this.value.fnEditar) {
                data.id_acordo = this.value.idAcordos[0];
            }

            var response = await this.$http().post(
                "/acordoEcommerce/produtos",
                {
                    data: data,
                }
            );

            this.produto = response.data;

            this.produtoServidor = response.data;

            this.pagination.page = 1;

            this.produtoSelecionado = [];

            if (this.value.fnEditar) {
                for (const p of this.produto) {
                    if (p.selecionado) {
                        this.produtoSelecionado.push(p);
                    }
                }
                this.salvarProduto(false);
            }

            this.carregando = false;
        },

        salvarProduto(alert = true) {
            this.value.produtos = [];

            for (const d of this.produtoSelecionado) {
                d.selecionado = true;
                this.value.produtos.push({ ...d });
            }
        },
        carregarProduto() {
            this.produto = [];
            this.produtoSelecionado = [];

            for (const p of this.value.produtos) {
                this.produto.push(p);
                console.log(p);
                if ( p.selecionado) {
                    this.produtoSelecionado.push(p);
                }
            }

            this.pagination.page = 1;
        },
        recarregarProduto() {
            this.produto = [];

            for (const d of this.produtoServidor) {
                this.produto.push({ ...d });
            }

            this.pagination.page = 1;
        },
        async limpaProdutos(fnServidor = false) {
            this.produto = [];
            this.produtoServidor = [];
            this.produtoSelecionado = [];
            this.value.produtos = [];
            this.somaProdutoSalvo = [];
            this.vb = "";

            if (fnServidor) {
               await this.buscaProdutos();
            }

        },
        async abrirImportacao() {
            this.$refs.importarProdutos.abrir(this.produtoServidor);
        },
        async importar() {
            var dadosTemp = this.$refs.importarProdutos.dadosTemp;
            // this.produto = [];
            this.produtoSelecionado = [];

            for (const p of dadosTemp) {
                // this.produto.push(p);

                this.produtoSelecionado.push(p);
            }

            this.salvarProduto();
        },
        async exportarModelo() {
            this.$refs.importarProdutos.carregando = true;

            var data = {
                id_fornecedor: this.value.id_fornecedor,
                sem_vb: true,
            };
            
            var response = await this.$http().post("/acordo/modeloProdutos", {
                data: data,
            });

            window.location = this.$http("baseURL") + response.data.url;

            this.$refs.importarProdutos.carregando = false;
        },
        validate() {
            if (this.value.produtos.length > 0) {
                return true;
            }
            return false;
        },
        reset() {
            this.produto = [];
            this.produtoServidor = [];
            this.produtoSelecionado = [];
        },
    },
};
</script>
<style >
.botao-pesquisa {
    background: red;
}
.dialogo-erro {
    color: red;
    z-index: 1000;
    position: absolute;
    width: 700px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-top: 100px;
}
</style>