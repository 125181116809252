<template>
    <v-form ref="form">
        <v-row no-gutters class="mt-4 h-100">
            <v-col cols="3" class="px-2">
                <v-text-field
                    label="Desconto (%)"
                    v-model="descontoCampo.percentual"
                    class="input-cinza"
                    outlined
                    dense
                    v-mask="'###'"
                ></v-text-field>
            </v-col>
            <v-col cols="3" class="px-2">
                <vuetify-money
                    label="Desconto (R$)"
                    v-model="descontoCampo.desconto"
                    class="input-cinza"
                    outlined
                    dense
                    backgroundColor=""
                />
            </v-col>
            <v-col cols="3" class="px-2">
                <v-btn
                    class="ml-2 mb-1 new-button"
                    color="primary"
                    @click="carregarDesconto()"
                >
                    <v-icon left>mdi-arrow-down</v-icon> Carregar Desconto
                </v-btn>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                md="2"
                class="pt-0 mt-0 d-flex"
                justify="center"
				v-if="mostrarPreAcordo"
            >
			<div class="d-flex"                     
 >
            
                    <InfoBox class="mt-1"  >
                        <p>
							Caso deseje prosseguir com o acordo, mas ainda não possui as informações sobre os produtos, ative o Pré acordo ao lado.
                        </p></InfoBox
                    >

					<v-checkbox
                    class="flag-pre-acordo  ml-2"
                    v-model="value.preAcordo"
                    label="Pré acordo"
                    dark
					
                >
                </v-checkbox>
                </div>
            </v-col>
        </v-row>
        <v-data-table
            :headers="listaHeaderDesconto"
            :items="desconto"
            :item-class="investimentoClass"
            item-key="nome"
            class="elevation-0 tabela"
            hide-default-footer
            disable-sort
            :items-per-page="-1"

        >
            <!-- <template v-slot:[`item.percentual`]="{ item }">
                <v-text-field
                    v-if="item.mes != 'Total'"
                    :ripple="false"
                    dense
                    outlined
                    hide-details
                    v-mask="'###'"
                    v-model="item.percentual"
                    @input="calculaDesconto()"
                ></v-text-field>
            </template> -->
            <template v-slot:[`item.custo`]="{ item }"  >
                <vuetify-money
                v-if="item.mes != 'Total' && value.mecanismo_preco_id == 3" 
                    :ripple="false"
                    dense
                    outlined
                    hide-details
                    v-model="item.valor"
                    class="input-cinza"
                    @input="calculosPrecosFixos(item)"
                />
                <span v-else>{{ item.custo }}</span>
            </template>
            <template v-slot:[`item.percentual`]="{ item }">
                <v-text-field
                    v-if="item.mes != 'Total'"
                    :ripple="false"
                    dense
                    outlined
                    hide-details
                    v-mask="'###'"
                    class="input-cinza"
                    :placeholder="`${item.desconto_padrao}% (Desconto padrão)`"
                    v-model="item.percentual"
                    @input="calculaDesconto()"
                ></v-text-field>
            </template>
            <!-- <template v-slot:[`item.desconto`]="{ item }">
                <vuetify-money
                    v-if="item.mes != 'Total'"
                    :ripple="false"
                    dense
                    outlined
                    hide-details
                    v-model="item.desconto"
                    backgroundColor=""
                    @input="calculaDesconto()"
                />
            </template> -->
            <template v-slot:[`item.desconto`]="{ item }">
                <vuetify-money
                    v-if="item.mes != 'Total'"
                    :ripple="false"
                    dense
                    outlined
                    hide-details
                    v-model="item.desconto"
                    class="input-cinza"
                    @input="calculaDesconto()"
                />
            </template>
            <template v-slot:[`item.justificativas`]="{ item }">
                <v-select
                    required
                    placeholder="Justificativa"
                    v-model="item.justificativa"
                    :rules="regras.justificativa"
                    :items="justificativas"
                    item-text="justificativa"
                    item-value="id_justificativa"
                    dense
                    outlined
                    hide-details
                    @change="atualizaDescontos(item)"
                    class="input-cinza"
                    :menu-props="menuProps"
                    v-if="
                        ((item.percentual != '' &&
                            item.percentual != undefined) ||
                            (item.desconto != '' &&
                                item.desconto != undefined &&
                                item.desconto != 0)) &&
                        item.mes != 'Total'
                    "
                ></v-select>
            </template>
        </v-data-table>

        <div v-if="dialogoCamposFaltando" class="dialogo-erro" width="700px">
            <v-card class="br-15">
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        Por favor retorne as etapas anteriores e preencha os
                        seguintes campos:</v-toolbar-title
                    >
                </v-toolbar>
                <v-card-text class="pa-5">
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <p>{{ dialogoCamposFaltandoMensagem }}</p>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </div>
        <!-- <v-btn class="ml-2 mb-1 new-button" color="primary" @click="validate()">
            <v-icon left>mdi-arrow-down</v-icon> Valida
        </v-btn> -->

        <v-overlay :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-overlay
            :value="semAcesso"
            :absolute="true"
            :opacity="0.1"
            :z-index="3"
        ></v-overlay>
        <v-overlay
            :value="mostraOverlay"
            :absolute="true"
            :opacity="0.6"
            :z-index="3"
        ></v-overlay>
    </v-form>
</template>

<script>
export default {
    props: ["value", "preacordo"],
    data() {
        return {
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            acordo: {},
            somaFiliais: [],
            somaFiliaisValor: [],
            dialogoCamposFaltando: false,
            dialogoCamposFaltandoMensagem: "",
            desconto: [],
            descontoCampo: {
                percentual: null,
                desconto: null,
            },
            regras: {
                justificativa: [
                    (v) => !!v || "O campo justificativa é obrigatório",
                ],
            },
            listaHeaderDesconto: [
                {
                    text: "Mês",
                    value: "mes",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Filiais",
                    value: "filiais",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Custo",
                    value: "custo",
                    align: "center",
                    class: "th-separador min-width-100",
                },
                {
                    text: "Percentual (%)",
                    value: "percentual",
                    align: "center",
                    class: "th-separador",

                    width: "14%",
                },
                {
                    text: "Desconto (R$)",
                    value: "desconto",
                    align: "center",
                    class: "th-separador",

                    width: "14%",
                },
                {
                    text: "Justificativa",
                    value: "justificativas",
                    align: "center",
                    class: "th-separador",

                    width: "14%",
                },
                {
                    text: "Total de Desconto (R$)",
                    value: "total",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Investimento (R$)",
                    value: "investimento",
                    align: "center",
                    class: "th-separador",
                },
            ],
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false /* doesn't work with directive */,
            },
            carregando: false,
            semAcesso: false,
            mesDesconto: [],
            justificativas: [],
            primeiraAlteracao: true,
            precoFixoClonando: true
        };
    },
    computed: {
        mostrarPreAcordo() {
            if (this.acordo.idStatus === 30) {
                return true;
            }
            const data = this.$moment().format("YYYY-MM");
            if (!this.acordo.idStatus && data !== this.acordo.mesInicial) {
                return true;
            }
            return false;
        },

        mostraOverlay() {
            if (this.dialogoCamposFaltandoMensagem.length > 0) {
                return true;
            }
            return false;
        },
    },
    methods: {
        atualizaDescontos(item) {
            if (this.primeiraAlteracao) {
                for (const d of this.desconto) {
                    if (d.mes != item.mes && d.mes != "Total") {
                        d.desconto = item.desconto;
                        d.percentual = item.percentual;
                        d.justificativa = item.justificativa;
                    }
                }
            }
            this.calculaDesconto();
            this.primeiraAlteracao = false;
        },
        setSemAcesso(semAcesso) {
            this.semAcesso = semAcesso;
        },
        preAcordoFunc(currentValue) {
            console.log(" func pre acordo", currentValue);
            // this.$emit('preacordo', currentValue)
            this.$emit("update:preacordo", currentValue === true);
        },

        getPeriodo(campo = "quantidade") {
            var mesAtual = new Date(this.acordo.mesInicial + "-15");
            var mesFinal = new Date(this.acordo.mesFinal + "-15");

            var periodo = [];

            while (mesAtual <= mesFinal) {
                periodo.push(
                    campo +
                        "_" +
                        mesAtual.getFullYear() +
                        "_" +
                        (mesAtual.getMonth() + 1)
                );

                mesAtual.setMonth(mesAtual.getMonth() + 1);
            }

            return periodo;
        },
        getDesconto(periodo) {
            var mes =
                periodo.split("_")[1] +
                "-" +
                ("00" + periodo.split("_")[2]).substr(-2) +
                "-01";

            if (this.mesDesconto.length == 0) return 0;

            return this.mesDesconto.find((e) => e.mes.substr(0, 10) == mes)
                .desconto;
        },
        async criaDesconto(
            acordo,
            somaFiliais,
            somaFiliaisValor,
            quantidadeFiliais,
            errosFiliais
        ) {
            if (this.desconto.length > 0) return;

            if (quantidadeFiliais == 0 || errosFiliais) {
                this.dialogoCamposFaltando = true;
                this.dialogoCamposFaltandoMensagem =
                    "Por favor selecione pelo menos uma filial para continuar";

                return;
            }

            this.dialogoCamposFaltando = false;
            this.dialogoCamposFaltandoMensagem = "";
            this.carregando = true;

            this.desconto = [];

            this.acordo = acordo;
            this.somaFiliais = somaFiliais;
            this.somaFiliaisValor = somaFiliaisValor;

            var data = {
                mesInicial: this.acordo.mesInicial + "-01",
                mesFinal: this.acordo.mesFinal + "-01",
                id_fornecedor: this.acordo.id_fornecedor,
            };

            this.mesDesconto = (
                await this.$http().post("/fornecedor/mesDesconto", {
                    data: data,
                })
            ).data;
            let descontosJustificativas = (
                await this.$http().post("acordo/justificativa")
            ).data;
            this.justificativas = descontosJustificativas.map((i) => {
                return {
                    justificativa: i.justificativa,
                    id_justificativa: i.id_justificativa,
                };
            });
            this.descontoCampo.percentual = null;
            this.descontoCampo.desconto = null;

            var desconto = [];
            var descontoMes = {};
            var descontoTotal = {};

            descontoTotal.mes = "Total";
            descontoTotal.mes_desconto = null;
            descontoTotal.filiais = this.somaFiliais[0].total;
            descontoTotal.custo = null;
            descontoTotal.valor = 0;
            descontoTotal.desconto_padrao = null;
            descontoTotal.percentual = null;
            descontoTotal.desconto = null;
            descontoTotal.total = 0;
            descontoTotal.totalValor = 0;
            descontoTotal.investimento = 0;

            for (const p of this.getPeriodo()) {
                let data = p.split("_");

                descontoMes.mes = ("0" + data[2]).slice(-2) + "/" + data[1];
                descontoMes.mes_desconto = data[1] + "-" + data[2];
                descontoMes.filiais = this.somaFiliais[0][p];
                descontoMes.custo = this.somaFiliais[1][p];
                descontoMes.valor = this.somaFiliaisValor[p];
                descontoMes.desconto_padrao = this.getDesconto(p);
                descontoMes.percentual = null;
                descontoMes.desconto = null;
                descontoMes.totalValor =
                    (descontoMes.valor * this.getDesconto(p)) / 100;
                descontoMes.total = descontoMes.totalValor.toLocaleString(
                    "pt-br",
                    { style: "currency", currency: "BRL" }
                );
                descontoMes.justificativa = "";

                descontoMes.investimentoValor =
                    descontoMes.valor - descontoMes.totalValor;
                descontoMes.investimento =
                    descontoMes.investimentoValor.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    });

                descontoTotal.valor += this.somaFiliaisValor[p];
                descontoTotal.total +=
                    (descontoMes.valor * this.getDesconto(p)) / 100;
                descontoTotal.totalValor +=
                    (descontoMes.valor * this.getDesconto(p)) / 100;
                descontoTotal.investimento += descontoMes.investimentoValor;

                desconto.push({ ...descontoMes });
            }

            descontoTotal.custo = descontoTotal.valor.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
            descontoTotal.total = descontoTotal.total.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
            descontoTotal.investimento =
                descontoTotal.investimento.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                });

            desconto.push({ ...descontoTotal });
            this.desconto = desconto.map((a) => ({ ...a }));
            this.primeiraAlteracao = true;

            if (this.acordo.fnEditar) {
                let data = {};

                data.acordo = this.acordo;

                let response = await this.$http().post("/acordo/desconto", {
                    data: data,
                });

                for (let i = 0; i < response.data.length; i++) {
                    this.desconto[i].desconto_padrao =
                        response.data[i].desconto_padrao;
                    this.desconto[i].percentual =
                        response.data[i].desconto_percentual;
                    this.desconto[i].desconto = response.data[i].desconto;
                    this.desconto[i].justificativa =
                        response.data[i].id_justificativa;
                }
                this.primeiraAlteracao = false;
                this.calculaDesconto();
            } else {
                setTimeout(() => this.carregarDesconto(), 100);
            }

            this.carregando = false;
        },
        carregarDesconto() {
            for (const d of this.desconto) {
                if (d.mes != "Total") {
                    d.percentual = this.descontoCampo.percentual;
                    d.desconto = this.descontoCampo.desconto;
                }
            }

            this.calculaDesconto();
        },
        calculosPrecosFixos (item) {
            if (this.precoFixoClonando) {
                if (this.desconto[0].mes == item.mes) {
                    const valor = item.valor
                    this.desconto = this.desconto.map(item => {
                        return {...item,valor:valor }
                    })
                } else {
                    this.precoFixoClonando = false
                }
                this.calculaDesconto() 
            }
        },
        calculaDesconto() {
            var descontoTotal = 0;
            var investimentoTotal = 0;

            for (const d of this.desconto) {
                if (d.mes != "Total") {
                    if (
                        d.percentual > 0 ||
                        d.percentual === "0" ||
                        d.percentual === 0
                    ) {
                        d.total =
                            (d.valor * d.percentual) / 100 + d.desconto * 1;
                        d.totalValor =
                            (d.valor * d.percentual) / 100 + d.desconto * 1;
                    } else {
                        d.total =
                            (d.valor * d.desconto_padrao) / 100 +
                            d.desconto * 1;
                        d.totalValor =
                            (d.valor * d.desconto_padrao) / 100 +
                            d.desconto * 1;
                    }

                    descontoTotal += d.total;
                    investimentoTotal += d.valor - d.totalValor;
                    d.total = d.total.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    });
                    d.investimento = (
                        Math.ceil(100 * (d.valor - d.totalValor)) / 100
                    ).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    });
                    d.investimentoValor =
                        Math.ceil(100 * (d.valor - d.totalValor)) / 100;

                    if (
                        (d.percentual === 0 ||
                            d.percentual === "0" ||
                            d.percentual == undefined ||
                            d.percentual === "") &&
                        (d.desconto === 0 ||
                            d.desconto === "0" ||
                            d.desconto == undefined ||
                            d.desconto === "")
                    ) {
                        d.justificativa = "";
                    }
                }
            }

            this.desconto[this.desconto.length - 1].total =
                descontoTotal.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                });
            this.desconto[this.desconto.length - 1].totalValor = descontoTotal;
            this.desconto[this.desconto.length - 1].investimento = (
                Math.ceil(100 * investimentoTotal) / 100
            ).toLocaleString("pt-br", { style: "currency", currency: "BRL" });
        },
        investimentoClass(item) {
            if (item.mes == "Total") {
                return "";
            } else {
                return item.investimentoValor < 0
                    ? "style-investimento-negativo"
                    : "";
            }
        },
        limpaDescontos() {
            console.log("limpaDescontos");
            this.desconto = [];
        },
        validate() {
            this.$refs.form.validate();
            if (this.desconto.length == 0) {
                return false;
            }

            const validaValores = !this.desconto.some(
                (v) => v.investimentoValor < 0
            );
            const validaCampos = this.$refs.form.validate();
            console.log({
                validaValores,
                validaCampos,
                desconto: this.desconto,
            });
            if (validaValores && validaCampos) {
                return true;
            }
            return false;
        },
    },
};
</script>

<style>
.style-investimento-negativo {
    color: var(--v-error-base) !important;
}
.flag-pre-acordo {
    margin: 0 auto;
    padding-top: 0;
}
.flag-pre-acordo .v-label {
    color: #fff;
}
.flag-pre-acordo .v-input__slot {
    background: var(--v-yellow-base);
    padding: 7px 20px;
    border-radius: 6px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
</style>
