<template>
    <v-form ref="form_4">
        <v-row >
            <v-col lg="3" xl="4"  class="px-2">
                <v-text-field
                    v-model="buscaProduto"
                    append-icon="mdi-magnify"
                    class="input-button input-cinza"
                    label="Pesquisar"
                    dense
                    outlined
                    hide-details
                >
                    <template>
                        <v-icon slot="append" color="white">mdi-magnify</v-icon>
                    </template></v-text-field
                >
            </v-col>
            <v-col cols="2" class="px-2">
                <v-text-field
                    label="VB"
                    v-model="vb"
                    class="input-cinza py-0"
                    outlined
                    dense
                    v-mask="'####'"
                ></v-text-field>
            </v-col>
            <v-col lg="7" xl="6" class="px-2">
                <v-btn class="new-button" color="primary" @click="carregarVB()">
                    <v-icon left>mdi-arrow-down</v-icon> Carregar VB
                </v-btn>
                <v-btn
                    class="ml-4 new-button"
                    color="primary"
                    @click="recarregarProduto()"
                >
                    <v-icon left>mdi-autorenew</v-icon> Recarregar Produtos
                </v-btn>
                <v-btn
                    class="ml-4 new-button"
                    color="primary"
                    @click="abrirImportacao()"
                >
                    <v-icon left>mdi-upload</v-icon> Importar Base de Produtos
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="listaHeaderProduto"
            :items="produto" 
            :search="buscaProduto"
            item-key="id_produto"
            class="elevation-0 tabela"
            :items-per-page="5"
            :footer-props="{
                'items-per-page-options': [5, 10, 15, 25, 50, 100, -1],

            }"
            :options.sync="pagination"
        >
            <template v-slot:[`item.pdv`]="{ item }">
                <v-checkbox
                    :ripple="false"
                    v-model="item.pdv"
                    hide-details
                    class="d-inline-flex mt-0"
               ></v-checkbox>
            </template>
            <template v-slot:[`item.ecommerce`]="{ item }">
                <v-checkbox
                    :ripple="false"
                    v-model="item.ecommerce"
                    hide-details
                    class="d-inline-flex mt-0"
               ></v-checkbox>
            </template>
            <template v-slot:[`item.vb_pdv`]="{ item }">
                <v-text-field
                    :ripple="false"
                    dense
                    outlined
                    v-mask="'####'"
                    class="input-cinza"
                    v-model="item.vb_pdv"
                    @change="vbChange(item)"
                    hide-details
                ></v-text-field>
            </template>
        </v-data-table>

        <v-row no-gutters class="mb-3 mt-1">
            <v-col cols="12" class="px-2 ml-auto" align="end">
                <v-btn
                    class="ml-2 mb-1 new-button"
                    color="error"
                    @click="limpaProdutos(true)"
                >
                    <v-icon left>mdi-trash-can</v-icon> Limpar Produtos
                </v-btn>

                <v-btn
                    class="ml-2 mb-1 new-button"
                    color="primary"
                    @click="carregarProduto()"
                >
                    <v-icon left>mdi-arrow-up</v-icon> Carregar Produtos Salvos
                </v-btn>
                <v-btn
                    class="ml-2 mb-1 new-button"
                    color="primary"
                    @click="salvarProduto()"
                    
                >
                    <v-icon left>mdi-arrow-down</v-icon> Salvar Produtos
                    Selecionados
                </v-btn>
            </v-col>
        </v-row>

        <!-- <v-data-table
            :headers="listaHeaderProdutoSalvo"
            :items="somaProdutoSalvo"
            item-key="nome"
            class="elevation-0 tabela"
            hide-default-footer
            disable-sort
        >
        </v-data-table> -->

        <v-data-table
            :headers="listaHeaderProdutoSalvo"
            :items="produtoSalvo"
            item-key="nome"
            class="elevation-0 tabela mt-4"
            hide-default-footer
            disable-sort
        >
        <template v-slot:[`item.pdv`]="{ item }">
                <v-checkbox
                    :ripple="false"
                    v-model="item.pdv"
                    hide-details
                    class="d-inline-flex mt-0"
                    disabled
               ></v-checkbox>
            </template>
            <template v-slot:[`item.ecommerce`]="{ item }">
                <v-checkbox
                    :ripple="false"
                    v-model="item.ecommerce"
                    hide-details
                    class="d-inline-flex mt-0"
                    disabled
               ></v-checkbox>
            </template>
        <template v-slot:[`item.vb_pdv`]="{ item }">
                <v-text-field
                    :ripple="false"
                    dense
                    outlined
                    class="input-cinza"
                    v-mask="'####'"
                    v-model="item.vb_pdv"
                    hide-details
                ></v-text-field>
            </template>
        </v-data-table>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 6"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>

        <v-overlay :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <importar-produtos
            ref="importarProdutos"
            @importar="importar"
            @exportarModelo="exportarModelo"
        ></importar-produtos>
        <div v-if="dialogoCamposFaltando" class="dialogo-erro" width="700px">
            <v-card class="br-15">
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        Por favor retorne as etapas anteriores e preencha os
                        seguintes campos:</v-toolbar-title
                    >
                </v-toolbar>
                <v-card-text class="pa-5">
                    <v-list>
                        <v-list-item
                            v-for="campo in camposFaltando"
                            :key="campo"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    campo
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </div>
        <v-overlay
            :value="semAcesso"
            :absolute="true"
            :opacity="0.1"
            :z-index="3"
        ></v-overlay>
        <v-overlay
            :value="mostraOverlay"
            :absolute="true"
            :opacity="0.6"
            :z-index="3"
        ></v-overlay>
    </v-form>
</template>

<script>
import importarProdutos from "./importarProdutos.vue";

export default {
    components: {
        importarProdutos,
    },
    props: ["value"],
    data() {
        return {
            acordo: {},
            produto: [],
            produtoServidor: [],
            // produtoSelecionado: [],
            produtoSalvo: [],
            somaProdutoSalvo: [],
            dialogoCamposFaltando: false,
            buscaProduto: "",
            vb: "",
            listaHeaderProduto: [
                {
                    text: "PDV",
                    value: "pdv",
                    align: "center",
                    class: "th-separador text-xs-center",
                    width: "60px",
                },
                {
                    text: "E-com.",
                    value: "ecommerce",
                    align: "center",
                    class: "th-separador text-xs-center",
                    width: "60px",
                },
                {
                    text: "Código",
                    value: "codigo",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Marca",
                    value: "marca",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Descrição",
                    value: "nome",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Ativo/Inativo",
                    value: "ativo_inativo",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Curva do produto",
                    value: "curva",
                    align: "center",
                    class: "th-separador",
                },
                { text: "VB", value: "vb_pdv", align: "center", width: "120px" },
            ],
            listaHeaderProdutoSalvo: [
            {
                    text: "PDV",
                    value: "pdv",
                    align: "center",
                    class: "th-separador text-xs-center",
                    width: "60px",
                },
                {
                    text: "E-com.",
                    value: "ecommerce",
                    align: "center",
                    class: "th-separador text-xs-center",
                    width: "60px",
                },
                {
                    text: "Produtos Salvos",
                    value: "nome",
                    align: "center",
                    class: "th-separador",
                },
                { text: "Quantidade de VB", value: "vb_pdv", align: "center" },
            ],
            carregando: false,
            pagination: {},
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
            semAcesso: false,
        };
    },
    computed: {
        camposFaltando() {
            const campos = [];
            if (!this.value.id_fornecedor) {
                campos.push("Fornecedor");
            }
            return campos;
        },
        mostraOverlay() {
            if (this.camposFaltando.length > 0) {
                return true;
            }
            return false;
        },
        produtoSelecionado(){
            return this.produto.filter(item => item.ecommerce || item.pdv);
        }
    },
    methods: {
        setAcordo(acordo) {
            //this.acordo = { ...acordo };
        },
        setSemAcesso(semAcesso) {
            this.semAcesso = semAcesso;
        },
       async vbChange(value){
            if(value.vb_pdv !== ''){
                value.pdv = true
        
            }else {
                value.pdv = false
            }
            await this.$forceUpdate();

        },
        async buscaProdutos() {
            if (this.camposFaltando.length > 0) {
                this.dialogoCamposFaltando = true;
                return;
            }
            if (!this.value.fnEditar && this.produto.length > 0) {
                this.dialogoCamposFaltando = false;
                return;
            }
            if (this.produtoServidor.length > 0) {
                return;
            }
            // this.produtoSelecionado = [];

            this.dialogoCamposFaltando = false;
            this.carregando = true;
            this.carregando = true;

            var data = {};

            data.id_fornecedor = this.value.id_fornecedor;

            if (this.value.fnEditar || this.value.fnReplicar) {
                data.id_acordo = this.value.idAcordos[0];
                
            }

            var response = await this.$http().post("/acordoCampanhas/produtos", {
                data: data,
            });

            this.produto = response.data;

            this.produtoServidor = response.data;

            this.pagination.page = 1;

            if (this.value.fnEditar || this.value.fnReplicar) {
                for (const p of this.produto) {
                    if (p.vb_pdv != null || p.vb_ecommerce) {
                        if(p.vb !== null){
                            p.pdv = true
                        }
                        if(p.vb_ecommerce == 1){
                            p.ecommerce = true
                        }
                        this.produtoSelecionado.push(p);
                    }
                }
                this.salvarProduto(false);
            }

            this.carregando = false;
        },
        carregarVB() {
            for (const d of this.produto) {
                d.vb = this.vb;
            }
        },
        salvarProduto(alert = true) {
            this.produtoSalvo = [];

            const vbZerado = this.produto.filter(
                (p) => p.pdv == true && p.vb_pdv == 0
            );


            var vb = 0;
            var fnVB = 0;
            for (const produto of this.produto ){
                if ((produto.pdv == true) || produto.ecommerce == true){
                    this.produtoSalvo.push(produto);
                }
            }

            if (vbZerado.length >0 && alert) {
                this.alerta = true;
                this.alertaColor = "info";
                this.alertaTimeout = 3000;
                this.alertaTexto = "Existem produtos com VB igual a zero.";
            }

            var soma = [
                {
                    produto: this.produtoSalvo.length,
                    vb: vb,
                },
            ];

            this.somaProdutoSalvo = soma;
        },
        carregarProduto() {
            this.produto = [];
            // this.produtoSelecionado = [];

            for (const p of this.produtoSalvo) {
                this.produto.push(p);
    
                if ( +p.vb > 0) {
                    this.produtoSelecionado.push(p);
                }
            }

            this.pagination.page = 1;
        },
        recarregarProduto() {
            this.produto = [];

            for (const d of this.produtoServidor) {
                this.produto.push({ ...d });
            }

            this.pagination.page = 1;
        },
        limpaProdutosSelecionados() {
            this.produto.forEach(produto => {
                produto.pdv = false;
                produto.ecommerce = false;
                produto.vb = null
            })
        },
        limpaProdutos(fnServidor = false) {
            this.produto = [];
            this.produtoServidor = [];
            // this.produtoSelecionado = [];
            this.limpaProdutosSelecionados()
            this.produtoSalvo = [];
            this.somaProdutoSalvo = [];
            this.vb = "";

            if (fnServidor) {
                this.buscaProdutos();
            }
        },
        async abrirImportacao() {
            this.$refs.importarProdutos.abrir(this.produtoServidor);
        },
        async importar() {
            var dadosTemp = this.$refs.importarProdutos.dadosTemp;
            this.limpaProdutosSelecionados()
            // this.produto = [];
            // this.produtoSelecionado = [];

            // for (const p of dadosTemp) {
            //     this.produto.push(p);

            //     if (typeof p.vb == "number") {
            //         this.produtoSelecionado.push(p);
            //     }
            // }
            for (const produtoImportado of dadosTemp){
                const produtoIndex = this.produtoServidor.findIndex(p => p.id_produto == produtoImportado.id_produto);
                // const produto = this.produtoServidor.find(p => p.id_produto == produtoImportado.id_produto);
                if (produtoIndex > -1){
                    if(produtoImportado.vb_pdv > 0){
                        this.produtoServidor[produtoIndex].pdv = true;
                        this.produtoServidor[produtoIndex].vb = produtoImportado.vb_pdv;

                    } 
                    if(produtoImportado.vb_ecommerce > 0){
                        this.produtoServidor[produtoIndex].ecommerce = true;

                    }
                }
            }
            this.salvarProduto();
        },
        async exportarModelo() {
            this.$refs.importarProdutos.carregando = true;

            var data = {};

            data.id_fornecedor = this.value.id_fornecedor;

            if (this.value.fnEditar || this.value.fnReplicar) {
                data.id_acordo = this.value.idAcordos[0];
            }

            var response = await this.$http().post("/acordoCampanhas/modeloProdutos", {
                data: data,
            });

            window.location = this.$http("baseURL") + response.data.url;

            this.$refs.importarProdutos.carregando = false;
        },
        validate() {
            if (this.produtoSelecionado.length > 0) {
                return true;
            }
            return false;
            // else if (this.somaProdutoSalvo[0].produto == 0) {
            //     console.log("erro2");
            //     return true;
            // }
        },
    },
};
</script>