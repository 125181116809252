<template>
  <v-row class="mt-3">
    <v-col md="3">
      <Indicadores
        titulo="Taxa de Ocupação"
        icone="$percentageCircle"
        :valor="totalSomado.comprometidoPorcentagem"
        :load="false"
      />
    </v-col>
    <v-col md="3">
      <Indicadores
        titulo="Quantidade Ocupado"
        :valor="totalSomado.comprometido"
        icone="$expandIcon"
        :load="false"
      />
    </v-col>
    <v-col md="3">
      <Indicadores
        titulo="Taxa de Oportunidade"
        :valor="totalSomado.oportunidadePorcentagem"
        icone="$percentageCircle"
        :load="false"
      />
    </v-col>
    <v-col md="3">
      <Indicadores
        titulo="Oportunidade"
        icone="$tagDiscount"
        :valor="totalSomado.oportunidade"
        :load="false"
      />
    </v-col>
  </v-row>
</template>

<script>
import Indicadores from "./_components/Indicadores.vue";
export default {
  components: {
    Indicadores,
  },
  props: ["totais"],
  computed: {
    totalSomado() {
      if (this.totais?.graficoMensal && this.totais.graficoMensal.length > 0) {
        const oportunidade = this.totais.graficoMensal.reduce(
          (acc, item) => acc + item.oportunidade,
          0
        );
        const comprometido = this.totais.graficoMensal.reduce(
          (acc, item) => acc + item.comprometido,
          0
        );
        const total = oportunidade + comprometido;
        return {
          oportunidade,
          comprometido,
          oportunidadePorcentagem: this.formatarValor(
            (oportunidade / total) * 100
          ),
          comprometidoPorcentagem: this.formatarValor(
            (comprometido / total) * 100
          ),
        };
      } else {
        return {
          oportunidade: 9,
          comprometido: 9,
          oportunidadePorcentagem: 9,
          comprometidoPorcentagem: 9,
        };
      }
    },
  },
  methods: {
    formatarValor(valor = 0) {
      return `${valor.toFixed(2)} %`;
    },
  },
};
</script>

<style scoped>
.indicador {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}
.indicador::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background: #eaeaea;
  transition: all 0.4s;
}
.indicador:hover::before {
  background: var(--v-primary-base);
}
.indicador-icon {
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  position: relative;
  padding: 8px;
  margin-top: 6px;
  box-shadow: 0 0 10px #0002;
}
.indicador-text {
  font-weight: 500;
  color: #000;
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 0.9rem;
}
.indicador-separador {
  width: 25px;
  height: 1px;
  background: #e0e0e0;
  margin-top: 5px;
}
.indicador-value {
  font-size: 1rem;
  font-weight: 500;
  color: var(--v-primary-base);
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
