<template>
    <v-form ref="form">
        <v-row class="mt-4">
            <v-col cols="6" class="py-0">
                <v-select
                    label="Deixar Visível?"
                    outlined
                    dense
                    v-model="value.visivel"
                    :items="lista.visivel"
                    :rules="regra.visivel"
                    class="input-cinza"
                    :menu-props="menuProps"
                    required
                ></v-select>
            </v-col>
            <v-col cols="6" class="py-0">
                <v-file-input
                    label="Anexar Planograma"
                    :prepend-icon="null"
                    outlined
                    dense
                    v-model="value.planograma"
                    :rules="regra.planograma"
                    class="input-cinza input-button"
                >
                    <template>
                        <v-icon slot="append" color="white"
                            >mdi-paperclip</v-icon
                        >
                    </template>
                </v-file-input>
            </v-col>
            <v-col cols="12" class="">
                <v-textarea
                    outlined
                    v-model="value.comentario"
                    dense
                    rows="5"
                    label="Comentários Gerais"
                    class="input-cinza py-0"
                ></v-textarea>
            </v-col>
        </v-row>
        <div class="resumo">
            <div class="resumo-title">Resumo do acordo</div>
            <div class="resumo-conteudo">
                <div class="resumo-item">
                    <span class="titulo">Cadastro do Acordo:</span>
                    <p>
                        Periodo de
                        <strong>{{ formatarMes(value.mesInicial) }}</strong> até
                        <strong>{{ formatarMes(value.mesFinal) }}</strong>
                    </p>
                    <p>
                        Espaço: <strong>{{ espaco }}</strong>
                    </p>

                    <p>
                        Bandeira:
                        <strong>{{ value.bandeira }}</strong>
                    </p>
                </div>
                <v-divider />
                <div class="resumo-item">
                    <span class="titulo">Seleção de filias:</span>
                    <p>
                        Fornecedor: <strong>{{ value.fornecedor }}</strong>
                    </p>
                    <p>
                        Filias: <strong>{{ resumo.filiais || 0 }} selecionadas</strong>
                    </p>
                </div>
                <v-divider />
                <div class="resumo-item">
                    <span class="titulo">Seleção de Produtos:</span>
                    <p>
                        Fornecedor: <strong>{{ value.fornecedor }}</strong>
                    </p>
                    <p>
                        Produtos:
                        <strong>{{ resumo.produtos || 0 }} selecionados</strong>
                    </p>
                </div>
                <div class="resumo-item resumo-total">
           
                    <p> Total de investimento do acordo: <span style="color: var(--v-primary-base)"> {{resumo.investimento || 'R$ 0,00'}}</span>
                        
                    </p>
                </div>
            </div>
        </div>

        <v-overlay
            :value="semAcesso"
            :absolute="true"
            :opacity="0.1"
            :z-index="3"
        ></v-overlay>
    </v-form>
</template>

<script>
export default {
    props: ["value"],
    inject: ["listaCampos"],

    async created() {
        var lista = await this.listaCampos;
        this.lista.espaco = lista.espaco;
        this.lista.categorias = lista.categorias;
        this.lista.device = lista.device;
    },
    data() {
        return {
            resumo: {},
            acordo: {
                visivel: null,
                planograma: null,
                comentario: null,
            },
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            lista: {
                visivel: ["Sim", "Não"],
            },
            regra: {
                visivel: [
                    (v) => !!v || "O campo Visível para Filial é obrigatório",
                ],
                csv: [
                    (v) => !!v || "Selecione um arquivo.",
                    (v) =>
                        (v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
                        "O arquivo deve ser do tipo CSV.",
                ],
                planograma: [
                    (v) =>
                        !v ||
                        (v &&
                            [
                                ".pdf",
                                ".PDF",
                                ".jpg",
                                ".JPG",
                                ".png",
                                ".PNG",
                                "jpeg",
                                "JPEG",
                            ].includes(v.name.substr(-4))) ||
                        "O arquivo deve ser do tipo PDF, JPG ou PNG.",
                ],
            },
            semAcesso: false,
        };
    },
    computed: {
        espaco() {
            if (this.value.id_espaco && this.lista.espaco) {
                const espaco = this.lista.espaco.find(
                    (e) => e.value === this.value.id_espaco
                );
                if (espaco && !this.value.ponta_tematica) {
                    return espaco.text;
                } else {
                    console.log('aqui->',this.value)
                    return this.value.prateleira_nome
                }
            }
            return "";
        },
    },
    methods: {
        setAcordo(acordo) {
            this.acordo = { ...acordo };
        },
        setResumo(resumo) {
            console.log(resumo);
            this.resumo = resumo;
        },

        setSemAcesso(semAcesso) {
            this.semAcesso = semAcesso;
        },
        validate() {
            return this.$refs.form.validate();
        },
        resetValidation() {
            this.resumo = {};
            try {
                this.$refs.form.resetValidation();
            } catch (error) {
                //console.log(error)
            }
        },
        formatarMes(mes) {
            return this.$moment(mes, "YYYY-MM")
                .format("MMMM [de] YYYY")
                .capitalize();
        },
    },
};
</script>
<style lang="scss">
.resumo {
    .resumo-total {
        grid-column: span 6;
        text-align: center;
        font-size: 1rem;
        margin-top: 1rem;
    }
    .resumo-title {
        font-size: 1.1rem;
        background: var(--v-primary-base);
        text-align: center;
        padding: 10px;
        color: #fff;
        font-weight: 500;
        border-radius: 8px 8px 0 0;
    }
    .resumo-conteudo {
        display: grid;
        grid-template-columns: 1fr 20px 1fr 20px 1fr;
        grid-gap: 15px;
        align-items: center;
        padding: 20px;
        border-radius: 0 0 8px 8px;
        font-weight: 500;
        font-size: 0.8rem !important;
        color: #808080;
        border: 1px solid #ddd;
        p {
            margin: 0;
        }
        .resumo-item {
            width: 100%;
            height: 100%;
            padding: 10px 20px;
            border-radius: 8px;
            background: #eaeaea;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .titulo {
                font-weight: bold;
                color: var(--v-primary-base);
            }
        }
    }
}
</style>