var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"br-15 pa-3 px-5 overflow-hidden",attrs:{"elevation":"0"}},[_c('InfoBox',[_c('span',{staticClass:"titulo"},[_vm._v("Resumo trimestral: ")]),_vm._v(" Nesta tabela é possivel avaliar os valores de investimentos por timestre, nas visões: PDV, Campanha e Ecommerce. ")]),_c('h2',{staticClass:"text-center",staticStyle:{"font-weight":"normal"}},[_vm._v("Resumo Trimestral")]),_c('div',{staticStyle:{"height":"503px"}},[[(!_vm.error && !_vm.empty && !_vm.overlay)?_c('v-simple-table',{staticClass:"mt-3 tabela-resumo-trimestral",attrs:{"dense":"","height":"493px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticClass:"v-data-table-header"},[_c('th',{staticClass:"text-center th-separador"},[_vm._v("Ferramentas")]),_vm._l(([1, 2, 3, 4]),function(i){return _c('th',{key:'th' + i,staticClass:"text-center th-separador"},[_vm._v(" "+_vm._s(i)+"º Trimestre ")])})],2)]),_c('tbody',[_c('tr',[_c('th',{staticClass:"text-center v-data-table-header teal darken-2",staticStyle:{"padding":"5px !important"},attrs:{"colspan":"5"}},[_vm._v(" "+_vm._s(_vm.ano)+" ")])]),_vm._l((_vm.filterTable(_vm.ano)),function(item,index){return _c('tr',{key:'tra' + index,class:{
                'text-center': true,
                'table-footer': item.ferramenta === 'Total',
              }},[_c('td',[_vm._v(_vm._s(item.ferramenta))]),_vm._l(([1, 2, 3, 4]),function(i){return _c('td',{key:'tda' + i,staticClass:"text-center"},[_c('formataReal',{attrs:{"valor":item['tri' + i]}})],1)})],2)}),_c('tr',[_c('th',{staticClass:"text-center v-data-table-header teal darken-2",staticStyle:{"padding":"5px !important"},attrs:{"colspan":"5"}},[_vm._v(" "+_vm._s(_vm.ano - 1)+" ")])]),_vm._l((_vm.filterTable(_vm.ano - 1)),function(item,index){return _c('tr',{key:'trb' + index,class:{
                'text-center': true,
                'table-footer': item.ferramenta === 'Total',
              }},[_c('td',[_vm._v(" "+_vm._s(item.ferramenta)+" ")]),_vm._l(([1, 2, 3, 4]),function(i){return _c('td',{key:'tdb' + i,staticClass:"text-center"},[_c('formataReal',{attrs:{"valor":item['tri' + i]}})],1)})],2)})],2)]},proxy:true}],null,false,2307439540)}):_vm._e()],_c('Overlay',{attrs:{"overlay":_vm.overlay,"error":_vm.error,"empty":_vm.empty}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }