<template>
  <v-form ref="form">
    <v-row class="mt-4">
      <v-col cols="3" class="py-0">
        <MesInput
          label="Mês"
          v-model="value.mes"
          :rules="regra.mes"
          required
        ></MesInput>
      </v-col>
      <v-col cols="3" class="py-0">
        <v-select
          label="Semana"
          outlined
          dense
          class="input-cinza"
          v-model="value.semana"
          :items="listaSemanas"
          :menu-props="menuProps"
          :rules="regra.semana"
          required
        ></v-select>
      </v-col>
      <v-col cols="3" class="py-0">
        <DataInput
          v-model="value.data_inicio"
          type="date"
          label="Data de Início"
          :disabled="!value.semana"
          :rules="regra.data_inicio"
          :min="dataMinima"
          :max="dataMaxima"
          :first-day-of-week="1"
          outlined
          dense
        ></DataInput>
      </v-col>
      <v-col cols="3" class="py-0">
        <DataInput
          v-model="value.data_fim"
          type="date"
          label="Fim"
          outlined
          dense
          :disabled="!value.semana"
          :rules="regra.data_fim"
          :min="dataMinima"
          :max="dataMaxima"
          :first-day-of-week="1"
        ></DataInput>
      </v-col>
      <v-col cols="3" class="py-0">
        <v-autocomplete
          label="Ativação"
          class="input-cinza"
          outlined
          dense
          :items="lista.ativacoes"
          v-model="value.id_ativacao"
          :menu-props="menuProps"
          :rules="regra.ativacao"
          required
        ></v-autocomplete>
      </v-col>

      <v-col cols="3" class="py-0">
        <v-select
          label="Bandeira"
          outlined
          dense
          class="input-cinza"
          v-model="value.id_bandeira"
          :items="lista.bandeira"
          :menu-props="menuProps"
          :rules="regra.id_bandeira"
          required
        ></v-select>
      </v-col>
      <v-col cols="3" class="py-0">
        <v-tooltip top :disabled="(value.id_bandeira > 0)">
          <template v-slot:activator="{ on, attrs }">
            <v-autocomplete
              label="Categoria"
              class="input-cinza"
              outlined
              v-bind="attrs"
              v-on="on"
              :readonly="!value.id_bandeira"

              dense
              :items="listaCategoria"
              v-model="value.id_categoria"
              :menu-props="menuProps"
              :rules="regra.categoria"
              required
            ></v-autocomplete>
          </template>
          <span>Selecione a bandeira primeiro</span>
        </v-tooltip>
      </v-col>
      <v-col cols="3" class="py-0">
        <v-select
          label="Device"
          outlined
          dense
          class="input-cinza"
          v-model="value.device"
          :items="lista.device"
          :menu-props="menuProps"
          :rules="regra.device"
          multiple
          required
        ></v-select>
      </v-col>
      <v-col cols="3" class="py-0">
        <v-select
          label="Tipo/Área"
          outlined
          dense
          class="input-cinza"
          v-model="value.id_area"
          :items="lista.areas"
          :menu-props="menuProps"
          :rules="regra.area"
          required
        ></v-select>
      </v-col>
      <v-col cols="3" class="py-0">
        <v-autocomplete
          label="Fornecedor"
          class="input-cinza"
          outlined
          dense
          :items="lista.fornecedor"
          v-model="value.id_fornecedor"
          :menu-props="menuProps"
          :rules="areaTrade && regra.fornecedor"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="3" class="py-0">
        <v-text-field
          label="Ação"
          class="input-cinza"
          v-model="value.acao"
          required
          :rules="regra.acao"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="3" class="py-0">
        <v-tooltip top :disabled="(value.id_fornecedor > 0)">
          <template v-slot:activator="{ on, attrs }">
            <v-autocomplete
              label="Marca"
              class="input-cinza"
              v-bind="attrs"
              v-on="on"
              outlined
              :readonly="!value.id_fornecedor"
              dense
              :items="listaMarcas"
              :loading="loadingMarcas"
              v-model="value.marca"
              :menu-props="menuProps"
              multiple
            ></v-autocomplete>
          </template>
          <span>Selecione o fornecedor primeiro</span>
        </v-tooltip>
      </v-col>
      <v-col cols="3" class="py-0">
        <v-select
          label="Plano"
          outlined
          dense
          class="input-cinza"
          v-model="value.plano"
          :items="lista.planos"
          :menu-props="menuProps"
          :rules="areaTrade && regra.plano"
          required
        ></v-select>
      </v-col>
      <v-col cols="3" class="py-0">
        <vuetify-money
          label="Investimento"
          v-model="value.investimento"
          :options="options"
          class="input-cinza"
          outlined
          dense
          backgroundColor=""
        ></vuetify-money>
      </v-col>
      <v-col cols="3" class="py-0">
        <v-select
          label="Validação"
          outlined
          dense
          class="input-cinza"
          v-model="value.id_status_planejamento"
          :items="lista.status_planejamento"
          :menu-props="menuProps"
          required
        ></v-select>
      </v-col>
      <v-col cols="3" class="py-0">
        <v-autocomplete
          label="Biblioteca de Termos"
          class="input-cinza"
          outlined
          dense
          :items="lista.termos"
          v-model="value.id_termo"
          :menu-props="menuProps"
          :rules="regra.termo"
          required
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-alert type="error" v-if="disponibilidade.disponivel === false" text>
      {{
        disponibilidade.extra ||
        "Sem disponibilidade para o período ou ativação selecionada."
      }}
    </v-alert>
    <v-alert type="success" v-if="disponibilidade.disponivel === true" text>
      Período com disponibilidade.
    </v-alert>
    <v-alert type="info" v-if="disponibilidade.loading === true" text>
      Consultando disponibilidade...
    </v-alert>
  </v-form>
</template>
<script>
import useFiltros from "@/hooks/useFiltros";
import Data from "@/plugins/datas";
export default {
  setup() {
    const { isLoading, isError, isFetching, data, error, refetch } = useFiltros(
      "/planejamentoEcommerce/listaCampos"
    );
    return {
      isLoading,
      isError,
      isFetching,
      lista: data,
      error,
      refetch,
    };
  },
  props: ["value"],
  data() {
    return {
      loadingMarcas: false,
      listaMarcas: [],
      mesAtual: this.$moment().format("YYYY-MM"),
      disponibilidade: {},

      menuProps: {
        bottom: true,
        offsetY: true,
        contentClass: "select-outline",
        transition: "slide-y-transition",
      },
      regra: {
        mes: [
          (v) => !!v || "O período inicial é obrigatório",
          (v) => this.validarPeriodoInicial(v) || "Período inicial inválido",
        ],
        data_inicio: [
          (v) => !!v || "O campo data de início é obrigatório",
          (v) => this.validarPeriodoInicial(v) || "Data inválida",
        ],
        data_fim: [
          (v) => !!v || "O campo data de fim é obrigatório",
          (v) => this.validarPeriodoFinal(v) || "Data inválida",
        ],
        semana: [(v) => !!v || "O campo semana é obrigatório"],
        ativacao: [(v) => !!v || "O campo ativação é obrigatório"],
        categoria: [(v) => !!v || "O campo categoria é obrigatório"],
        termo: [(v) => !!v || "O campo biblioteca de termos é obrigatório"],
        id_bandeira: [(v) => !!v || "O campo bandeira é obrigatório"],
        acao: [(v) => !!v || "O campo ação é obrigatório"],
        area: [(v) => !!v || "O campo área é obrigatório"],
        // if area == trade fornecedor is not required
        fornecedor: [(v) => !!v || "O campo fornecedor é obrigatório"],
        device: [
          (v) => !!v || "O campo device é obrigatório",
          (v) => v.length > 0 || "O campo device é obrigatório",
        ],
        plano: [(v) => !!v || "O campo plano é obrigatório"],
      },
      options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2,
      },
    };
  },
  computed: {
    listaSemanas() {
      let semanaIndex = 0;
      return this.semanasMes.map((semana) => {
        semanaIndex++;
        const text = `${semanaIndex}ª Semana - ${semana.inicio.format(
          "DD/MM"
        )} a ${semana.fim.format("DD/MM")}`;

        return { value: semanaIndex, text };
      });
    },
    semanasMes() {
      return Data.calculaSemanas(this.value.mes);
    },
    semanaAtiva() {
      if (this.value.semana) {
        return this.semanasMes[this.value.semana - 1];
      }
      return {};
    },
    areaTrade() {
      return this.value.id_area == 7; // Trade
    },
    dataMinima() {
      if (this.semanaAtiva.inicio) {
        return this.semanaAtiva.inicio.format("YYYY-MM-DD");
      }
      return 1;
    },
    dataMaxima() {
      if (this.semanaAtiva.fim) {
        return this.semanaAtiva.fim.format("YYYY-MM-DD");
      }
      return 1;
    },
    listaCategoria() {
      if (!this.value.id_bandeira) return [];
      return this.lista.categorias.filter((categoria) => {
        return categoria.bandeiras.includes(this.value.id_bandeira);
      });
    },
  },
  watch: {
    "value.mes": {
      handler(v) {
        console.log("mes", v);
        if (this.value.id_planejamento) return;
        this.value.semana = null;
        this.checaDisponobilidade();
      },
    },
    "value.semana": {
      handler(v) {
        this.changeSemana(v);
        this.checaDisponobilidade();
      },
    },
    "value.id_bandeira": {
      handler(v) {
        this.checaDisponobilidade();
      },
    },
    "value.id_ativacao": {
      handler(v) {
        this.checaDisponobilidade();
      },
    },
    "value.id_categoria": {
      handler(v) {
        this.checaDisponobilidade();
      },
    },
    "value.id_fornecedor": {
      handler(v) {
        this.getMarcas();
      },
    },
    "value.id_termo": {
      handler(v) {
        this.checaDisponobilidade();
      },
    },
  },
  mounted() {
    this.checaDisponobilidade();
  },
  methods: {
    validarPeriodoInicial(v) {
      if (this.value.data_fim) {
        return this.$moment(this.value.data_inicio).isSameOrBefore(
          this.value.data_fim
        );
      }
      return true;
    },
    validarPeriodoFinal(v) {
      if (this.value.data_inicio) {
        return this.$moment(this.value.data_fim).isSameOrAfter(
          this.value.data_inicio
        );
      }
      return true;
    },
    async getMarcas() {
      if (!this.value.id_fornecedor) return;

      this.loadingMarcas = true;
      const response = await this.$http().get(
        `/planejamentoEcommerce/listaMarcas/${this.value.id_fornecedor}`
      );

      if (
        response.status == 200 &&
        response.data.marcas &&
        response.data.marcas.length > 0
      ) {
        this.listaMarcas = response.data.marcas;
      }
      this.loadingMarcas = false;
    },
    changeSemana() {
      if (this.semanaAtiva.inicio) {
        this.value.data_inicio = this.semanaAtiva.inicio.format("YYYY-MM-DD");
        this.value.data_fim = this.semanaAtiva.fim.format("YYYY-MM-DD");
      } else {
        this.value.data_inicio = null;
        this.value.data_fim = null;
      }
    },
    validate() {
      return this.$refs.form.validate() && this.disponibilidade.disponivel;
    },
    resetValidation() {
      console.log("resetValidation");
      this.disponibilidade = {};
      this.$refs.form.resetValidation();
    },
    async checaDisponobilidade() {
      this.disponibilidade = {};

      // Verifica se todos os campos estão preenchidos
      if (
        !this.value.mes ||
        !this.value.semana ||
        !this.value.id_bandeira ||
        !this.value.id_ativacao ||
        !this.value.id_categoria ||
        !this.value.id_termo 
      ) {
        return;
      }
      this.disponibilidade.loading = true;

      // Monta o objeto para enviar para a API
      const data = {
        mes: this.value.mes,
        semana: this.value.semana,
        id_bandeira: this.value.id_bandeira,
        id_ativacao: this.value.id_ativacao,
        id_categoria: this.value.id_categoria,
        id_planejamento: this.value.id_planejamento,
        id_termo: this.value.id_termo,
      };

      const response = await this.$http().post(
        "/planejamentoEcommerce/disponibilidade",
        { data }
      );
      if (response.status == 200 && response.data.error == false) {
        this.disponibilidade = response.data;
        if (response.data.custo) {
          this.value.investimento = response.data.custo;
        }
      } else {
        this.disponibilidade = {
          loading: false,
          extra: "Falha ao checar disponibilidade",
        };
      }
    },
  },
};
</script>
