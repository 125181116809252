<template>
	<v-container fluid>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="4" lg="4" xl="4">
				<v-toolbar-title class="ml-2 mt-2">Auditorias</v-toolbar-title>
			</v-col>
		</v-row>
		<v-card class="mt-2 pt-4 px-2" elevation="1">
			<filtro ref="filtro" @filtrar="filtrar"></filtro>
		</v-card>
		<v-row no-gutters>
			<v-col
				cols="12"
				sm="12"
				:md="layoutCompacto ? 6 : 12"
				:lg="layoutCompacto ? 6 : 12"
				:xl="layoutCompacto ? 6 : 12"
			>
				<v-card class="mt-4 px-2 pt-2" elevation="1">
					<v-icon
						style="
							position: absolute;
							top: 10px;
							left: 10px;
							z-index: 2;
						"
						left
						:color="layoutCompacto ? 'primary' : ''"
						@click="mudaLayout()"
						title="Layout compacto"
						>mdi-view-compact</v-icon
					>
					<v-icon
						style="
							position: absolute;
							top: 10px;
							left: 50px;
							z-index: 2;
						"
						left
						:color="percentualExecucoes ? 'primary' : ''"
						@click="clickPercentualExecucoes()"
						title="Exibir percentual / quantidade"
						>mdi-label-percent</v-icon
					>
					<highcharts
						:options="graficoExecucoes"
						ref="grafico1"
					></highcharts>
				</v-card>
			</v-col>
			<v-col
				cols="12"
				sm="12"
				:md="layoutCompacto ? 6 : 12"
				:lg="layoutCompacto ? 6 : 12"
				:xl="layoutCompacto ? 6 : 12"
			>
				<v-card
					class="mt-4 px-2 pt-2"
					:class="layoutCompacto ? 'ml-4' : ''"
					elevation="1"
				>
					<v-icon
						style="
							position: absolute;
							top: 10px;
							left: 10px;
							z-index: 2;
						"
						left
						:color="layoutCompacto ? 'primary' : ''"
						@click="mudaLayout()"
						title="Layout compacto"
						>mdi-view-compact</v-icon
					>
					<v-icon
						style="
							position: absolute;
							top: 10px;
							left: 50px;
							z-index: 2;
						"
						left
						:color="percentualJustificativas ? 'primary' : ''"
						@click="clickPercentualJustificativas()"
						title="Exibir percentual / quantidade"
						>mdi-label-percent</v-icon
					>
					<highcharts
						:options="graficoJustificativas"
						ref="grafico2"
					></highcharts>
				</v-card>
			</v-col>
		</v-row>
		<v-card class="my-4 px-2" elevation="1">
			<v-row no-gutters>
				<v-col cols="3">
					<v-switch
						v-model="exibirCodigos"
						label="+ Informações"
					></v-switch>
				</v-col>
				<v-col cols="9" align="end" class="py-2">
					<v-btn
						class="ml-2 my-1"
						color="primary"
						@click="exportarFotos()"
					>
						<v-icon left>mdi-microsoft-powerpoint</v-icon> Exportar
						Fotos
					</v-btn>
					<v-btn
						class="ml-2 my-1"
						color="primary"
						@click="exportar({})"
					>
						<v-icon left>mdi-microsoft-excel</v-icon> Exportar CSV
					</v-btn>
					<v-btn
						class="ml-2 my-1"
						color="primary"
						@click="exportar({ fnHistorico: true })"
					>
						<v-icon left>mdi-microsoft-excel</v-icon> Exportar
						Histórico
					</v-btn>
					<v-btn class="ml-2 my-1" color="primary" @click="exportarConsolidado()">
						<v-icon left="">mdi-microsoft-excel</v-icon> Exportar
						Consolidado
					</v-btn>
				</v-col>
			</v-row>
			<v-data-table
				:headers="headers()"
				:items="dados"
				:server-items-length="total"
				:items-per-page="5"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 25, 50, 100],
				}"
				:options.sync="pagination"
				multi-sort
				class="elevation-0"
			>
				<template v-slot:[`item.fotos`]="{ item }">
					<v-icon
						small
						class="mr-2"
						color="primary"
						@click="abrirFoto(item)"
						title="Foto"
						v-if="item.imagem"
					>
						mdi-tooltip-image
					</v-icon>
				</template>
				<template v-slot:[`item.produtos`]="{ item }">
					<v-icon
						small
						class="mr-2"
						color="primary"
						@click="abrirProdutos(item)"
						title="Produtos"
					>
						mdi-clipboard-list
					</v-icon>
				</template>
			</v-data-table>
		</v-card>
		<v-overlay :value="carregando" :z-index="300">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
		<v-dialog v-model="dialogImagem" width="600">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					{{ dialogTitulo }}
				</v-card-title>
				<v-card-text class="mt-4" v-html="htmlImage"> </v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="dialogImagem = false">
						ok
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogProdutos" width="80%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					{{ dialogTitulo }}
				</v-card-title>
				<v-data-table
					:headers="headersProdutos"
					:items="dadosProdutos"
					:items-per-page="5"
					:footer-props="{
						'items-per-page-options': [5, 10, 15, 25, 50, 100],
					}"
					class="elevation-0 data-table-2"
				></v-data-table>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="dialogProdutos = false">
						ok
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogDetalhado" width="95%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Detalhado
				</v-card-title>
				<v-row no-gutters class="mx-2">
					<v-col cols="6">
						<v-switch
							v-model="exibirCodigosDetalhado"
							label="+ Informações"
						></v-switch>
					</v-col>
					<v-col cols="6" align="end" class="py-2">
						<v-btn
							class="ml-2 my-1"
							color="primary"
							@click="exportar({ fnDetalhado: true })"
						>
							<v-icon left>mdi-microsoft-excel</v-icon> Exportar
							CSV
						</v-btn>
					</v-col>
				</v-row>
				<v-data-table
					:headers="headers(true)"
					:items="dadosDetalhado"
					:server-items-length="totalDetalhado"
					:items-per-page="5"
					:footer-props="{
						'items-per-page-options': [5, 10, 15, 25, 50, 100],
					}"
					:options.sync="paginationDetalhado"
					multi-sort
					class="elevation-0"
				>
					<template v-slot:[`item.fotos`]="{ item }">
						<v-icon
							small
							class="mr-2"
							color="primary"
							@click="abrirFoto(item)"
							title="Foto"
							v-if="item.imagem"
						>
							mdi-tooltip-image
						</v-icon>
					</template>
					<template v-slot:[`item.produtos`]="{ item }">
						<v-icon
							small
							class="mr-2"
							color="primary"
							@click="abrirProdutos(item)"
							title="Produtos"
						>
							mdi-clipboard-list
						</v-icon>
					</template>
				</v-data-table>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="dialogDetalhado = false">
						ok
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogPPT" width="80%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Exportando PPTX
				</v-card-title>
				<v-row no-gutters>
					<v-col cols="12" class="px-8 pt-8 pb-2">
						Serão exportados arquivos com 100 fotos cada.
						<br />
						Total de fotos: {{ fotos }}
						<br />
						Total de arquivos: {{ Math.ceil(fotos / 100) }}
						<br />
						Escrevendo arquivo {{ arquivo }}.
					</v-col>
					<v-col cols="12" class="px-8 pb-8">
						<v-progress-linear v-model="index" height="25">
							<span class="font-weight-bold white--text"
								>{{ Math.ceil(index) }}%</span
							>
						</v-progress-linear>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
		<v-snackbar
			v-model="alerta"
			:color="alertaColor"
			:timeout="alertaTimeout"
			top
			style="z-index: 400"
		>
			<v-alert :type="alertaColor">
				{{ alertaTexto }}
			</v-alert>
			<v-btn dark text class="float-right" @click="alerta = false">
				Fechar
			</v-btn>
		</v-snackbar>
	</v-container>
</template>

<script>
import Filtro from "./Filtro.vue";

export default {
    components: {
        Filtro,
    },
    data() {
        return {
            dados: [],
            pagination: {},
            total: null,
            dadosDetalhado: [],
            paginationDetalhado: {},
            totalDetalhado: null,
            filtroDetalhado: {},
            carregando: false,
            exibirCodigos: false,
            exibirCodigosDetalhado: false,
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
            dialogImagem: false,
            dialogProdutos: false,
            dialogDetalhado: false,
            dialogTitulo: null,
            dialogPPT: false,
            arquivoTexto: "",
            fotos: 0,
            index: 0,
            arquivo: 0,
            htmlImage: null,
            dadosProdutos: [],
            headersProdutos: [
                {
                    text: "Código",
                    value: "codigo",
                    sortable: false,
                    align: "center",
                },
                {
                    text: "Produto",
                    value: "produto",
                    sortable: false,
                    align: "center",
                },
                { text: "VB", value: "vb", sortable: false, align: "center" },
            ],
            execucoes: [],
            layoutCompacto: true,
            percentualExecucoes: true,
            graficoExecucoes: {
                credits: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },
                title: {
                    text: "Execuções (%)",
                },
                chart: {
                    type: "column",
                },
                yAxis: {
                    visible: false,
                },
                plotOptions: {
                    column: {
                        stacking: "normal",
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return this.point.y * 1 == 0
                                    ? ""
                                    : this.point.y + "%";
                            },
                            style: {
                                fontWeight: "bold",
                                color: "#FFF",
                                textOutline: "none",
                            },
                        },
                        states: {
                            inactive: {
                                opacity: 1,
                            },
                        },
                        maxPointWidth: 100,
                    },
                },
                xAxis: {
                    categories: [],
                },
                series: [
                    {
                        name: "Pendente",
                        data: [],
                        stack: "total",
                        color: this.$vuetify.theme.themes.light.warning,
                        dataLabels: {
                            style: {
                                textOutline:
                                    this.$vuetify.theme.themes.light.warning,
                            },
                        },
                        cursor: "pointer",
                        events: {
                            click: ({ point }) => {
                                this.filtroDetalhado = {
                                    execucao: ["null"],
                                    mes:
                                        point.category.split("/")[1] +
                                        "-" +
                                        point.category.split("/")[0] +
                                        "-01",
                                };
                                this.paginationDetalhado.page = 1;
                                this.dialogDetalhado = true;
                                this.detalhado();
                            },
                        },
                    },
                    {
                        name: "Auditado",
                        data: [],
                        stack: "total",
                        color: this.$vuetify.theme.themes.light.info,
                        dataLabels: {
                            style: {
                                textOutline:
                                    this.$vuetify.theme.themes.light.info,
                            },
                        },
                        cursor: "pointer",
                        events: {
                            click: ({ point }) => {
                                this.filtroDetalhado = {
                                    execucao: ["Sim", "Não"],
                                    mes:
                                        point.category.split("/")[1] +
                                        "-" +
                                        point.category.split("/")[0] +
                                        "-01",
                                };
                                this.paginationDetalhado.page = 1;
                                this.dialogDetalhado = true;
                                this.detalhado();
                            },
                        },
                    },
                    {
                        name: "Executado",
                        data: [],
                        stack: "auditado",
                        color: this.$vuetify.theme.themes.light.success,
                        dataLabels: {
                            style: {
                                textOutline:
                                    this.$vuetify.theme.themes.light.success,
                            },
                        },
                        cursor: "pointer",
                        events: {
                            click: ({ point }) => {
                                this.filtroDetalhado = {
                                    execucao: ["Sim"],
                                    mes:
                                        point.category.split("/")[1] +
                                        "-" +
                                        point.category.split("/")[0] +
                                        "-01",
                                };
                                this.paginationDetalhado.page = 1;
                                this.dialogDetalhado = true;
                                this.detalhado();
                            },
                        },
                    },
                    {
                        name: "Não executado",
                        data: [],
                        stack: "auditado",
                        color: this.$vuetify.theme.themes.light.error,
                        dataLabels: {
                            style: {
                                textOutline:
                                    this.$vuetify.theme.themes.light.error,
                            },
                        },
                        cursor: "pointer",
                        events: {
                            click: ({ point }) => {
                                this.filtroDetalhado = {
                                    execucao: ["Não"],
                                    mes:
                                        point.category.split("/")[1] +
                                        "-" +
                                        point.category.split("/")[0] +
                                        "-01",
                                };
                                this.paginationDetalhado.page = 1;
                                this.dialogDetalhado = true;
                                this.detalhado();
                            },
                        },
                    },
                ],
            },
            macro_justificativas: [],
            percentualJustificativas: true,
            graficoJustificativas: {
                credits: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },
                title: {
                    text: "Justificativas (%)",
                },
                chart: {
                    type: "column",
                },
                yAxis: {
                    visible: false,
                },
                legend: {
                    enabled: false,
                },
                plotOptions: {
                    column: {
                        stacking: "normal",
                        dataLabels: {
                            enabled: true,
                            format: "{point.y}%",
                            style: {
                                fontWeight: "bold",
                                color: "#FFF",
                                textOutline:
                                    this.$vuetify.theme.themes.light.primary
                                        .base,
                            },
                        },
                        maxPointWidth: 100,
                    },
                },
                xAxis: {
                    categories: [],
                },
                series: [
                    {
                        data: [],
                        color: this.$vuetify.theme.themes.light.primary.base,
                        cursor: "pointer",
                        events: {
                            click: ({ point }) => {
                                this.filtroDetalhado = {
                                    justificativa: [point.category],
                                };
                                this.paginationDetalhado.page = 1;
                                this.dialogDetalhado = true;
                                this.detalhado();
                            },
                        },
                    },
                ],
            },
            primeiraBusca: true,
        };
    },
    provide() {
        return {
            listaCampos: this.listaCampos,
        };
    },
    watch: {
        pagination: {
            handler() {
                if (this.primeiraBusca) {
                    this.$refs.filtro.iniciaFiltro();

                    /* setTimeout(() => {
						this.filtrar();
					}, 500); */

                    this.primeiraBusca = false;
                } else {
                    this.filtrar();
                }
            },
            deep: true,
        },
        paginationDetalhado: {
            handler() {
                this.detalhado();
            },
            deep: true,
        },
    },
    computed: {},
    methods: {
        mudaLayout() {
            this.layoutCompacto = !this.layoutCompacto;

            setTimeout(() => {
                this.$refs.grafico1.chart.reflow();
                this.$refs.grafico2.chart.reflow();
            }, 100);
        },
        headers(fnDetalhado) {
            var codigos = [
                "contrato",
                "acordo_rd",
                "codigo_bandeira",
                "codigo_fornecedor",
                "codigo_espaco",
                "codigo_cluster",
                "nome",
                "ano",
                "mes",
                "observacao",
                "cnpj",
            ];

            var headers = [
                { text: "Cont.", value: "id_contrato", align: "center" },
                { text: "Cont. RD", value: "contrato", align: "center" },
                { text: "Acor.", value: "id_acordo", align: "center" },
                { text: "Acor. RD", value: "acordo_rd", align: "center" },
                {
                    text: "Cód. Band.",
                    value: "codigo_bandeira",
                    align: "center",
                },
                { text: "Band.", value: "bandeira", align: "center" },
                { text: "Ano", value: "ano", align: "center" },
                { text: "Mês", value: "mes", align: "center" },
                { text: "Mês", value: "ano_mes", align: "center" },
                {
                    text: "Cód. Forn.",
                    value: "codigo_fornecedor",
                    align: "center",
                },
                { text: "Forn.", value: "fornecedor", align: "center" },
                { text: "Cód. Espa.", value: "codigo_espaco", align: "center" },
                { text: "Espa.", value: "espaco", align: "center" },
                {
                    text: "Espaço macro",
                    value: "espaco_macro",
                    align: "center",
                },
                { text: "Cód Clus.", value: "codigo_cluster", align: "center" },
                { text: "Clus.", value: "cluster", align: "center" },
                { text: "ID Java", value: "id_java", align: "center" },
                { text: "Versão App", value: "versao_app", align: "center" },
                { text: "CNPJ", value: "cnpj", align: "center" },
                { text: "Nome", value: "nome", align: "center" },
                { text: "Audi.", value: "auditoria", align: "center" },
                { text: "Data", value: "data_execucao", align: "center" },
                { text: "Just.", value: "justificativa", align: "center" },
                {
                    text: "Macr.Just.",
                    value: "macro_justificativa",
                    align: "center",
                },
                {
                    text: "Fotos",
                    value: "fotos",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Prod.",
                    value: "produtos",
                    align: "center",
                    sortable: false,
                },
                { text: "Obs.", value: "observacao", align: "center" },
            ];

            var retorno = [];

            let exibirCodigos = !fnDetalhado
                ? this.exibirCodigos
                : this.exibirCodigosDetalhado;

            for (const h of headers) {
                if (exibirCodigos) {
                    if (h.value == "ano_mes") continue;

                    retorno.push(h);
                } else {
                    if (!codigos.includes(h.value)) {
                        retorno.push(h);
                    }
                }
            }

            return retorno;
        },

        async filtrar(fnGrafico = true) {
            if (!this.$refs.filtro.validate()) return;

            this.carregando = true;

            if (this.$refs.filtro.filtro.mesInicial == undefined) {
                this.$refs.filtro.iniciaFiltro();
            }

            var filtro = { ...this.$refs.filtro.filtro };

            var data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesFinal;
            data.id_filial = filtro.id_filial;
            data.bandeira = filtro.bandeira;
            data.id_fornecedor = filtro.id_fornecedor;
            data.id_espaco = filtro.id_espaco;
            data.espaco_macro = filtro.espaco_macro;
            data.id_cluster = filtro.id_cluster;
            data.regiao = filtro.regiao;
            data.perfil = filtro.perfil;
            data.tamanho = filtro.tamanho;

            data.execucao = [];

            for (const e of filtro.execucao) {
                data.execucao = [...data.execucao, ...e];
            }

            data.pagination = this.pagination;

            data.fnGrafico = fnGrafico || this.total === null ? true : false;

            var response = await this.$http().post("/gerencial/auditoria", {
                data: data,
            });

            this.dados = response.data.lista;

            this.total = response.data.lista[0]
                ? response.data.lista[0].total
                : 0;

            if (this.total == 0 && this.pagination.page > 1) {
                this.pagination.page = 1;
                this.filtrar(false);
            }

            if (data.fnGrafico) {
                this.execucoes = response.data.execucoes;

                this.graficoExecucoes.xAxis.categories = [];
                this.graficoExecucoes.series[0].data = [];
                this.graficoExecucoes.series[1].data = [];
                this.graficoExecucoes.series[2].data = [];
                this.graficoExecucoes.series[3].data = [];

                for (const e of this.execucoes) {
                    this.graficoExecucoes.xAxis.categories.push(e.mes);

                    if (this.percentualExecucoes) {
                        this.graficoExecucoes.series[0].data.push(
                            Math.round((e.pendentes * 100) / e.total)
                        );
                        this.graficoExecucoes.series[1].data.push(
                            Math.round((e.auditadas * 100) / e.total)
                        );
                        this.graficoExecucoes.series[2].data.push(
                            Math.round((e.executadas * 100) / e.total)
                        );
                        this.graficoExecucoes.series[3].data.push(
                            Math.round((e.nao_executadas * 100) / e.total)
                        );
                    } else {
                        this.graficoExecucoes.series[0].data.push(e.pendentes);
                        this.graficoExecucoes.series[1].data.push(e.auditadas);
                        this.graficoExecucoes.series[2].data.push(e.executadas);
                        this.graficoExecucoes.series[3].data.push(
                            e.nao_executadas
                        );
                    }
                }

                let total = response.data.macro_justificativas.length
                    ? response.data.macro_justificativas
                          .map((e) => e.total)
                          .reduce((a, c) => a + c)
                    : 0;

                const limiteCorte = total * 0.01;

                this.macro_justificativas = response.data.macro_justificativas
                    .length
                    ? response.data.macro_justificativas.filter(
                          (e) => e.total > limiteCorte
                      )
                    : [];

                this.graficoJustificativas.xAxis.categories = [];
                this.graficoJustificativas.series[0].data = [];

                for (const j of this.macro_justificativas) {
                    this.graficoJustificativas.xAxis.categories.push(
                        j.macro_justificativa
                    );

                    if (this.percentualJustificativas) {
                        this.graficoJustificativas.series[0].data.push(
                            Math.round((j.total * 100) / total)
                        );
                    } else {
                        this.graficoJustificativas.series[0].data.push(j.total);
                    }
                }
            }

            this.carregando = false;
        },
        async exportar({ fnDetalhado = false, fnHistorico = false }) {
            if (!this.$refs.filtro.validate()) return;

            this.carregando = true;

            var filtro = this.$refs.filtro.filtro;

            var data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesFinal;
            data.id_filial = filtro.id_filial;
            data.bandeira = filtro.bandeira;
            data.id_fornecedor = filtro.id_fornecedor;
            data.id_espaco = filtro.id_espaco;
            data.espaco_macro = filtro.espaco_macro;
            data.id_cluster = filtro.id_cluster;
            data.regiao = filtro.regiao;
            data.perfil = filtro.perfil;
            data.tamanho = filtro.tamanho;
            data.fnHistorico = fnHistorico;

            data.execucao = [];

            for (const e of filtro.execucao) {
                data.execucao = [...data.execucao, ...e];
            }

            if (fnDetalhado) {
                data.execucao = this.filtroDetalhado.execucao
                    ? [...data.execucao, ...this.filtroDetalhado.execucao]
                    : [...data.execucao];

                data.justificativa = this.filtroDetalhado.justificativa
                    ? this.filtroDetalhado.justificativa
                    : [];
            }

            var response = await this.$http().post(
                "/gerencial/exportarAuditoria",
                { data: data }
            );

            window.location = this.$http("baseURL") + response.data.url;

            this.carregando = false;
        },
        async exportarConsolidado() {
            if (!this.$refs.filtro.validate()) return;

            this.carregando = true;

            var filtro = this.$refs.filtro.filtro;

            var data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesFinal;
            data.id_filial = filtro.id_filial;
            data.bandeira = filtro.bandeira;
            data.id_fornecedor = filtro.id_fornecedor;
            data.id_espaco = filtro.id_espaco;
            data.espaco_macro = filtro.espaco_macro;
            data.id_cluster = filtro.id_cluster;
            data.regiao = filtro.regiao;
            data.perfil = filtro.perfil;
            data.tamanho = filtro.tamanho;

            var response = await this.$http().post(
                "/gerencial/exportarAuditoriaConsolidado",
                { data: data }
            );

            window.location = this.$http("baseURL") + response.data.dados.url;

            this.carregando = false;
        },

        async exportarFotos() {
            if (!this.$refs.filtro.validate()) return;

            this.arquivoTexto = "";
            this.fotos = 0;
            this.index = 0;
            this.arquivo = 1;

            this.carregando = true;
            this.dialogPPT = true;

            var filtro = this.$refs.filtro.filtro;

            var data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesFinal;
            data.id_filial = filtro.id_filial;
            data.bandeira = filtro.bandeira;
            data.id_fornecedor = filtro.id_fornecedor;
            data.id_espaco = filtro.id_espaco;
            data.espaco_macro = filtro.espaco_macro;
            data.id_cluster = filtro.id_cluster;
            data.regiao = filtro.regiao;
            data.perfil = filtro.perfil;
            data.tamanho = filtro.tamanho;

            data.execucao = ["Sim"];
            data.imagem = ["is not null"];

            var response = await this.$http().post(
                "/gerencial/exportarAuditoriaFotos",
                { data: data }
            );

            var lista = response.data.lista;

            this.fotos = lista.length;

            lista.map(item => {
                if(item.imagem) {
                     item.imagem = this.$arquivoProtegido(item.imagem);
                }
            })
            var pptx = [];


            var hora = this.$moment().format("DD-MM-YYYY_hh-mm-ss");

            for (let i = 1; i <= Math.ceil(lista.length / 100); i++) {
                pptx[i] = {};

                pptx[i].nome = `Fotos_Auditorias_${hora}_arquivo_${i}.pptx`;

                pptx[i].pptx = new this.$pptxgen();

                pptx[i].pptx.author = "IPDV";
                pptx[i].pptx.company = "Raia Drogasil";
                pptx[i].pptx.subject = "Auditorias";
                pptx[i].pptx.title = "Fotos Auditorias";
            }

            const proximaFoto = (i) => {
                let e = lista[i];

                let img = new Image();

                img.addEventListener("load", () => {
                    this.index = Math.ceil(100 * (i / lista.length));

                    //slida comprimento e altura = 10 / 5,62519685
                    let slide = pptx[this.arquivo].pptx.addSlide();

                    slide.addText("", {
                        x: 1.3,
                        y: 0,
                        w: 8.7,
                        h: 0.7,
                        fill: "#00774D",
                    });

                    let texto = "";
                    texto += e.fornecedor;
                    texto += "\n";
                    texto += e.id_java + " - " + e.nome;
                    texto += "\n";
                    texto += e.espaco;

                    slide.addText(texto, {
                        x: 1.5,
                        y: 0,
                        w: 5,
                        h: 0.7,
                        color: "#ffffff",
                        fontSize: 9,
                        fill: "#00774D",
                        bold: true,
                    });

                    slide.addImage({
                        path: require("@/assets/images/login/logo_branco.png"),
                        x: 9,
                        y: 0,
                        w: 0.7,
                        h: 0.7,
                    });

                    slide.addImage({
                        path: require("@/assets/images/login/logo_trade_quadrado.png"),
                        x: 0.3,
                        y: 0,
                        w: 0.7,
                        h: 0.7,
                    });

                    let rows = [
                        [
                            {
                                text: "Código",
                                options: {
                                    fill: "00774D",
                                    color: "ffffff",
                                    bold: true,
                                },
                            },
                            {
                                text: "Produto",
                                options: {
                                    fill: "00774D",
                                    color: "ffffff",
                                    bold: true,
                                },
                            },
                            {
                                text: "VB",
                                options: {
                                    fill: "00774D",
                                    color: "ffffff",
                                    bold: true,
                                },
                            },
                        ],
                    ];

                    for (let p = 0; p < e.produtos.length; p++) {
                        let linha = [
                            {
                                text: e.produtos[p].codigo,
                                options: { fill: p % 2 ? "7cbba5" : "bcd7cd" },
                            },
                            {
                                text: e.produtos[p].produto,
                                options: { fill: p % 2 ? "7cbba5" : "bcd7cd" },
                            },
                            {
                                text: e.produtos[p].vb,
                                options: { fill: p % 2 ? "7cbba5" : "bcd7cd" },
                            },
                        ];

                        rows.push(linha);
                    }

                    slide.addTable(rows, {
                        x: 4.9,
                        y: 0.9,
                        w: 4.9,
                        h: 4.5,
                        rowH: 0.2,
                        colW: [0.8, 3.5, 0.6],
                        color: "#000000",
                        fontSize: 9,
                        fill: "#cccccc",
                        align: "center",
                        border: {
                            pt: 1,
                            color: "#ffffff",
                            type: "solid",
                        },
                        autoPage: true,
                        autoPageRepeatHeader: true,
                    });

                    let altura = 4.5;
                    let comprimento = 4.5;

                    if (img.naturalWidth > img.naturalHeight) {
                        //foto na horizontal

                        altura =
                            Math.round(
                                (100 * comprimento * img.naturalHeight) /
                                    img.naturalWidth
                            ) / 100;
                    } else {
                        //foto na vertical

                        comprimento =
                            Math.round(
                                (100 * altura * img.naturalWidth) /
                                    img.naturalHeight
                            ) / 100;
                    }

                    slide.addImage({
                        path: e.imagem,
                        x: 0.2,
                        y: 0.9,
                        w: comprimento,
                        h: altura,
                    });

                    if (i + 1 == lista.length || (i + 1) % 100 == 0) {
                        pptx[this.arquivo].pptx
                            .writeFile({ fileName: pptx[this.arquivo].nome })
                            .then((data) => {
                                console.log(data);

                                pptx[this.arquivo] = null;

                                this.arquivo++;

                                if (i + 1 == lista.length) {
                                    this.carregando = false;
                                    this.dialogPPT = false;
                                } else {
                                    proximaFoto(i + 1);
                                }
                            })
                            .catch((err) => {
                                console.error(err);

                                this.carregando = false;
                                this.dialogPPT = false;
                            });
                    } else {
                        proximaFoto(i + 1);
                    }
                });

                img.addEventListener("error", () => {
                    if (i + 1 == lista.length || (i + 1) % 100 == 0) {
                        pptx[this.arquivo].pptx
                            .writeFile({ fileName: pptx[this.arquivo].nome })
                            .then((data) => {
                                console.log(data);

                                pptx[this.arquivo] = null;

                                this.arquivo++;

                                if (i + 1 == lista.length) {
                                    this.carregando = false;
                                    this.dialogPPT = false;
                                } else {
                                    proximaFoto(i + 1);
                                }
                            })
                            .catch((err) => {
                                console.error(err);

                                this.carregando = false;
                                this.dialogPPT = false;
                            });
                    } else {
                        proximaFoto(i + 1);
                    }
                });

                img.crossOrigin = "anonymous";
                img.src = e.imagem;
            };

            if (lista.length) {
                proximaFoto(0);
            } else {
                this.carregando = false;
                this.dialogPPT = false;

                this.alerta = true;
                this.alertaColor = "info";
                this.alertaTimeout = 3000;
                this.alertaTexto = "Nenhuma foto encontrada.";
            }
        },
        abrirFoto(item) {
            this.dialogImagem = true;

            this.dialogTitulo = "Foto Auditoria";

            if (item.imagem.substr(-3) == "pdf") {
                this.htmlImage = `<embed src="${
                   this.$arquivoProtegido( item.imagem)
                }" type="application/pdf" width="100%" height="450px">`;
            } else {
                this.htmlImage = `<img src="${
                   this.$arquivoProtegido( item.imagem)
                }" width="100%"></img>`;
            }
        },
        abrirProdutos(item) {
            this.dadosProdutos = item.produtos;

            this.dialogTitulo = "Produtos (Acordo: " + item.id_acordo + ")";

            this.dialogProdutos = true;
        },
        clickPercentualExecucoes() {
            this.percentualExecucoes = !this.percentualExecucoes;

            this.graficoExecucoes.title.text = this.percentualExecucoes
                ? "Execuções (%)"
                : "Execuções";

            if (this.percentualExecucoes) {
                this.graficoExecucoes.plotOptions.column.dataLabels.formatter =
                    function () {
                        return this.point.y * 1 == 0 ? "" : this.point.y + "%";
                    };
            } else {
                this.graficoExecucoes.plotOptions.column.dataLabels.formatter =
                    function () {
                        return this.point.y * 1 == 0 ? "" : this.point.y;
                    };
            }

            this.graficoExecucoes.series[0].data = [];
            this.graficoExecucoes.series[1].data = [];
            this.graficoExecucoes.series[2].data = [];
            this.graficoExecucoes.series[3].data = [];

            for (const e of this.execucoes) {
                if (this.percentualExecucoes) {
                    this.graficoExecucoes.series[0].data.push(
                        Math.round((e.pendentes * 100) / e.total)
                    );
                    this.graficoExecucoes.series[1].data.push(
                        Math.round((e.auditadas * 100) / e.total)
                    );
                    this.graficoExecucoes.series[2].data.push(
                        Math.round((e.executadas * 100) / e.total)
                    );
                    this.graficoExecucoes.series[3].data.push(
                        Math.round((e.nao_executadas * 100) / e.total)
                    );
                } else {
                    this.graficoExecucoes.series[0].data.push(e.pendentes);
                    this.graficoExecucoes.series[1].data.push(e.auditadas);
                    this.graficoExecucoes.series[2].data.push(e.executadas);
                    this.graficoExecucoes.series[3].data.push(e.nao_executadas);
                }
            }
        },
        clickPercentualJustificativas() {
            this.percentualJustificativas = !this.percentualJustificativas;

            this.graficoJustificativas.title.text = this
                .percentualJustificativas
                ? "Justificativas (%)"
                : "Justificativas";
            this.graficoJustificativas.plotOptions.column.dataLabels.format =
                this.percentualJustificativas ? "{point.y}%" : "{point.y}";

            let total = this.macro_justificativas.length
                ? this.macro_justificativas
                      .map((e) => e.total)
                      .reduce((a, c) => a + c)
                : 0;

            this.graficoJustificativas.series[0].data = [];

            for (const j of this.macro_justificativas) {
                if (this.percentualJustificativas) {
                    this.graficoJustificativas.series[0].data.push(
                        Math.round((j.total * 100) / total)
                    );
                } else {
                    this.graficoJustificativas.series[0].data.push(j.total);
                }
            }
        },
        async detalhado() {
            if (!this.$refs.filtro.validate()) return;

            if (this.paginationDetalhado.itemsPerPage == undefined) {
                return;
            }

            if (this.carregando) {
                return;
            }

            this.carregando = true;

            var filtro = { ...this.$refs.filtro.filtro };

            var data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesFinal;
            data.id_filial = filtro.id_filial;
            data.bandeira = filtro.bandeira;
            data.id_fornecedor = filtro.id_fornecedor;
            data.id_espaco = filtro.id_espaco;
            data.espaco_macro = filtro.espaco_macro;
            data.id_cluster = filtro.id_cluster;
            data.regiao = filtro.regiao;
            data.perfil = filtro.perfil;
            data.tamanho = filtro.tamanho;

            data.execucao = [];

            for (const e of filtro.execucao) {
                data.execucao = [...data.execucao, ...e];
            }

            data.execucao = this.filtroDetalhado.execucao
                ? [...data.execucao, ...this.filtroDetalhado.execucao]
                : [...data.execucao];

            data.mes = this.filtroDetalhado.mes
                ? [this.filtroDetalhado.mes]
                : [];

            data.justificativa = this.filtroDetalhado.justificativa
                ? this.filtroDetalhado.justificativa
                : [];

            data.pagination = this.paginationDetalhado;

            var response = await this.$http().post(
                "/gerencial/auditoriaDetalhado",
                { data: data }
            );

            this.dadosDetalhado = response.data.lista;

            this.totalDetalhado = response.data.lista[0]
                ? response.data.lista[0].total
                : 0;

            if (this.totalDetalhado == 0 && this.paginationDetalhado.page > 1) {
                this.paginationDetalhado.page = 1;
                this.detalhado();
            }

            this.carregando = false;
        },
    },
};
</script>

<style></style>
