<template>
  <v-form
    v-on:submit.prevent="filtrar"
    ref="formFiltro"
    class="filtros-acordos"
  >
    <v-divider class="mt-2 mb-3"></v-divider>
    <v-row>
      <v-col sm="6" md="4" lg="3" class="pb-0">
        <DataInput
          label="Período Inicial"
          v-model="filtro.periodoInicial"
          :rules="regra.periodoInicial"
          :change="periodoInicialChange"
          required
        ></DataInput>
      </v-col>
      <v-col sm="6" md="4" lg="3" class="pb-0">
        <DataInput
          label="Período Final"
          v-model="filtro.periodoFinal"
          :rules="regra.periodoFinal"
          :change="periodoFinalChange"
          required
        ></DataInput>
      </v-col>
      <v-col sm="6" md="4" lg="3" class="pb-0">
        <v-select
          label="Semana"
          outlined
          dense
          class="input-cinza"
          v-model="filtro.semana"
          :items="listaSemanas"
          :menu-props="menuProps"
          multiple
        ></v-select>
      </v-col>

      <v-col sm="6" md="4" lg="3" class="pb-0">
        <v-select
          label="Bandeira"
          v-model="filtro.id_bandeira"
          :items="lista.bandeira"
          :menu-props="menuProps"
          outlined
          dense
          class="input-cinza"
          multiple
        >
        </v-select>
      </v-col>
    </v-row>
    <v-expansion-panels flat v-model="mostrarFiltros" accordion>
      <v-expansion-panel>
        <v-expansion-panel-content eager class="p-0 m-0">
          <v-row>
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-autocomplete
                label="Ativação"
                class="input-cinza"
                outlined
                dense
                :items="lista.ativacoes"
                v-model="filtro.id_ativacao"
                :menu-props="menuProps"
                multiple
              ></v-autocomplete>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-select
                label="Device"
                outlined
                dense
                class="input-cinza"
                v-model="filtro.device"
                :items="lista.device"
                :menu-props="menuProps"
                multiple
              ></v-select>
            </v-col>

            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-select
                label="Tipo/Área"
                outlined
                dense
                class="input-cinza"
                v-model="filtro.id_area"
                :items="lista.areas"
                :menu-props="menuProps"
                multiple
              ></v-select>
            </v-col>

            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-autocomplete
                label="Fornecedor"
                v-model="filtro.id_fornecedor"
                :items="lista.fornecedor"
                multiple
                :menu-props="menuProps"
                outlined
                dense
                class="input-cinza"
              >
                <template v-slot:selection="{ item, index }">
                  <div
                    v-if="filtro.id_fornecedor.length === 1 && index === 0"
                    class="autocomplete-selecao"
                  >
                    {{ item.text }}
                  </div>
                  <span
                    v-if="filtro.id_fornecedor.length > 1 && index === 0"
                    class="grey--text caption mr-1"
                    >{{ filtro.id_fornecedor.length }} selecionados</span
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-autocomplete
                label="Categoria"
                class="input-cinza"
                outlined
                dense
                :items="lista.categorias"
                v-model="filtro.id_categoria"
                :menu-props="menuProps"
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <div
                    v-if="filtro.id_categoria.length === 1 && index === 0"
                    class="autocomplete-selecao"
                  >
                    {{ item.text }}
                  </div>
                  <span
                    v-if="filtro.id_categoria.length > 1 && index === 0"
                    class="grey--text caption mr-1"
                    >{{ filtro.id_categoria.length }} selecionados</span
                  >
                </template>
              </v-autocomplete>
            </v-col>
    
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-text-field
                label="Número planejamento"
                outlined
                dense
                class="input-cinza"
                v-model="filtro.id_planejamento"
              ></v-text-field>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-text-field
                label="Ação"
                outlined
                dense
                class="input-cinza"
                v-model="filtro.acao"
              ></v-text-field>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
              <v-select
                label="Validação"
                outlined
                dense
                class="input-cinza"
                v-model="filtro.id_status_planejamento"
                :items="lista.status_planejamento"
                :menu-props="menuProps"
                multiple
              ></v-select>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider class="mt-0 mb-3"></v-divider>
    <v-row>
      <v-col cols="3" class="px-2 ml-auto">
        <div class="new-button d-flex align-center" text>
          <span @click="expandirFiltros" style="cursor: pointer"
            >Personalizar Filtros</span
          >
          <v-btn
            style="background: #e6e6e6; width: 20px; height: 20px"
            fab
            icon
            class="ml-3"
            @click="expandirFiltros"
            x-small
          >
            <v-icon>{{
              mostrarFiltros == 0 ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="9" class="ml-auto" align="end">
        <v-btn class="ml-2 new-button" color="yellow" dark @click="limpar()">
          <v-icon left>mdi-filter-off</v-icon> Limpar Filtros
        </v-btn>
        <v-btn class="ml-5 new-button" color="primary" type="submit">
          <v-icon left>mdi-filter</v-icon> Filtrar
        </v-btn>
      </v-col>
    </v-row>
    <v-overlay :value="carregando">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-form>
</template>

<script>
import useFiltros from "@/hooks/useFiltros";
import Data from "@/plugins/datas";

export default {
  setup() {
    const { isLoading, isError, isFetching, lista, error, refetch } =
      useFiltros("/planejamentoEcommerce/listaCampos");

    return { isLoading, isError, isFetching, lista, error, refetch };
  },
  // inject: ["listaCampos"],
  data() {
    return {
      carregando: false,
      mostrarFiltros: null,
      listaSemanas: [
        { value: 1, text: "Semana 1" },
        { value: 2, text: "Semana 2" },
        { value: 3, text: "Semana 3" },
        { value: 4, text: "Semana 4" },
        { value: 5, text: "Semana 5" },
      ],
      menuProps: {
        bottom: true,
        offsetY: true,
        contentClass: "select-outline",
        transition: "slide-y-transition",
      },
      filtro: {
        periodoInicial: this.$moment().startOf("month"),
        periodoFinal: this.$moment().endOf("month"),
      },
      regra: {
        periodoInicial: [(v) => !!v || "O campo período inicial é obrigatório"],
        periodoFinal: [
          (v) => !!v || "O campo período final é obrigatório",
          (v) =>
            (!!v && this.filtro.periodoFinal >= this.filtro.periodoInicial) ||
            "O período final deve ser igual ou posterior ao período inicial",
        ],
      },
    };
  },
  async created() {
    await new Promise((resolve) => setTimeout(resolve, 100));
    this.iniciaFiltro();
    // this.carregando = false;
  },
  methods: {
    iniciaFiltro() {
      const { periodoInicial, periodoFinal } = this.periodoMes();
      console.log(periodoInicial, periodoFinal);
      this.filtro = {
        periodoInicial: periodoInicial.format("YYYY-MM-DD"),
        periodoFinal: periodoFinal.format("YYYY-MM-DD"),
        tipoPeriodo: -1,
        id_bandeira: [],
        id_planejamento: null,
        semana: [],
        id_ativacao: [],
        device: [],
        id_area: [],
        id_fornecedor: [],
        id_categoria: [],
        plano: [],
        id_status_planejamento: [],
      };
    },
    limpar() {
      this.iniciaFiltro();

      this.$emit("limpar");
    },
    filtrar() {
      this.$emit("filtrar");
    },
    validate() {
      return this.$refs.formFiltro.validate();
    },
    expandirFiltros() {
      this.mostrarFiltros = this.mostrarFiltros == 0 ? null : 0;
    },
    periodoInicialChange(valor) {
      if (this.filtro.periodoFinal < valor) {
        this.filtro.periodoFinal = valor;
      }
    },
    periodoFinalChange(valor) {
      if (this.filtro.periodoInicial > valor) {
        this.filtro.periodoInicial = valor;
      }
    },
    periodoMes() {
      const semanas = Data.calculaSemanas(this.$moment());
      const periodoInicial = semanas[0].inicio;
      const periodoFinal = semanas[semanas.length - 1].fim;
      return { periodoInicial, periodoFinal };
    },
    resetValidation() {
      try {
        this.$refs.formFiltro.resetValidation();
      } catch (error) {
        //console.log(error)
      }
    },
  },
};
</script>
