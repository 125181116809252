<template>
	<v-form ref="formFiltro" class="filtros-oportunidade">
		<v-card class="px-5 py-3 br-15 w-100" elevation="0">
			<v-row>
				<v-col class="d-flex" cols="12" sm="12" md="3" lg="3">
					<v-toolbar-title class="page-title"
						>Oportunidades</v-toolbar-title
					>
					<div class="ma-1">
						<InfoBox>
							<p>
								<span
									style="color: var(--v-primary-base); font-weight: 500"
									>Filtros:</span
								>
								A filtragem padrão para a visualização das
								oportunidades é baseada na seleção do "Mês/Ano"
								e na opção de "Visualização Padrão" que se
								consiste na seleção automatica do campo
								<span style="font-weight: 500"> Cluster. </span>
							</p>
							<p>
								Caso a filtragem precise ser mais específica, o
								campo "Visualização Padrão" pode ser editado
								para incluir mais ou menos opções de
								visualização. Para personalizar ainda mais sua
								pesquisa, selecione a opção "Personalizar
								Filtros" e visualize todas abas disponíveis.
							</p></InfoBox
						>
					</div>
				</v-col>
				<v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pb-0">
					<MesInput
						v-model="mesAno"
						:rules="regra.mesAno"
						:min="'2021-06'"
						label="Mês/Ano"
						:change="mesAnoChange"
					/>
				</v-col>
				<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="pb-0">
					<v-autocomplete
						label="Espaço"
						v-model="filtro.id_espaco"
						:items="lista.espaco"
						multiple
						outlined
						class="input-cinza select"
						:menu-props="menuProps"
						dense
					>
						<template v-slot:selection="{ item, index }">
							<div
								v-if="
									filtro.id_espaco.length === 1 && index === 0
								"
								class="autocomplete-selecao"
							>
								{{ item.text }}
							</div>
							<span
								v-if="
									filtro.id_espaco.length > 1 && index === 0
								"
								class="grey--text caption mr-1"
								>{{
									filtro.id_espaco.length
								}}
								selecionados</span
							>
						</template>
					</v-autocomplete>
				</v-col>
				<v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pb-0">
					<v-select
						label="Colunas Agrupadas"
						:items="listaCampos.campo"
						v-model="filtro.campo"
						class="input-cinza select"
						:menu-props="{
							bottom: true,
							offsetY: true,
							contentClass: 'select-outline',
						}"
						multiple
						outlined
						dense
					>
						<template v-slot:selection="{ item, index }">
							<span
								v-if="
									filtro.campo.filter(c => c !== 'cnpj').length === 1 &&
										item.text == 'Cluster'
								"
								>Visualização padrão</span
							>
							<span
								v-else-if="
									filtro.campo.filter(c => c !== 'cnpj').length === 1 && index === 0
								"
								>{{ item.text }}</span
							>
							<span
								v-if="filtro.campo.filter(c => c !== 'cnpj').length > 1 && index === 0"
								class="grey--text caption mr-1"
								>{{ filtro.campo.filter(c => c !== 'cnpj').length }} selecionados</span
							>
						</template>
					</v-select>
				</v-col>
			</v-row>
			<v-divider class="mt-n1 mb-2"></v-divider>

			<v-expansion-panels flat v-model="mostrarFiltros" accordion>
				<v-expansion-panel>
					<v-expansion-panel-content class="p-0 m-0">
						<v-row class="my-2">
							<v-col
								cols="12"
								sm="6"
								md="4"
								lg="3"
								xl="3"
								class="pb-0"
							>
								<MesInput
									v-model="filtro.mesInicial"
									:rules="regra.mesInicial"
									:min="'2021-06'"
									label="Período Inicial"
									required
									:change="periodoChange"
								/>
							</v-col>
							<v-col
								cols="12"
								sm="6"
								md="4"
								lg="3"
								xl="3"
								class="pb-0"
							>
								<MesInput
									label="Período Final"
									v-model="filtro.mesFinal"
									:rules="regra.mesFinal"
									:change="periodoChange"
									:min="'2021-06'"
									required
								></MesInput>
							</v-col>
							<v-col
								cols="12"
								sm="6"
								md="4"
								lg="3"
								xl="3"
								class="pb-0"
							>
								<v-autocomplete
									label="Filial"
									v-model="filtro.id_filial"
									:items="lista.filial"
									multiple
									outlined
									class="input-cinza select"
									:menu-props="menuProps"
									dense
								>
									<template
										v-slot:selection="{ item, index }"
									>
										<div
											v-if="
												filtro.id_filial.length === 1 &&
													index === 0
											"
											class="autocomplete-selecao"
										>
											{{ item.text }}
										</div>
										<span
											v-if="
												filtro.id_filial.length > 1 &&
													index === 0
											"
											class="grey--text caption mr-1"
											>{{
												filtro.id_filial.length
											}}
											selecionados</span
										>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								sm="6"
								md="4"
								lg="3"
								xl="3"
								class="pb-0"
							>
								<v-select
									label="Bandeira"
									v-model="filtro.bandeira"
									:items="lista.bandeira"
									multiple
									outlined
									class="input-cinza select"
									:menu-props="menuProps"
									dense
								>
									<template
										v-slot:selection="{ item, index }"
									>
										<span
											v-if="
												filtro.bandeira.length === 1 &&
													index === 0
											"
											>{{ item.text }}</span
										>
										<span
											v-if="
												filtro.bandeira.length > 1 &&
													index === 0
											"
											class="grey--text caption mr-1"
											>{{
												filtro.bandeira.length
											}}
											selecionados</span
										>
									</template>
								</v-select>
							</v-col>

							<v-col
								cols="12"
								sm="6"
								md="4"
								lg="3"
								xl="3"
								class="py-0"
							>
								<v-autocomplete
									label="Espaço Macro"
									v-model="filtro.espaco_macro"
									:items="lista.espaco_macro"
									multiple
									outlined
									class="input-cinza select"
									:menu-props="menuProps"
									dense
								>
									<template
										v-slot:selection="{ item, index }"
									>
										<div
											v-if="
												filtro.espaco_macro.length ===
													1 && index === 0
											"
											class="autocomplete-selecao"
										>
											{{ item.text }}
										</div>
										<span
											v-if="
												filtro.espaco_macro.length >
													1 && index === 0
											"
											class="grey--text caption mr-1"
											>{{
												filtro.espaco_macro.length
											}}
											selecionados</span
										>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								sm="6"
								md="4"
								lg="3"
								xl="3"
								class="py-0"
							>
								<v-select
									label="Cluster Tabela"
									:items="listaClusterTabela"
									v-model="filtro.id_cluster"
									outlined
									class="input-cinza select"
									:menu-props="menuProps"
									dense
									clearable
								/>
							</v-col>
							<v-col
								cols="12"
								sm="6"
								md="4"
								lg="3"
								xl="3"
								class="py-0"
							>
								<v-select
									label="Cluster"
									:items="lista.cluster"
									v-model="filtro.id_cluster"
									multiple
									outlined
									class="input-cinza select"
									:menu-props="menuProps"
									dense
								>
									<template
										v-slot:selection="{ item, index }"
									>
										<span
											v-if="
												filtro.id_cluster.length ===
													1 && index === 0
											"
											>{{ item.text }}</span
										>
										<span
											v-if="
												filtro.id_cluster.length > 1 &&
													index === 0
											"
											class="grey--text caption mr-1"
											>{{
												filtro.id_cluster.length
											}}
											selecionados</span
										>
									</template>
								</v-select>
							</v-col>

							<v-col
								cols="12"
								sm="6"
								md="4"
								lg="3"
								xl="3"
								class="py-0"
							>
								<v-autocomplete
									label="Região"
									v-model="filtro.regiao"
									:items="lista.regiao"
									multiple
									outlined
									class="input-cinza select"
									:menu-props="menuProps"
									dense
								>
									<template
										v-slot:selection="{ item, index }"
									>
										<div
											v-if="
												filtro.regiao.length === 1 &&
													index === 0
											"
											class="autocomplete-selecao"
										>
											{{ item.text }}
										</div>
										<span
											v-if="
												filtro.regiao.length > 1 &&
													index === 0
											"
											class="grey--text caption mr-1"
											>{{
												filtro.regiao.length
											}}
											selecionados</span
										>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								sm="6"
								md="4"
								lg="3"
								xl="3"
								class="py-0"
							>
								<v-select
									label="Perfil"
									:items="lista.perfil"
									v-model="filtro.perfil"
									multiple
									outlined
									class="input-cinza select"
									:menu-props="menuProps"
									dense
								>
									<template
										v-slot:selection="{ item, index }"
									>
										<span
											v-if="
												filtro.perfil.length === 1 &&
													index === 0
											"
											>{{ item.text }}</span
										>
										<span
											v-if="
												filtro.perfil.length > 1 &&
													index === 0
											"
											class="grey--text caption mr-1"
											>{{
												filtro.perfil.length
											}}
											selecionados</span
										>
									</template>
								</v-select>
							</v-col>
							<v-col
								cols="12"
								sm="6"
								md="4"
								lg="3"
								xl="3"
								class="py-0"
							>
								<v-select
									label="Tamanho"
									:items="lista.tamanho"
									v-model="filtro.tamanho"
									multiple
									outlined
									class="input-cinza select"
									:menu-props="menuProps"
									dense
								>
									<template
										v-slot:selection="{ item, index }"
									>
										<span
											v-if="
												filtro.tamanho.length === 1 &&
													index === 0
											"
											>{{ item.text }}</span
										>
										<span
											v-if="
												filtro.tamanho.length > 1 &&
													index === 0
											"
											class="grey--text caption mr-1"
											>{{
												filtro.tamanho.length
											}}
											selecionados</span
										>
									</template>
								</v-select>
							</v-col>
							<v-col
								cols="12"
								sm="6"
								md="4"
								lg="3"
								xl="3"
								class="py-0"
							>
								<v-select
									label="Modelo CKO"
									:items="lista.check_out"
									v-model="filtro.check_out"
									multiple
									outlined
									class="input-cinza select"
									:menu-props="menuProps"
									dense
								>
									<template
										v-slot:selection="{ item, index }"
									>
										<span
											v-if="
												filtro.check_out.length === 1 &&
													index === 0
											"
											>{{ item.text }}</span
										>
										<span
											v-if="
												filtro.check_out.length > 1 &&
													index === 0
											"
											class="grey--text caption mr-1"
											>{{
												filtro.check_out.length
											}}
											selecionados</span
										>
									</template>
								</v-select>
							</v-col>
						</v-row>
						<v-divider class="mt-n2 mb-3"></v-divider>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>

			<v-row>
				<v-col cols="3" class="px-2 ml-auto">
					<div class="new-button d-flex align-center" text>
						<span @click="expandirFiltros" style="cursor: pointer;"
							>Pesonalizar Filtros</span
						>

						<v-btn
							style="background: #e6e6e6; width: 20px; height: 20px"
							fab
							icon
							class="ml-3"
							@click="expandirFiltros"
							x-small
						>
							<v-icon>{{
								mostrarFiltros == 0
									? "mdi-chevron-up"
									: "mdi-chevron-down"
							}}</v-icon>
						</v-btn>
					</div>
				</v-col>
				<v-col cols="3" class="d-flex px-3 mt-2 new-button">
					{{ dtLastUpdate }}
					<InfoBox class="customInfoBoxOport">
						<p>
							<span
								style="color: var(--v-primary-base); font-weight: 500"
								>Dados da Gerencial:
							</span>
							A data e hora informada, refere-se a última
							atualização automática dos dados consolidados dessa
							gerencial.
						</p>
						<p>
							O processamento é realizado a cada 1 hora
							aproximadamente, entre os horários de 4:00 às 23:00.
						</p>
						<p>
							O período será sempre de 13 meses (6 antes e depois
							do mês atual), neste momento os dados são de
							{{ periodoPreProcessado }}.
						</p>
						<p>
							Caso necessite gerar a informação em tempo real,
							flag o filtro ao lado, porém, dependendo da visão
							selecionada, pode demorar até 5 min para retornar os
							dados.
						</p>
					</InfoBox>
				</v-col>
				<v-col cols="2" class=" px-3 mt-0 ">
					<v-checkbox
						class="mt-1"
						v-model="filtro.realtime"
						label="Real time "
					></v-checkbox>
				</v-col>

				<v-col cols="4" class="ml-auto" align="end">
					<v-btn
						class="ml-2 new-button"
						color="yellow"
						dark
						@click="limpar()"
					>
						<v-icon left>mdi-filter-off</v-icon> Limpar Filtros
					</v-btn>
					<v-btn
						class="ml-5 new-button"
						color="primary"
						@click="filtrar()"
					>
						<v-icon left>mdi-filter</v-icon> Filtrar
					</v-btn>
				</v-col>
			</v-row>
			<v-overlay :absolute="true" :value="load || propLoad">
				<v-progress-circular indeterminate size="40" />
			</v-overlay>
		</v-card>
	</v-form>
</template>

<script>
import MesInput from "../../../components/MesInput.vue";
import InfoBox from "../../../components/InfoBox.vue";
import  useFiltros  from "../../../hooks/useFiltros";

export default {
    setup() {
		const { isLoading, isError, isFetching, lista, error, refetch } = useFiltros("/gerencial/filtrosOportunidade");

        return { isLoading, isError, isFetching, lista, error, refetch };
    },
	// inject: ["listaCampos"],
	components: {
		MesInput,
		InfoBox,
	},
	props: {
		propLastUpdate: String,
		propLoad: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			menuProps: {
				bottom: true,
				offsetY: true,
				contentClass: "select-outline",
				transition: "slide-y-transition",
			},
			mesAno: this.$moment().format("YYYY-MM"),
			mostrarFiltros: null,
			dtLastUpdate: null,
			listaCampos: {
				campo: [
					{ text: "Bandeira", value: "bandeira" },
					{ text: "Espaço", value: "espaco" },
					{ text: "Espaço Macro", value: "espaco_macro" },
					{ text: "ID JAVA", value: "id_java" },
					{ text: "Cluster", value: "cluster" },
					{ text: "Cluster Tabela", value: "cluster_macro" },
					{ text: "Região", value: "regiao" },
					{ text: "Cidade", value: "cidade" },
					{ text: "Perfil", value: "perfil" },
					{ text: "Tamanho", value: "tamanho" },
					{ text: "Modelo CKO", value: "modelo_cko" },
					{ text: "Valor Unit.", value: "vl_unitario" },
				],
			},
			filtro: {},
			regra: {
				mesInicial: [
					(v) => !!v || "Selecione um período inicial para o filtro",
					(v) =>
						(!!v && v >= "2021-06") ||
						"O período inicial deve ser igual ou posterior a junho de 2021",
				],
				mesAno: [
					(v) => {
						if (this.mostrarFiltros == null) {
							if (
								!this.filtro.mesInicial ||
								!this.filtro.mesFinal
							)
								return "Selecione um período para o filtro";
						}
						return true;
					},
				],
				mesFinal: [
					(v) => !!v || "Selecione um período final para o filtro",
					(v) =>
						(!!v && v >= "2021-06") ||
						"O período final deve ser igual ou posterior a junho de 2021",
					(v) =>
						(!!v && v >= this.filtro.mesInicial) ||
						"O período final deve ser igual ou posterior ao período inicial",
				],
			},
			load: false,
		};
	},
	async mounted() {
		this.load = true;

		this.iniciaFiltro();

		this.load = false;

		this.filtrar();
	},
	methods: {

		iniciaFiltro() {
			this.filtro = {
				mesInicial: this.$moment().format("YYYY-MM"),
				mesFinal: this.$moment().format("YYYY-MM"),
				cnpj: null,
				bandeira: [],
				espaco_macro: [],
				id_espaco: [],
				id_cluster: [],
				id_filial: [],
				regiao: [],
				perfil: [],
				tamanho: [],
				check_out: [],
				realtime: false,
				campo: ["cluster"],
			};
		},
		limpar() {
			this.iniciaFiltro();

			this.filtrar();
		},
		filtrar() {
			this.$emit("filtrar");
		},
		validate() {
			return this.$refs.formFiltro.validate();
		},
		resetValidation() {
			try {
				this.$refs.formFiltro.resetValidation();
			} catch (error) {
				//console.log(error)
			}
		},
		expandirFiltros() {
			this.mostrarFiltros = this.mostrarFiltros == 0 ? null : 0;
			setTimeout(() => {
				this.validate();
			}, 100);
		},
		periodoChange(valor) {
			this.mesAno = null;

			setTimeout(() => {
				this.validate();
			}, 10);
		},
		mesAnoChange(valor) {
			this.filtro.mesInicial = valor;
			this.filtro.mesFinal = valor;
		},
	},
	watch: {
		"filtro.campo": {
			handler(val) {
				if(val.includes('id_java') && !val.includes('cnpj')){
					this.filtro.campo.push('cnpj')
				}else if(!val.includes('id_java') && val.includes('cnpj')){
					this.filtro.campo.splice(this.filtro.campo.indexOf('cnpj'), 1)
				}
			
			},
		},
		propLastUpdate: function(newVal, oldVal) {
			this.dtLastUpdate = newVal;
		},
	},
	computed: {
				listaClusterTabela() {
			if(!this.lista.cluster) return []

					const cluster = this.lista.cluster
					let _clusters = [...new Set(cluster.map((e) => e.cluster_macro))];
			var _result = [];

			for (let x of _clusters) {
				let _values = [
					...new Set(
						cluster
							.filter((e) => e.cluster_macro == x)
							.map((v) => v.value)
					),
				];

				_result.push({
					text: x,
					value: _values,
				});
			}
			return _result;
		},

		periodoPreProcessado() {
			return `
				${this.$moment()
					.subtract(6, "months")
					.format("MM/YYYY")}
				à 
				${this.$moment()
					.add(6, "months")
					.format("MM/YYYY")}
			`;
		},
	},
};
</script>

