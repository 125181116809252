var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form_4"},[_c('v-row',{staticClass:"mt-4 mb-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"input-cinza",attrs:{"label":"Pesquisar","dense":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"botao-pesquisa"},[_vm._v(" a "),_c('v-icon',[_vm._v("mdi-magnify")])],1)]},proxy:true}]),model:{value:(_vm.buscaProduto),callback:function ($$v) {_vm.buscaProduto=$$v},expression:"buscaProduto"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"3","offset":"3"}},[_c('v-btn',{staticClass:"ml-auto mb-1 d-block new-button",attrs:{"color":"primary"},on:{"click":function($event){return _vm.abrirImportacao()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v(" Importar Base de Produtos ")],1)],1)],1),_c('v-data-table',{staticClass:"tabela paginacao-esquerda",attrs:{"headers":_vm.listaHeaderProduto,"items":_vm.produto,"search":_vm.buscaProduto,"item-key":"id_produto","items-per-page":5,"footer-props":{
            'items-per-page-options': [5, 10, 15, 25, 50, 100],
            itemsPerPageText: 'Linhas por páginas',
            ofText: 'de',
            pageText: '{0}-{1} de {2}',
        },"options":_vm.pagination,"show-select":""},on:{"update:options":function($event){_vm.pagination=$event}},model:{value:(_vm.produtoSelecionado),callback:function ($$v) {_vm.produtoSelecionado=$$v},expression:"produtoSelecionado"}}),_c('div',{staticClass:"table-footer-prepend d-flex pl-2 pr-0 mr-0 pt-6 align-center justify-end"},[_c('v-row',{staticClass:"mb-3 mt-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-2 ml-auto",attrs:{"cols":"12","align":"end"}},[_c('v-btn',{staticClass:"ml-4 mb-1 new-button",attrs:{"color":"error"},on:{"click":function($event){return _vm.limpaProdutos(true)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-trash-can")]),_vm._v(" Limpar Produtos ")],1),_c('v-btn',{staticClass:"ml-4 mb-1 new-button",attrs:{"color":"primary"},on:{"click":function($event){return _vm.carregarProduto()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-up")]),_vm._v(" Carregar Produtos Salvos ")],1),_c('v-btn',{staticClass:"ml-4 mb-1 new-button",attrs:{"color":"primary"},on:{"click":function($event){return _vm.salvarProduto()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-down")]),_vm._v(" Salvar Produtos Selecionados ")],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0 tabela mt-4",attrs:{"headers":_vm.listaHeaderProdutoSalvo,"items":_vm.value.produtos,"item-key":"nome","footer-props":{
            'items-per-page-options': [5, 10, 15, 25, 50, 100],
            itemsPerPageText: 'Linhas por páginas',
            ofText: 'de',
            pageText: '{0}-{1} de {2}',
        },"disable-sort":""}}),_c('v-snackbar',{staticStyle:{"z-index":"6"},attrs:{"color":_vm.alertaColor,"timeout":_vm.alertaTimeout,"top":""},model:{value:(_vm.alerta),callback:function ($$v) {_vm.alerta=$$v},expression:"alerta"}},[_c('v-alert',{attrs:{"type":_vm.alertaColor}},[_vm._v(" "+_vm._s(_vm.alertaTexto)+" ")]),_c('v-btn',{staticClass:"float-right",attrs:{"dark":"","text":""},on:{"click":function($event){_vm.alerta = false}}},[_vm._v(" Fechar ")])],1),_c('v-overlay',{attrs:{"value":_vm.carregando}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('importar-produtos',{ref:"importarProdutos",on:{"importar":_vm.importar,"exportarModelo":_vm.exportarModelo}}),(_vm.dialogoCamposFaltando)?_c('div',{staticClass:"dialogo-erro",attrs:{"width":"700px"}},[_c('v-card',{staticClass:"br-15"},[_c('v-toolbar',{staticClass:"error white--text fix-flex",attrs:{"elevation":"0","dark":""}},[_c('v-toolbar-title',[_vm._v(" Por favor retorne as etapas anteriores e preencha os seguintes campos:")])],1),_c('v-card-text',{staticClass:"pa-5"},[_c('v-list',_vm._l((_vm.camposFaltando),function(campo){return _c('v-list-item',{key:campo},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(campo))])],1)],1)}),1)],1)],1)],1):_vm._e(),_c('v-overlay',{attrs:{"value":_vm.semAcesso,"absolute":true,"opacity":0.1,"z-index":3}}),_c('v-overlay',{attrs:{"value":_vm.mostraOverlay,"absolute":true,"opacity":0.6,"z-index":3}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }