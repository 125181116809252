<template>
    <v-container fluid>
        <filtro ref="filtro" @filtrar="filtrar"></filtro>
        <v-card class="my-4 px-2 br-15" elevation="0">
            <v-row no-gutters>
                <v-col cols="8" class="px-4 py-4">
                    <div class="detalhes">
                        <span>Fornecedor:</span
                        ><span class="grey--text">
                            {{ resumo.fornecedor }}
                        </span>
                        <br />
                        <span>Mês: </span>
                        <span class="grey--text"> {{ resumo.periodo }} </span>
                        <br />
                        <span>Quant. de Filiais:</span>
                        <span class="grey--text"> {{ resumo.filiais }} </span>
                        <br />
                    </div>
                </v-col>
                <v-col cols="4" align="end" class="py-2">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                class="new-button px-8 mr-4 mt-4"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon left>mdi-microsoft-excel</v-icon>
                                Exportar
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="extratoXLSX()">
                                <v-list-item-title
                                    >Detalhado XLSX</v-list-item-title
                                >
                            </v-list-item>
                            <v-list-item @click="exportar('Verba')">
                                <v-list-item-title>Verba CSV</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="exportar('VB')">
                                <v-list-item-title>VB CSV</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <v-divider class="mx-4"></v-divider>
            <v-row class="mx-1 my-2 mb-3 mt-3">
                <v-col cols="3">
                    <Indicadores
                        titulo="PDV"
                        :valor="resumo.investimento"
                        icone="mdi-store-outline"
                        :load="false"
                    />
                </v-col>
                <v-col cols="3">
                    <Indicadores
                        titulo="E-commerce"
                        :valor="resumo.investimentoEcommerce"
                        icone="mdi-tag-outline"
                        :load="false"
                    />
                </v-col>
                <v-col cols="3">
                    <Indicadores
                        titulo="Campanha"
                        :valor="resumo.investimentoCampanha"
                        icone="mdi-shopping-outline"
                        :load="false"
                    />
                </v-col>
                <v-col cols="">
                    <Indicadores
                        titulo="InvestimentoTotal"
                        :valor="resumo.total"
                        icone="mdi-currency-usd"
                        :load="false"
                    />
                </v-col>
            </v-row>
        </v-card>
        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>
        <v-dialog
            :persistent="porcentagem < 100"
            content-class="br-15"
            v-model="dialog"
            width="600"
            style="z-index: 5000"
        >
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title> Exportando Extrato XLSX </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="porcentagem < 100"
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialog = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-5">
                    <p class="text-center">
                        {{ mensagemProcessamento }}
                        <span v-if="!erroProcessamento"
                            >({{ porcentagem }}%)</span
                        >
                    </p>
                    <v-progress-linear
                        class="mb-3"
                        :color="erroProcessamento ? 'error' : 'teal'"
                        buffer-value="0"
                        :value="porcentagem"
                        stream
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Filtro from "./Filtro.vue";
import Indicadores from "./_components/Indicadores.vue";

export default {
    components: {
        Filtro,
        Indicadores,
    },
    data() {
        return {
            dialog: false,
            carregando: false,
            erroProcessamento: false,
            dadosVerba: [],
            headersVerba: [
                { text: "Acor.", value: "id_acordo", align: "center" },
                { text: "Acor. RD", value: "acordo_rd", align: "center" },
                { text: "Bandeira", value: "bandeira", align: "center" },
                {
                    text: "Cod. Forn.",
                    value: "codigo_fornecedor",
                    align: "center",
                },
                { text: "Fornecedor", value: "fornecedor", align: "center" },
                { text: "Cluster", value: "cluster", align: "center" },
                { text: "Cod. Esp.", value: "codigo_espaco", align: "center" },
                { text: "Espaço", value: "espaco", align: "center" },
                { text: "Desc.", value: "desconto", align: "center" },
                {
                    text: "Desc. %",
                    value: "desconto_percentual",
                    align: "center",
                },
                { text: "Invest.", value: "investimento", align: "center" },
                { text: "Valor s/ Desc.", value: "valor", align: "center" },
                { text: "Mês", value: "mes", align: "center" },
                { text: "Filiais", value: "filiais", align: "center" },
                { text: "Produtos", value: "produtos", align: "center" },
                { text: "Status", value: "status", align: "center" },
                {
                    text: "Obs. Depart.",
                    value: "obs_departamento",
                    align: "center",
                },
            ],
            paginationVerba: {},
            totalVerba: null,
            dadosVB: [],
            headersVB: [
                { text: "Acor.", value: "id_acordo", align: "center" },
                { text: "Acor. RD", value: "acordo_rd", align: "center" },
                { text: "Mês", value: "mes", align: "center" },
                { text: "Bandeira", value: "bandeira", align: "center" },
                { text: "ID JAVA", value: "id_java", align: "center" },
                { text: "CNPJ", value: "cnpj", align: "center" },
                { text: "Filial", value: "filial", align: "center" },
                { text: "Cluster", value: "cluster", align: "center" },
                {
                    text: "Cod. Prod.",
                    value: "codigo_produto",
                    align: "center",
                },
                { text: "Produto", value: "produto", align: "center" },
                { text: "Espaço", value: "espaco", align: "center" },
                {
                    text: "Cod. Forn.",
                    value: "codigo_fornecedor",
                    align: "center",
                },
                { text: "Fornecedor", value: "fornecedor", align: "center" },
                { text: "VB", value: "vb", align: "center" },
            ],
            paginationVB: {},
            totalVB: null,
            porcentagem: 0,
            mensagemProcessamento: "",
            resumo: {
                fornecedor: null,
                periodo: null,
                filiais: null,
                investimento: null,
            },
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
        };
    },
    // provide() {
    //     return {
    //         listaCampos: this.listaCampos(),
    //     };
    // },
    watch: {
        paginationVerba: {
            handler() {
                if (this.$refs.filtro.filtro.id_fornecedor) {
                    this.filtrar("Verba");
                }
            },
            deep: true,
        },
        paginationVB: {
            handler() {
                if (this.$refs.filtro.filtro.id_fornecedor) {
                    this.filtrar("VB");
                }
            },
            deep: true,
        },
    },
    computed: {},

    methods: {
        // async listaCampos() {
        //     this.carregando = true;

        //     var response = await this.$http().post("/gerencial/filtros", {});

        //     this.carregando = false;

        //     return response.data;
        // },

        async filtrar2() {
            await this.$http().post(
                "/gerencial/testeXLSX",
                {},
                {
                    // params: data,
                    onDownloadProgress: (progressEvent) => {
                        const retorno =
                            progressEvent.currentTarget.response.split("\n");
                        console.log(progressEvent);
                        this.teste = retorno[retorno.length - 2];

                        // let _i =
                        //     retorno.length > 1 ? retorno.length - 2 : 0;
                        // let obj = JSON.parse(retorno[_i]);

                        // // this.index = obj.index || 0;
                        // console.log(obj)
                        // if (obj.url) {
                        //     url = obj.url;
                        // }

                        // if (obj.msg) msgError = obj.msg;
                    },
                }
            );
        },

        async filtrar(tipo = "tudo") {
            this.carregando = true;

            var filtro = { ...this.$refs.filtro.filtro };

            var data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesInicial;
            data.id_filial = filtro.id_filial;
            data.bandeira = filtro.bandeira;
            data.id_fornecedor = [filtro.id_fornecedor];
            data.id_espaco = filtro.id_espaco;
            data.espaco_macro = filtro.espaco_macro;
            data.id_cluster = filtro.id_cluster;
            data.regiao = filtro.regiao;
            data.perfil = filtro.perfil;
            data.tamanho = filtro.tamanho;

            if (tipo == "tudo") {
                this.paginationVerba.page = 1;
                this.paginationVB.page = 1;
            }

            data.paginationVerba = this.paginationVerba;
            data.paginationVB = this.paginationVB;

            data.tipo = tipo;

            var response = await this.$http().post("/gerencial/extrato", {
                data: data,
            });

            if (tipo == "tudo" || tipo == "Verba") {
                this.dadosVerba = response.data.extratoVerba;
                this.totalVerba = response.data.extratoVerba.length
                    ? response.data.extratoVerba[0].total * 1
                    : 0;
            }

            if (response.data.extratoResumo) {
                this.resumo = response.data.extratoResumo[0];
                this.resumo.periodo = this.$moment(
                    data.mesInicial + "-01"
                ).format("MMM/YYYY");
                this.resumo.investimento = response.data.extratoVerba.reduce(
                    (a, b) => a + b.investimento_valor * 1,
                    0
                );
                this.resumo.investimentoEcommerce = response.data.extratoEcommerce.reduce(
                    (a, b) => a + b.investimento_valor * 1,
                    0
                );
                // this.resumo.investimentoCampanha = 0;
                this.resumo.investimentoCampanha = response.data.extratoCampanha.reduce(
                    (a, b) => a + b.investimento_valor * 1,
                    0
                );
                this.resumo.total = this.resumo.investimento + this.resumo.investimentoEcommerce // + this.resumo.investimentoCampanha;
            } else {
                this.resumo = {
                    fornecedor: null,
                    periodo: null,
                    filiais: null,
                    investimento: null,
                    investimentoEcommerce: null,
                    investimentoCampanha: null,
                    total: null,
                };
            }

            this.carregando = false;
        },
        async exportar(tipo) {
            if (!this.$refs.filtro.validate()) return;
            this.porcentagem = 0;
            this.mensagemProcessamento = "Enviando dados.";
            this.carregando = true;

            var filtro = { ...this.$refs.filtro.filtro };

            var data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesInicial;
            data.id_filial = filtro.id_filial;
            data.bandeira = filtro.bandeira;
            data.id_fornecedor = [filtro.id_fornecedor];
            data.id_espaco = filtro.id_espaco;
            data.espaco_macro = filtro.espaco_macro;
            data.id_cluster = filtro.id_cluster;
            data.regiao = filtro.regiao;
            data.perfil = filtro.perfil;
            data.tamanho = filtro.tamanho;

            data.tipo = tipo;

            var response = await this.$http().post(
                "/gerencial/exportarExtrato",
                { data: data }
            );

            window.location = this.$http("baseURL") + response.data.url;

            this.carregando = false;
        },
        async extratoXLSX() {
            if (!this.$refs.filtro.validate()) return;
            this.erroProcessamento = false;
            // if (this.totalVB > 200000 || this.totalVerba > 200000) {
            //     this.alerta = true;
            //     this.alertaColor = "error";
            //     this.alertaTimeout = -1;
            //     this.alertaTexto =
            //         "Não é permitido exportação de extrato XLSX com mais de 200.000 linhas. Utilize os filtros para dividir a exportação, ou exporte como CSV.";
            //     return;
            // }

            // this.carregando = true;
            this.dialog = true;

            var filtro = { ...this.$refs.filtro.filtro };

            var data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesInicial;
            data.id_filial = filtro.id_filial;
            data.bandeira = filtro.bandeira;
            data.id_fornecedor = [filtro.id_fornecedor];
            data.id_espaco = filtro.id_espaco;
            data.espaco_macro = filtro.espaco_macro;
            data.id_cluster = filtro.id_cluster;
            data.regiao = filtro.regiao;
            data.perfil = filtro.perfil;
            data.tamanho = filtro.tamanho;

            data.periodo = this.$moment(data.mesInicial + "-01").format(
                "MMM/YYYY"
            );

            var response = await this.$http().post(
                "/gerencial/extratoXLSX",
                {
                    data: data,
                },
                {
                    onDownloadProgress: (progressEvent) => {
                        const retorno =
                            progressEvent.currentTarget.response.split("\n");
                        console.log(progressEvent);
                        const data = JSON.parse(retorno[retorno.length - 2]);
                        console.log(data);
                        this.porcentagem = data.porcentagem;
                        this.mensagemProcessamento = data.mensagem;
                        if (data.url) {
                            window.location = this.$http("baseURL") + data.url;
                        }
                    },
                }
            );
            console.log(response);
            if (response.status !== 200) {
                this.mensagemProcessamento =
                    "Erro ao gerar o arquivo. Tente novamente.";
                this.porcentagem = 100;
                this.erroProcessamento = true;
            }
            this.carregando = false;
        },
    },
};
</script>


<style>
.detalhes {
    font-weight: 500;
}
</style>