<template>
  <v-form ref="form">
    <v-row class="mt-4">
      <v-col cols="6" class="py-0">
        <v-text-field
          label="Projeto"
          class="input-cinza"
          v-model="value.projeto"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0 d-flex">
        <div class="mr-4 flex-grow-1">
          <v-combobox
            label="Vitrine/Skus"
            class="input-cinza"
            v-model="value.vitrine"
            :items="listaVitrines"
            :menu-props="menuProps"
            outlined
            dense
            multiple
            deletable-chips
            :delimiters="[',']"
            small-chips
            validate-on-blur
            @change="delimit"
          ></v-combobox>
        </div>
        <div class="flex-shrink-0">
          <v-btn
            color="primary"
            class="new-button w-100"
            @click="abrirImportacao"
          >
            <v-icon left>mdi-plus</v-icon>
            Importar SKUs
          </v-btn>
        </div>
      </v-col>
      <v-col cols="4" class="py-0">
        <v-text-field
          label="Link de direcionamento"
          class="input-cinza"
          v-model="value.link"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="4" class="py-0">
        <v-text-field
          label="ID"
          class="input-cinza"
          v-model="value.id_rd"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4" class="py-0">
        <v-text-field
          label="Posição"
          class="input-cinza"
          v-model="value.posicao"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="4" class="py-0">
        <v-text-field
          label="Alteração/Pausada"
          class="input-cinza"
          v-model="value.alteracao"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="4" class="py-0">
        <v-text-field
          label="Prazo de arte"
          class="input-cinza"
          v-model="value.prazo_de_arte"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="4" class="py-0">
        <v-text-field
          label="Motivo atraso"
          class="input-cinza"
          v-model="value.motivo_atraso"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" class="py-0">
        <v-textarea
          outlined
          v-model="value.observacoes"
          class="input-cinza"
          dense
          :counter="500"
          :rules="regra.observacoes"
          rows="3"
          label="Observações"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-dialog content-class="br-15" scrollable v-model="dialogoSku" width="600">
      <v-card>
        <v-toolbar
          elevation="0"
          dark
          class="primary-degrade white--text fix-flex"
        >
          <v-toolbar-title>Importação de SKUs </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            fab
            width="24px"
            height="24px"
            elevation="0"
            light
            @click="dialogoSku = false"
          >
            <v-icon size="20px" color="primary">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-5">
          Selecione o arquivo para importação. Apenas a coluna SKU será
          importada.
          <v-file-input
            accept=".csv"
            label="Arquivo"
            dense
            v-model="arquivoSku"
            outlined
            class="mt-5 input-cinza"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="new-button mr-3 px-4 mb-4"
            @click="processaCSV"
          >
            <v-icon class="mr-3">mdi-check-circle</v-icon>
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      mesAtual: this.$moment().format("YYYY-MM"),
      bandeiras: ["Raia", "Drogasil"],
      devices: [
        { id: 1, nome: "Desktop" },
        { id: 2, nome: "Mobile" },
        { id: 3, nome: "App" },
      ],
      arquivoSku: false,
      menuProps: {
        bottom: true,
        offsetY: true,
        contentClass: "select-outline",
        transition: "slide-y-transition",
      },
      dialogoSku: false,
      regra: {
        observacoes: [
          (v) => {
            if (v.length > 500) {
              return "Máximo de 500 caracteres";
            }
            return true;
          },
        ],
      },
    };
  },
  computed: {
    listaVitrines() {
      if (this.value.vitrine && this.value.vitrine.length) {
        return this.value.vitrine;
      }
      return [];
    },
  },
  methods: {
    abrirImportacao() {
      this.arquivoSku = null;
      this.dialogoSku = true;
    },
    async processaCSV() {
      const parseCsv = async (csv) => {
        return new Promise((resolve) => {
          this.$papa.parse(csv, {
            delimiter: ";",
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: function (results) {
              resolve(results);
            },
          });
        });
      };

      var csv = await parseCsv(this.arquivoSku);

      if (csv.errors.length > 0) {
        this.$dialog.notify.error("Erro ao importar arquivo", {
          position: "top-right",
          timeout: 5000,
        });
        return;
      }
      if (!csv.data[0].sku) {
        this.$dialog.notify.error("Coluna SKU não encontrada", {
          position: "top-right",
          timeout: 5000,
        });
        return;
      }
      this.value.vitrine = csv.data.map((e) => e.sku);
      this.dialogoSku = false;
    },
    validarPeriodoInicial(v) {
      if (this.value.mesFinal) {
        return this.$moment(v).isSameOrBefore(this.value.mesFinal);
      }
      return true;
    },
    validarPeriodoFinal(v) {
      if (this.value.mes) {
        return this.$moment(v).isSameOrAfter(this.value.mes);
      }
      return true;
    },
    periodoInicialChange(v) {
      if (this.value.mesFinal) {
        if (this.$moment(v).isAfter(this.value.mesFinal)) {
          this.value.mesFinal = v;
        }
      }
    },
    delimit(v) {
      const reducer = (a, e) => [...a, ...e.split(/[,]+/)];
      this.value.vitrine = [...new Set(v.reduce(reducer, []))];
    },
    periodoFinalChange(v) {
      if (this.value.mes) {
        if (this.$moment(v).isBefore(this.value.mes)) {
          this.value.mes = v;
        }
      }
    },
  },
};
</script>
