<template>
    <v-container fluid>
        <v-card class="px-5 py-3 br-15 w-100" elevation="0">
            <v-row>
                <v-col class="flex-grow-1">
                    <v-toolbar-title class="page-title"
                        >Preços E-commerce</v-toolbar-title
                    >
                </v-col>
                <v-col>
                    <MesInput v-model="mes" label="" />
                </v-col>

                <v-col md="2">
                    <v-btn
                        class="w-100 new-button"
                        color="primary"
                        @click="exportar()"
                    >
                        <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                    </v-btn>
                </v-col>
                <v-col md="2">
                    <v-btn
                        class="w-100 new-button"
                        color="primary"
                        @click="abrirImportar()"
                    >
                        <v-icon left>mdi-upload</v-icon> Importar
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="6">
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        class="input-cinza"
                        dense
                        outlined
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col md="4">
                    <v-autocomplete
                        label="Espaço"
                        v-model="filtro.id_espaco"
                        :items="lista.espaco"
                        :menu-props="menuProps"
                        class="input-cinza"
                        multiple
                        outlined
                        dense
                    >
                        <template v-slot:selection="{ item, index }">
                            <div
                                v-if="
                                    filtro.id_espaco.length === 1 && index === 0
                                "
                                class="autocomplete-selecao"
                            >
                                {{ item.text }}
                            </div>
                            <span
                                v-if="
                                    filtro.id_espaco.length > 1 && index === 0
                                "
                                class="grey--text caption mr-1"
                                >{{
                                    filtro.id_espaco.length
                                }}
                                selecionados</span
                            >
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col md="2">
                    <v-btn
                        class="w-100 new-button"
                        color="primary"
                        @click="filtrar()"
                    >
                        <v-icon left>mdi-filter</v-icon> Filtrar
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="px-5 py-3 my-5 br-15 w-100" elevation="0">
            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="12" md="6" lg="8" xl="8"> </v-col>
                <v-col cols="12" sm="12" md="6" lg="4" xl="4" align="end">
                </v-col>
            </v-row>
            <v-data-table
                class="elevation-0 tabela"
                :headers="headers"
                :items="dados"
                :server-items-length="total"
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100],
                }"
                :header-props="{ sortIcon: 'mdi-chevron-down' }"
                :options.sync="pagination"
                multi-sort
            >
                <template v-slot:[`item.botao`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="abrirEditar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog content-class="br-15" v-model="dialogo" width="800">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title> Editar Espaço </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogo = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4 pb-0">
                    <v-form v-model="validaForm">
                        <v-row no-gutters class="mt-6 pt-4">
                            <v-col cols="6" class="px-2">
                                <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    label="Espaço"
                                    class="input-cinza"
                                    v-model="espaco.espaco"
                                ></v-text-field>
                            </v-col>
                            <!-- <v-col cols="6" class="px-2">
                                <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    label="Categoria"
                                    class="input-cinza"
                                    v-model="espaco.categoria"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="px-2">
                                <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    label="Device"
                                    class="input-cinza"
                                    v-model="espaco.device"
                                ></v-text-field>
                            </v-col> -->

                            <!-- <v-col cols="6" class="px-2">
                                <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    class="input-cinza"
                                    label="Bandeira"
                                    v-model="espaco.bandeira"
                                ></v-text-field>
                            </v-col> -->
                            <v-col cols="6" class="px-2">
                                <MesInput v-model="mes" disabled label="" />
                            </v-col>
                            <v-col cols="6" class="px-2">
                                <vuetify-money
                                    label="Custo"
                                    outlined
                                    v-model="espaco.custo"
                                    dense
                                    required
                                    :options="{
                                        locale: 'pt-BR',
                                        length: 16,
                                        precision: 2,
                                    }"
                                    :properties="{
                                        class: 'input-cinza',
                                    }"
                                ></vuetify-money>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        class="new-button mt-0 pr-4 mr-4 mb-4"
                        :disabled="!validaForm"
                        @click="editar()"
                    >
                        <v-icon left>mdi-check-circle</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogoImportar" width="800px">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title> Importar CSV </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoImportar = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row   class="mt-4">
                        <v-col>
                            <MesInput v-model="mes" label="Mes importação" />

                        </v-col>
                    </v-row>
                    <v-alert
                      
                        text
                        type="info"
                        border="left"
                        style="font-size: 12px"
                    >
                        <ul>
                            <li>
                                A base utilizada para importação deve ser
                                parecida com base exportada. As colunas
                                <strong>id_espaço</strong> não deve ser
                                preenchida quando o espaço for cadastrado, a
                                informação será gerada pela IPDV.
                            </li>
                            <li>
                                Se for uma atualização de
                                <strong>custo</strong> (preço) deverá ter no
                                arquivo o <strong>id_espaço</strong> e dessa
                                forma é somente exportar o arquivo e alterar
                                apenas a coluna <strong>D</strong> (custo).
                            </li>
                            <li>
                                Os campos
                                <strong>
                                    espaco e custo
                                </strong>
                                são obrigatórios o preenchimento para a
                                importação.
                            </li>
                            <li>
                             <strong>Essa importação irá sobrescrever os custos para os meses futuros.</strong>  
                            </li>
                            <li>
                            a coluna <strong>mes</strong> serve apenas para identificar o mês da exportação e <strong>não será considerada para atualização.</strong>
                            </li>
                        </ul>
                    </v-alert>
                    <v-row no-gutters>
                        <v-col cols="8" class="px-2">
                            <v-form ref="form" v-model="isCsv">
                                <v-file-input
                                    label="Arquivo CSV"
                                    outlined
                                    dense
                                    v-model="arquivoCSV"
                                    :rules="regra.csv"
                                    accept=".csv"
                                ></v-file-input>
                            </v-form>
                        </v-col>
                        <v-col cols="4" class="px-2">
                            <v-btn
                                color="primary"
                                :disabled="!isCsv"
                                @click="importar()"
                            >
                                <v-icon left>mdi-upload</v-icon>
                                Importar Arquivo
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-expand-transition>
                        <div v-show="erro">
                            <v-row no-gutters class="mb-0">
                                <v-col cols="6">
                                    <download-csv
                                        :data="erros"
                                        :labels="errosLabels"
                                        name="erros.csv"
                                        delimiter=";"
                                    >
                                        <v-btn color="primary">
                                            <v-icon left
                                                >mdi-microsoft-excel</v-icon
                                            >
                                            Exportar Erros
                                        </v-btn>
                                    </download-csv>
                                </v-col>
                            </v-row>
                            <v-data-table
                                :headers="headerErros"
                                :items="erros"
                                :items-per-page="5"
                                :options.sync="paginationErros"
                                class="elevation-0 data-table-2"
                            ></v-data-table>
                        </div>
                    </v-expand-transition>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            headers: [
             
                {
                    text: "ID Espaço",
                    value: "id_espaco",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Espaço",
                    value: "espaco",
                    align: "center",
                    class: "th-separador",
                },
                // {
                //     text: "Categoria",
                //     value: "categoria",
                //     align: "center",
                //     class: "th-separador",
                // },
                // {
                //     text: "Bandeira",
                //     value: "bandeira",
                //     align: "center",
                //     class: "th-separador",
                // },
                // {
                //     text: "Device",
                //     value: "device",
                //     align: "center",
                //     class: "th-separador",
                // },
                {
                    text: "Custo",
                    value: "custo",
                    align: "center",
                    class: "th-separador",
                },

                { text: "", value: "botao", align: "center", sortable: false },
            ],
            dados: [],
            lista: {},
            filtro: {
                id_categoria: [],
                id_espaco: [],
            },
            espaco: {
                id_espaco: null,
                codigo: null,
                nome: null,
                organico: null,
                espaco_macro: null,
            },
            mes: this.$moment().format("YYYY-MM"),
            pagination: {},
            paginationErros: {},
            total: null,
            carregando: false,
            busca: null,
            dialogo: false,
            dialogoImportar: false,
            validaForm: false,
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
            arquivoCSV: null,
            regra: {
                csv: [
                    (v) => !!v || "Selecione um arquivo.",
                    (v) =>
                        (!!v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
                        "O arquivo deve ser do tipo CSV.",
                ],
            },
            isCsv: false,
            headerErros: [
                { text: "Descrição do Erro", value: "msg", align: "left" },
            ],
            erros: [],
            errosLabels: { msg: "Descrição do Erro" },
            erro: false,
        };
    },
    watch: {
        pagination: {
            handler() {
                this.filtrar();
            },
            deep: true,
        },
        busca(v) {
            setTimeout(() => {
                if (v == this.busca) {
                    this.filtrar();
                }
            }, 1500);
        },
    },
    async created() {
        this.carregando = true;

        var response = await this.$http().post("/espacoEcommerce/listaCampos", {
            data: {},
        });

        this.lista = response.data;

        this.carregando = false;
    },
    methods: {
        async filtrar() {
            this.carregando = true;

            const data = {
                filtro: this.filtro,
                mes: this.mes,
            };

            data.pagination = this.pagination;
            data.busca = this.busca;

            const response = await this.$http().post("/espacoEcommerce/lista", {
                data: data,
            });

            this.dados = response.data;
            this.total = response.data.length ? response.data[0].total : 0;

            if (this.total == 0 && this.pagination.page > 1) {
                this.pagination.page = 1;
                this.filtrar(false);
            }

            for (const e of this.dados) {
                e.organicoStr = e.organico ? "Sim" : "Não";
            }

            this.carregando = false;
        },
        async exportar() {
            this.carregando = true;

            const data = {
                filtro: this.filtro,
                mes: this.mes,
            };

            const response = await this.$http().post(
                "/espacoEcommerce/exportar",
                {
                    data: data,
                }
            );

            window.location = this.$http("baseURL") + response.data.url;

            this.carregando = false;
        },
        abrirEditar(item) {
            this.espaco = { ...item };

            this.dialogo = true;
        },
        async editar() {
            this.carregando = true;

            var data = this.espaco;
            data.mes = this.mes + "-01";

            var response = await this.$http().post("/espacoEcommerce/editar", {
                data: data,
            });

            this.carregando = false;

            if (response.data.sucesso) {
                this.filtrar();
                this.dialogo = false;
            } else {
                this.alerta = true;
                this.alertaColor = "error";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;
            }
        },
        abrirImportar() {
            this.arquivoCSV = null;
            this.erro = false;

            try {
                this.$refs.form.resetValidation();
            } catch (error) {
                //console.log(error)
            }

            this.dialogoImportar = true;
        },
        async importar() {
            this.carregando = true;

            var formData = new FormData();
			var data = { mes: this.mes + "-01" };

            formData.append("files", this.arquivoCSV);
            formData.append("data", JSON.stringify(data));


            this.arquivoCSV = null;

            try {
                this.$refs.form.resetValidation();
            } catch (error) {
                //console.log(error)
            }

            var response = await this.$http().post(
                "/espacoEcommerce/importar",
                formData
            );

            this.carregando = false;

            if (response.data.sucesso) {
                this.filtrar();
                this.dialogoImportar = false;
            } else {
                this.filtrar();
                this.erros = response.data.erros;
                this.pagination.page = 1;
                this.erro = true;
            }
        },
    },
};
</script>