import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueMask from 'v-mask'
import VuetifyMoney from "vuetify-money"
import JsonCSV from 'vue-json-csv'
import {http} from './config/http'
import VuePapaParse from 'vue-papa-parse'
import moment from 'moment'
import pptxgen from "pptxgenjs"
import helpers from './plugins/helpers'
import './plugins/dialog'
import roundedCorners from 'highcharts-rounded-corners'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import dataModule from "highcharts/modules/export-data"
import './plugins/tiptap-vuetif'
import FormataReal from './components/FormataReal'
import MesInput from './components/MesInput.vue'
import DataInput from './components/DataInput.vue'
import MesInputMultiple from './components/MesInputMultiple.vue'
import InfoBox from './components/InfoBox.vue'
import DatetimePicker from 'vuetify-datetime-picker'
import VueCompositionAPI from '@vue/composition-api'
import { VueQueryPlugin, VueQueryPluginOptions } from "vue-query";
import ArquivoInput from './components/ArquivoInput.vue'

// import './assets/css/fonts.css'

  
import stockInit from 'highcharts/modules/stock'
import { assinarUrl, arquivoProtegido } from './plugins/arquivoProtegido'
//import mapInit from 'highcharts/modules/map'

roundedCorners(Highcharts)
exportingInit(Highcharts)
dataModule(Highcharts)
stockInit(Highcharts)
//mapInit(Highcharts)

Highcharts.setOptions({
  lang: {
    contextButtonTitle: 'Menu',
    downloadJPEG: 'Exportar JPG',
    downloadPDF: 'Exportar PDF',
    downloadPNG: 'Exportar PNG',
    downloadSVG: 'Exportar SVG',
    viewFullscreen: 'Tela Inteira',
    exitFullscreen: 'Sair da Tela Inteira',
    printChart: 'Imprimir',
    thousandsSep: '.',
    decimalPoint: ',',
  },
  exporting: {

    buttons: {
      contextButton: {
        menuItems: [
          "viewFullscreen",
          "print",
          "downloadPNG",
          "downloadJPEG",
          "downloadPDF",
          "downloadCSV",
          "downloadXLS",
        ],
      },
    },
  },
  navigation: {
    buttonOptions: {
      theme: {
       fill: '#f2f2f2',
        r: 0,
        states: {
          hover: {
            fill: '#eee'
          },
        }
      }
    }
  }
})

Vue.use(HighchartsVue)

Vue.config.productionTip = false

Vue.prototype.$http = http
Vue.prototype.$arquivoProtegido = arquivoProtegido
Vue.prototype.$assinarUrl = assinarUrl

Vue.prototype.$helpers = helpers
moment.locale('pt-br')
Vue.prototype.$moment =  moment
Vue.prototype.$pptxgen =  pptxgen

Vue.use(VueCompositionAPI)

const vueQueryPluginOptions =  {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        // refetchOnMount: true,
        // staleTime: 1000 * 30, // 5 minutes
        // cacheTime: 1000 * 60*10, // 10 minutes
        // refetchOnWindowFocus: false,
      },
    },
  },
};

Vue.use(VueQueryPlugin,vueQueryPluginOptions)
Vue.use(VueMask)
Vue.use(VuetifyMoney)
Vue.component('downloadCsv', JsonCSV)
Vue.component('formataReal', FormataReal)
Vue.component('MesInput', MesInput)
Vue.component('MesInputMultiple', MesInputMultiple)
Vue.component('DataInput', DataInput)
Vue.component('InfoBox', InfoBox)
Vue.component('ArquivoInput', ArquivoInput)
Vue.use(DatetimePicker)

Vue.use(VuePapaParse)
Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});


new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')


