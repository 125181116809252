<template>
	<v-container fluid class="mt-0 pt-0 d-flex h-100">
		<v-card class="px-5 py-3 br-15 w-100" elevation="0">
			<v-row>
				<v-col cols="6">
					<v-toolbar-title class="page-title"
						>Ecommerce</v-toolbar-title
					>
				</v-col>
				<v-col cols="12" sm="12" md="3" lg="2" xl="2">
					<v-btn
						class="w-100 new-button"
						color="primary"
						@click="novo()"
					>
						<v-icon left>mdi-view-grid-plus</v-icon> Adicionar
					</v-btn>
				</v-col>
				<v-col cols="12" sm="12" md="3" lg="2" xl="2">
					<v-btn
						class="w-100 new-button"
						color="primary"
						@click="exportar()"
					>
						<v-icon left>mdi-microsoft-excel</v-icon> Exportar
					</v-btn>
				</v-col>

				<v-col cols="2">
						<v-btn
							class="w-100 new-button"
							color="primary"
							@click="filtrar()"
						>
							<v-icon left>mdi-filter</v-icon> Filtrar
						</v-btn>
					</v-col>
			</v-row>

			<hr class="hr-cinza my-5" />

			<v-form ref="formFiltro">
				<v-row>
					<v-col cols="12" sm="12" md="3" lg="3" xl="3">
						<v-text-field
							v-if="lista.usuarioTipo == 'fornecedor'"
							label="Fornecedor"
                            v-model="fornecedorProvider.fornecedor"
							class="input-cinza"
							disabled
							outlined
							dense
						></v-text-field>
						<v-autocomplete
							v-if="lista.usuarioTipo == 'rd'"
							label="Fornecedor"
							class="input-cinza"
                            v-model="fornecedorProvider.id_fornecedor"
							:items="lista.fornecedor"
							:rules="regra.id_fornecedor"
							outlined
							dense
							:menu-props="menuProps"
						>
						</v-autocomplete>
					</v-col>
					<v-col cols="2" >
						<v-select
							label="Ano"
                            v-model="fornecedorProvider.ano"
							:items="lista.anos"
							:rules="regra.ano"
							class="input-cinza"
							:menu-props="menuProps"
							outlined
							dense
						>
						</v-select>
					</v-col>
					<v-col cols="3">
						<v-autocomplete
							label="Espaço"
							class="input-cinza"
							:items="lista.espaco"
							outlined
							dense
							:menu-props="menuProps"
						>
						</v-autocomplete>
					</v-col>

					<v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
						<div class="total-investido w-100">
							<div class="texto">Total Investido:</div>
							<div class="valor">
								{{ dados.valor_total || "R$ 0,00" }}
							</div>
						</div>
					</v-col>
				</v-row>
			</v-form>
			<div class="involucro">
				<v-data-table
				v-model="simulacoesSelecionadas"
					:headers="headers"
					:items="dados"
					item-key="id_simulacao_ecommerce"
					:server-items-length="total"
					:items-per-page="5"
					:footer-props="{
						'items-per-page-options': [5, 10, 15, 25, 50, 100],
						itemsPerPageText: 'Linhas por páginas',
						ofText: 'de',
						pageText: '{0}-{1} de {2}',
					}"
					:options.sync="pagination"
					multi-sort
					show-select
					:header-props="{ sortIcon: 'mdi-chevron-down' }"
					class="simulation-table table-separator flex-table"
				>
					<template v-slot:[`item.botao`]="{ item }">
						<!-- <v-icon
							v-if="item.ano >= anoProximo"
							small
							class="mr-2"
							color="error"
							@click="confirmExcluir(item)"
							:disabled="
								statusAcesso(item.id_status_simulacao, 'excluir') &&
								lista.usuarioTipo == 'fornecedor'
							"
							title="Excluir"
						>
							mdi-delete-forever
						</v-icon> -->
						<v-icon
							v-if="item.ano >= anoLimite"
							small
							class="mr-2"
							color="orange"
							@click="editarSimulacao(item)"
							title="Editar"
						>
							mdi-circle-edit-outline
						</v-icon>
					</template>
					<template v-slot:[`item.obs`]="{ item }" class="cell-editable">
						<div
							@click="
								!statusAcesso(
									item.id_status_simulacao,
									'aprovar_reprovar'
								)
									? editarTipo(item, 'obs', 'Observação')
									: false
							"
							style="cursor: pointer"
							class="cell-editable"
						>
							<div v-if="item.obs">
								<v-icon color="#ccc" class="m-0" size="15" left
									>mdi-eye</v-icon
								>
								Visualizar
							</div>
							<div v-else>Adicionar</div>
						</div>
					</template>
					<template v-slot:[`item.marca`]="{ item }">
						<div class="cell-editable">
							<div @click="viewMarca(item)">
								<v-icon color="#ccc" class="m-0" size="15" left
									>mdi-eye</v-icon
								>
								Visualizar
							</div>
						</div>
					</template>
					<template v-slot:header="{}">
						<thead class="header0">
							<tr>
								<th
									v-for="(h, i) in headersGroup0"
									:key="i"
									:colspan="h.colspan"
									:class="h.class"
									style="height: 45px"
								>
									<v-icon :color="h.color" class="mx-1" size="18">
										{{ h.icon }}
									</v-icon>
									{{ h.text }}
								</th>
							</tr>
							<tr>
								<th
									v-for="(h, i) in headersGroup"
									:key="i"
									:colspan="h.colspan"
									:class="h.class"
									style="height: 45px"
								>
									{{ h.text }}
								</th>
							</tr>
						</thead>
					</template>

					<template
						v-for="index in 12"
						v-slot:[`item.valor_${index}`]="{ item }"
					>
						<div
							:key="index"
							@click="
								!statusAcesso(
									item.id_status_simulacao,
									'aprovar_reprovar'
								)
									? editarTipo(
											item,
											'valor_' + index,
											'Valor de ' + meses[index - 1]
										)
									: false
							"
							style="cursor: pointer; width: 90%; height: 20px"
						>
							{{ item["valor_" + index] }}
						</div>
					</template>

					<template v-slot:[`body.append`]>
						<tr>
							<td class="pl-4 bg-1" :colspan="6">
								<b>Valor total:</b>
							</td>
							<td class="text-center bg-2" :colspan='lista.usuarioTipo == "rd" ? 2 : 1'>
								{{ dados.totalMes1 }}
							</td>
							<td class="text-center bg-1" :colspan='lista.usuarioTipo == "rd" ? 2 : 1'>
								{{ dados.totalMes2 }}
							</td>
							<td class="text-center bg-2" :colspan='lista.usuarioTipo == "rd" ? 2 : 1'>
								{{ dados.totalMes3 }}
							</td>
							<td class="text-center bg-1" :colspan='lista.usuarioTipo == "rd" ? 2 : 1'>
								{{ dados.totalMes4 }}
							</td>
							<td class="text-center bg-2" :colspan='lista.usuarioTipo == "rd" ? 2 : 1'>
								{{ dados.totalMes5 }}
							</td>
							<td class="text-center bg-1" :colspan='lista.usuarioTipo == "rd" ? 2 : 1'>
								{{ dados.totalMes6 }}
							</td>
							<td class="text-center bg-2" :colspan='lista.usuarioTipo == "rd" ? 2 : 1'>
								{{ dados.totalMes7 }}
							</td>
							<td class="text-center bg-1" :colspan='lista.usuarioTipo == "rd" ? 2 : 1'>
								{{ dados.totalMes8 }}
							</td>
							<td class="text-center bg-2" :colspan='lista.usuarioTipo == "rd" ? 2 : 1'>
								{{ dados.totalMes9 }}
							</td>
							<td class="text-center bg-1" :colspan='lista.usuarioTipo == "rd" ? 2 : 1'>
								{{ dados.totalMes10 }}
							</td>
							<td class="text-center bg-2" :colspan='lista.usuarioTipo == "rd" ? 2 : 1'>
								{{ dados.totalMes11 }}
							</td>
							<td class="text-center bg-1" :colspan='lista.usuarioTipo == "rd" ? 2 : 1'>
								{{ dados.totalMes12 }}
							</td>
							<td class="text-center bg-2" :colspan='lista.usuarioTipo == "rd" ? 2 : 1'>
												{{ dados.valor_total }}
										</td>
						</tr>
					</template>
					<template
						v-for="index in 12"
						v-slot:[`item.desconto_${index}`]="{ item }"
						><div :key="index">
							<v-tooltip
								bottom
								:disabled="
									!comparaVersoes(
										item['va_desconto_' + index],
										item['desconto_' + index]
									)
								"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-bind="attrs"
										v-on="on"
										:value="item['desconto_' + index]"
										:class="{
											diff: comparaVersoes(
												item['va_desconto_' + index],
												item['desconto_' + index]
											),
											'input-cinza text-center': true,
										}"
										v-mask="'###'"
										suffix="%"
										@blur="
											(e) =>
												updateValor(
													item,
													'desconto_' + index,
													e.target.value
												)
										"
										@focus="
											(e) => (valorEdicao = e.target.value)
										"
										outlined
										dense
										hide-details
									>
									</v-text-field>
								</template>
								<span class="text-center"
									>Valor anterior:
									{{ item["va_desconto_" + index] }}
								</span>
							</v-tooltip>
						</div>
					</template>
				</v-data-table>
			</div>
			<v-btn
                v-if="simulacoesSelecionadas.length > 0"
                class="new-button px-4"
                color="error"
                @click="dialogoExcluir = true"
                >Excluir simulações selecionadas</v-btn
            >
		</v-card>

		<v-dialog v-model="editar.dialogo" width="600">
			<v-card class="radius-10">
				<v-toolbar
					elevation="0"
					dark
					class="primary-degrade white--text"
				>
					<v-toolbar-title>
						{{
							editar.tipo === "obs" && editar.valor === null
								? "Adicionar"
								: "Editar"
						}}
						{{ editar.titulo }}</v-toolbar-title
					>
					<v-spacer></v-spacer>
					<v-btn
						fab
						elevation="0"
						x-small
						color="#fff"
						@click="editar.dialogo = false"
					>
						<v-icon color="primary">mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text class="px-4 py-4">
					<v-form ref="formEditar">
						<v-row no-gutters>
							<v-col
								cols="12"
								class="px-2"
								v-if="editar.tipo == 'obs'"
							>
								<v-textarea
									placeholder="Digite a observação…"
									v-model="editar.valor"
									rows="8"
									outlined
									class="input-cinza mb-n2"
									dense
								>
								</v-textarea>
							</v-col>
							<v-col
								cols="12"
								class="px-2"
								v-if="editar.tipo.split('_')[0] == 'desconto'"
							>
								<v-text-field
									:label="editar.titulo"
									v-model="editar.valor"
									:rules="regra.desconto"
									v-mask="'###'"
									outlined
									dense
								>
								</v-text-field>
							</v-col>
							<v-col
								cols="12"
								class="px-2"
								v-if="editar.tipo.split('_')[0] == 'valor'"
							>
								<vuetify-money
									outlined
									dense
									:label="editar.titulo"
									v-model="editar.valor"
									:options="options"
									type="number"
									clearable="clearable"
								></vuetify-money>
							</v-col>
						</v-row>
					</v-form>

					<div class="d-flex justify-end w-100 mt-5">
						<v-spacer></v-spacer>
						<v-btn
							v-if="editar.tipo == 'obs'"
							color="red"
							dark
							class="pr-4"
							style="font-size: 1.2rem"
							@click="
								editar.valor = null;
								editarValor();
								editar.dialogo = false;
							"
						>
							<v-icon left>mdi-close-circle</v-icon> Apagar
							observação
						</v-btn>
						<v-btn
							color="primary"
							class="pr-4"
							@click="editarValor()"
						>
							<v-icon left>mdi-content-save</v-icon> Salvar
						</v-btn>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
        <v-dialog v-model="dialogoExcluir" content-class="br-15" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        Excluir simulações selecionadas
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoExcluir = false"
                    >
                        <v-icon size="18px" color="error">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                    Deseja realmente excluir as simulações selecionadas?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        class="pr-4 mr-2 mb-2 new-button"
                        @click="dialogoExcluir = false"
                    >
                        <v-icon left>mdi-close-circle</v-icon> Não, não quero
                        excluir
                    </v-btn>
                    <v-btn
                        class="pr-4 mb-2 new-button"
                        color="primary"
                        @click="excluirMultipos"
                    >
                        <v-icon left>mdi-check-circle</v-icon> Sim, quero
                        excluir
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
		<v-dialog v-model="dialogo" width="90%">
			<v-card class="radius-10">
				<v-toolbar
					elevation="0"
					dark
					class="primary-degrade white--text"
				>
					<v-toolbar-title class="px-1"
						>{{
							itemEdicao ? "Editar simulação" : "Nova simulação"
						}}
					</v-toolbar-title>
					<v-spacer></v-spacer>

					<v-btn
						fab
						elevation="0"
						x-small
						color="#fff"
						@click="dialogo = false"
					>
						<v-icon color="primary">mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text class="px-5 py-5">
					<v-form v-model="validaForm">
						<v-row>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="3"
								xl="3"
								class="pb-0"
							>
								<v-autocomplete
									outlined
									dense
									:rules="regra.plano"
									label="Plano"
									:items="listagem.plano"
									v-model="simulacao.plano"
									:menu-props="menuProps"
									class="input-cinza"
								></v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="3"
								xl="3"
								class="pb-0"
							>
								<v-autocomplete
									:items="lista.bandeira"
									outlined
									multiple
									dense
									label="Bandeira"
									:rules="RulesBadeira"
									v-model="simulacao.bandeira"
									:menu-props="menuProps"
									class="input-cinza"
								></v-autocomplete>
							</v-col>

							<!-- <v-col
								cols="12"
								sm="12"
								md="6"
								lg="3"
								xl="3"
								class="pb-0"
							>
								<v-autocomplete
									label="Espaço"
									v-model=""
									:rules=""
									class="input-cinza"
									outlined
									dense
								>
								</v-autocomplete>
							</v-col> -->
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="3"
								xl="3"
								class="pb-0"
							>
								<v-select
									label="Ano"
									v-model="fornecedorProvider.ano"
									:items="lista.anos"
									:rules="regra.ano"
									class="input-cinza"
									:menu-props="menuProps"
									outlined
									dense
								>
								</v-select>
							</v-col>

							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="3"
								xl="3"
								class="pb-0"
							>
								<v-select
									label="Período da ação"
									v-model="simulacao.meses"
									:items="lista.meses"
									class="input-cinza"
									:menu-props="menuProps"
									multiple
									outlined
									dense
								>
									<template v-slot:prepend-item>
										<v-list-item
											ripple
											@mousedown.prevent
											@click="selecionarTodosMeses"
										>
											<v-list-item-action>
												<v-icon>
													{{
														simulacao.meses
															.length >= 12
															? "mdi-close-box"
															: simulacao.meses
																	.length ===
															  0
															? "mdi-checkbox-blank-outline"
															: "mdi-minus-box"
													}}
												</v-icon>
											</v-list-item-action>
											<v-list-item-content>
												<v-list-item-title>
													{{
														simulacao.meses
															.length >= 12
															? "Desmarcar"
															: "Selecionar"
													}}
													todos os meses
												</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
										<v-divider class="mt-2"></v-divider>
									</template>
									<template
										v-slot:selection="{ item, index }"
									>
										<span
											v-if="
												simulacao.meses.length === 1 &&
													index === 0
											"
											>{{ item.nome }}</span
										>
										<span
											v-if="
												simulacao.meses.length > 1 &&
													index === 0
											"
											class="grey--text caption mr-1"
											>{{
												simulacao.meses.length
											}}
											selecionados</span
										>
									</template>
								</v-select>
							</v-col>
						</v-row>

						<div>
							<div class="d-flex align-center mt-n5 mb-1">
								<h2 style="font-weight: 500">
									Personalizar período
								</h2>
								<v-divider class="mx-5" />
								<div class="d-flex align-center">
									<v-checkbox
										color="primary"
										v-model="replicarValores"
									></v-checkbox>
									<span
										>Replicar informações para todos os
										meses</span
									>
								</div>
							</div>

							<v-row>
								<v-col
									cols="12"
									sm="12"
									md="6"
									lg="3"
									xl="3"
									class="py-1"
									v-for="(mes, i) in Object.keys(valores)"
									:key="i"
								>
									<MesBox
										:esconderLojas="false"
										:esconderDesconto="
											lista.usuarioTipo !== 'rd'
										"
										:mes="mes"
										:replicarValores="replicarValores"
										:active="
											simulacao.meses.includes(i + 1)
										"
										:icon="
											4 <= i && i <= 8
												? 'inverno'
												: 'verao'
										"
										v-model="valores"
									/>
								</v-col>
							</v-row>
						</div>
						<!-- fim de meses -->
					</v-form>
					<div class="d-flex justify-end w-100">
						<v-btn
							color="primary"
							class="pr-4"
							:disabled="!validaForm"
							@click="confirmCriar()"
						>
							<v-icon left>mdi-content-save</v-icon> Salvar
						</v-btn>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogoEditar" width="90%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Editar simulação
				</v-card-title>
				<v-card-text class="mt-4">
					<v-form v-model="validaForm">
						<v-row no-gutters class="mt-4">
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-autocomplete
									outlined
									dense
									label="Plano"
									:items="listagem.plano"
									v-model="simulacao.plano"
									:rules="regra.plano"
									:menu-props="menuProps"
								></v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-autocomplete
									:items="listagem.bandeira"
									outlined
									dense
									label="Bandeira"
									v-model="simulacao.bandeira"
									:menu-props="menuProps"
								></v-autocomplete>
							</v-col>

							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									v-if="lista.usuarioTipo == 'fornecedor'"
									label="Fornecedor"
									v-model="simulacao.fornecedor"
									disabled
									outlined
									dense
								></v-text-field>
								<v-autocomplete
									v-if="lista.usuarioTipo == 'rd'"
									label="Fornecedor"
									v-model="simulacao.id_fornecedor"
									:items="lista.fornecedor"
									:rules="regra.id_fornecedor"
									:menu-props="menuProps"
									outlined
									dense
								>
								</v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-select
									label="Ano"
									v-model="fornecedorProvider.ano"
									:items="lista.anos"
									:rules="regra.ano"
									:menu-props="menuProps"
									outlined
									dense
								>
								</v-select>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="pr-4"
						@click="dialogoEditar = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
					<v-btn
						color="primary"
						class="pr-4"
						:disabled="!validaForm"
						@click="editarValor()"
					>
						<v-icon left>mdi-content-save</v-icon> Salvar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="confirm.dialogo" width="600">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					{{ confirm.titulo }}
				</v-card-title>
				<v-card-text class="mt-4">
					{{ confirm.texto }}
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="pr-4"
						@click="confirm.dialogo = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
					<v-btn
						:color="confirm.color"
						class="pr-4"
						@click="confirm.funcao"
					>
						<v-icon left>mdi-close</v-icon> {{ confirm.botao }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-overlay :value="carregando" :z-index="300">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<v-snackbar
			v-model="alerta"
			:color="alertaColor"
			:timeout="alertaTimeout"
			top
			style="z-index: 400"
		>
			<v-alert :type="alertaColor">
				{{ alertaTexto }}
			</v-alert>
			<v-btn dark text class="float-right" @click="alerta = false">
				Fechar
			</v-btn>
		</v-snackbar>
	</v-container>
</template>

<script>
import MesBox from "./_components/mes_box.vue";

export default {
	components: {
		MesBox,
	},
	inject: ["fornecedorProvider"],
	data() {
		return {
			lista: {
				macrofornecedor: [],
				meses: [],
			},
			menuProps: {
				bottom: true,
				offsetY: true,
				contentClass: "select-outline",
				transition: "slide-y-transition",
			},
			headersGroup0: [{ text: null, colspan: 6, class: "" }],
			itemEdicao: null,
			replicarValores: false,
			simulacoesSelecionadas: [],
			dialogoExcluir: false,
			headersGroup: [{ text: null, colspan: 6, class: "" }],
			valores: {
				janeiro: {},
				fevereiro: {},
				março: {},
				abril: {},
				maio: {},
				junho: {},
				julho: {},
				agosto: {},
				setembro: {},
				outubro: {},
				novembro: {},
				dezembro: {},
			},
			meses: [
				"Janeiro",
				"Fevereiro",
				"Março",
				"Abril",
				"Maio",
				"Junho",
				"Julho",
				"Agosto",
				"Setembro",
				"Outubro",
				"Novembro",
				"Dezembro",
			],
			headers: [
				{ text: "", value: "botao", align: "center", sortable: false },
				{
					text: "Status",
					value: "status",
					align: "center",
					sortable: false,
					class: "th-separador min-width-150",
				},
				{
					text: "Plano",
					value: "plano",
					align: "center",
					class: "th-separador min-width-150",
				},
				{
					text: "Bandeira",
					value: "bandeira",
					align: "center",
					class: "th-separador min-width-150",
				},
				/* {
					text: "Espaço",
					value: "espaço",
					align: "center",
					class: "th-separador min-width-150",
				}, */
				{
					text: "Observação",
					value: "obs",
					align: "center",
					class: " min-width-150",
				},
			],
			dados: [],
			mes: null,
			anoLimite: 2020,
			simulacao: {
				meses: [],
				id_simulacao_ecommerce: null,
				plano: null,
				bandeira: [],
				mes: null,
				valor: null,
				id_fornecedor: this.fornecedorProvider.id_fornecedor,
                ano: this.fornecedorProvider.ano,
			},
			listagem: {
				bandeira: [],
				plano: ["Anual", "Avulso"],
			},
			pagination: {},
			paginationErros: {},
			total: null,
			carregando: false,
			busca: null,
			dialogo: false,
			dialogoEditar: false,
			dialogoImportar: false,
			validaForm: false,
			alerta: false,
			alertaTexto: "",
			alertaColor: "error",
			alertaTimeout: 3000,
			arquivoCSV: null,
			options: {
				locale: "pt-BR",
				prefix: "R$",
				suffix: "",
				length: 11,
				precision: 2,
			},

			tipocargo: null,
			regra: {
				desconto: [
					(v) =>
						(!!v && v >= 0 && v <= 100) ||
						"O desconto deve ser um numero inteiro ente 0 e 100.",
				],
				csv: [
					(v) => !!v || "Selecione um arquivo.",
					(v) =>
						(!!v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
						"O arquivo deve ser do tipo CSV.",
				],
				ano: [(v) => !!v || "O campo ano é obrigatório."],
				id_fornecedor: [
					(v) => !!v || "O campo fornecedor é obrigatório.",
				],
				plano: [(v) => !!v || "O campo Plano é obrigatório."],
				valor: [(v) => !!v || "O campo Valor não pode vazio."],
			},
			isCsv: false,
			headerErros: [
				{ text: "Descrição do Erro", value: "msg", align: "left" },
			],
			erros: [],
			errosLabels: { msg: "Descrição do Erro" },
			erro: false,
			confirm: {
				dialogo: false,
				titulo: null,
				texto: null,
				funcao: null,
				color: null,
				botao: null,
			},
			obj: {},
			obs: {
				dialogo: false,
				valor: null,
			},
			editar: {
				dialogo: false,
				valor: null,
				titulo: null,
				tipo: "",
			},
			item: {},
			status: [],
			item_Reprovados_Aprovados: [],
			selected: [],
			totalInvestido: null,
		};
	},
	watch: {
		pagination: {
			handler() {
				if (this.lista.fornecedor) this.filtrar();
			},
			deep: true,
		},
		fornecedorProvider: {
            handler(val) {
                this.simulacao.id_fornecedor = val.id_fornecedor;
                this.simulacao.ano = val.ano;
            },
            deep: true,
        },

		"simulacao.plano": {
			handler(v) {
				if (v == "Anual") {
					this.lista.bandeira = this.listagem.bandeira;
				} else {
					this.lista.bandeira = this.listagem.bandeira;
				}
			},
			deep: true,
		},
		selected(v) {
			this.item_Reprovados_Aprovados = [...v];
		},
	},

	async created() {
		this.carregando = true;

		// this.simulacao.ano = this.anoProximo;

		var response = await this.$http().post("/simulacao/ecommerce/filtros", {
			data: {},
		});

		this.lista = response.data;
		this.lista.meses = [];
		this.listagem.bandeira = this.lista.bandeira;

		if (this.lista.usuarioTipo == "fornecedor") {
			this.simulacao.id_fornecedor = this.lista.fornecedor[0].id_fornecedor;
			this.simulacao.fornecedor = this.lista.fornecedor[0].fornecedor;
		}

        var colspan = this.lista.usuarioTipo == "rd" ? 2 : 1;

		var classe = "dark";
		this.headersGroup0.push({
			text: "Verão",
			colspan: 3 * colspan,
			icon: "mdi-white-balance-sunny",
			class: "v-data-table-header text-center verao",
			color: "#c28f5b",
		});
		this.headersGroup0.push({
			text: "Inverno",
			colspan: 5 * colspan,
			icon: "mdi-snowflake",
			class: "v-data-table-header text-center inverno",
			color: "#1e7199",
		});
		this.headersGroup0.push({
			text: "Verão",
			colspan: 4 * colspan,
			icon: "mdi-white-balance-sunny",
			class: "v-data-table-header text-center verao",
			color: "#c28f5b",
		});
		for (let m = 1; m <= 12; m++) {
			const cellBg = `${
				4 <= m && m <= 8 ? "inverno" : "verao"
			}-${classe}`;
			this.headersGroup.push({
				text: this.meses[m - 1],
				colspan: colspan,
				class: "v-data-table-header min-width-100 text-center " + cellBg,
			});
			if (this.lista.usuarioTipo == "rd") {

				this.headers.push({
					text: "Desconto",
					value: "desconto_" + m,
					align: "center",
					class: cellBg + " min-width-150 th-separador",
				});
			}

			this.headers.push({
				text: "R$",
				value: "valor_" + m,
				align: "center",
				class: cellBg + " min-width-150",
			});

			classe = classe == "dark" ? "light" : "dark";
		}
		this.headers.push({
			text: "Total",
			colspan: 2,
			value: "valor_total",
			align: "center",
			class: "v-data-table-header text-center  min-width-150  " + classe,
		});

		for (let m = 1; m <= 12; m++) {
			this.lista.meses.push({
				value: m,
				text:
					this.meses[m - 1] +
					" - " +
					(4 <= m && m <= 8 ? "Inverno" : "Verão"),
				nome: this.meses[m - 1],
			});
		}

		this.filtrar();
	},

	computed: {
		anoProximo() {
			return new Date().getFullYear();
		},
		mesProximo() {
			return this.$moment()
				.add(1, "months")
				.format("YYYY-MM");
		},
		cargo() {
			return this.$store.state.auth.cargo;
		},
		RulesBadeira() {
			if (this.simulacao.plano == "Avulso") {
				return [
					(v) =>
						!!v ||
						"O campo Bandeira pelo plano escolhido não pode vazio.",
				];
			} else {
				return [];
			}
		},
	},
	methods: {
		comparaVersoes(anterior, atual) {
			if (anterior === null) return false;
			return anterior != atual;
		},
		statusAcesso(id_status_simulacao, acesso) {
			if (this.lista.status && id_status_simulacao) {
				var status = this.lista.status.find(
					(e) => e.id_status_simulacao == id_status_simulacao
				);

				if (
					id_status_simulacao == 2 &&
					this.lista.usuarioTipo == "rd"
				) {
					return false;
				} else if (
					id_status_simulacao == 3 &&
					this.lista.usuarioTipo == "rd"
				) {
					return false;
				} else {
					return status[acesso];
				}
			} else {
				return false;
			}
		},
		selecionarTodosMeses() {
			if (this.simulacao.meses.length < 12) {
				this.simulacao.meses = Array.from(Array(12).keys()).map(
					(i) => i + 1
				);
			} else {
				this.simulacao.meses = [];
			}
		},
		confirmExcluir(item) {
			this.obj = item;

			this.confirm.titulo = "Excluir Linha";
			this.confirm.texto =
				"Deseja realmente excluir a linha da simulação?";
			this.confirm.color = "error";
			this.confirm.botao = "Excluir";
			this.confirm.funcao = this.excluir;
			this.confirm.dialogo = true;
		},
		async excluir() {
			this.carregando = true;

			this.confirm.dialogo = false;

			var data = this.obj;

			var response = await this.$http().post(
				"/simulacao/ecommerce/excluir",
				{
					data: data,
				}
			);

			this.carregando = false;

			if (response.data.sucesso) {
				this.filtrar();

				this.dialogo = false;
			} else {
				this.erros = response.data.erros;
				this.pagination.page = 1;
				this.erro = true;
			}
		},
        async excluirMultipos() {
            this.carregando = true;

            var data = {};
            data.id_simulacao_ecommerce = this.simulacoesSelecionadas.map(
                (item) => item.id_simulacao_ecommerce
            );

            await this.$http().post("/simulacao/ecommerce/excluir", { data: data });

            this.carregando = false;
            this.dialogoExcluir = false;
            this.filtrar();
        },
		async filtrar() {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var data = {};

			data.simulacao = this.simulacao;
			data.pagination = this.pagination;

			if (this.simulacao.id_fornecedor) {
				data.id_fornecedor = this.simulacao.id_fornecedor;
			}

			if (this.simulacao.ano) {
				data.ano = this.simulacao.ano;
			}

			var response = await this.$http().post(
				"/simulacao/ecommerce/lista",
				{
					data: data,
				}
			);

			this.dados = response.data;

			this.total = response.data.length ? response.data[0].total : 0;

			const mes1 = response.data
				.map((x) =>
					parseInt(
						x.valor_1.replace(/[^0-9,]*/g, "").replace(",", ".")
					)
				)
				.reduce((a, b) => a + b, 0);
			const mes2 = response.data
				.map((x) =>
					parseInt(
						x.valor_2.replace(/[^0-9,]*/g, "").replace(",", ".")
					)
				)
				.reduce((a, b) => a + b, 0);
			const mes3 = response.data
				.map((x) =>
					parseInt(
						x.valor_3.replace(/[^0-9,]*/g, "").replace(",", ".")
					)
				)
				.reduce((a, b) => a + b, 0);
			const mes4 = response.data
				.map((x) =>
					parseInt(
						x.valor_4.replace(/[^0-9,]*/g, "").replace(",", ".")
					)
				)
				.reduce((a, b) => a + b, 0);
			const mes5 = response.data
				.map((x) =>
					parseInt(
						x.valor_5.replace(/[^0-9,]*/g, "").replace(",", ".")
					)
				)
				.reduce((a, b) => a + b, 0);
			const mes6 = response.data
				.map((x) =>
					parseInt(
						x.valor_6.replace(/[^0-9,]*/g, "").replace(",", ".")
					)
				)
				.reduce((a, b) => a + b, 0);
			const mes7 = response.data
				.map((x) =>
					parseInt(
						x.valor_7.replace(/[^0-9,]*/g, "").replace(",", ".")
					)
				)
				.reduce((a, b) => a + b, 0);
			const mes8 = response.data
				.map((x) =>
					parseInt(
						x.valor_8.replace(/[^0-9,]*/g, "").replace(",", ".")
					)
				)
				.reduce((a, b) => a + b, 0);
			const mes9 = response.data
				.map((x) =>
					parseInt(
						x.valor_9.replace(/[^0-9,]*/g, "").replace(",", ".")
					)
				)
				.reduce((a, b) => a + b, 0);
			const mes10 = response.data
				.map((x) =>
					parseInt(
						x.valor_10.replace(/[^0-9,]*/g, "").replace(",", ".")
					)
				)
				.reduce((a, b) => a + b, 0);
			const mes11 = response.data
				.map((x) =>
					parseInt(
						x.valor_11.replace(/[^0-9,]*/g, "").replace(",", ".")
					)
				)
				.reduce((a, b) => a + b, 0);
			const mes12 = response.data
				.map((x) =>
					parseInt(
						x.valor_12.replace(/[^0-9,]*/g, "").replace(",", ".")
					)
				)
				.reduce((a, b) => a + b, 0);
			const valor_total = response.data
				.map((x) =>
					parseInt(
						x.valor_total.replace(/[^0-9,]*/g, "").replace(",", ".")
					)
				)
				.reduce((a, b) => a + b, 0);

			this.dados.totalMes1 = mes1.toLocaleString("pt-br", {
				style: "currency",
				currency: "BRL",
			});
			this.dados.totalMes2 = mes2.toLocaleString("pt-br", {
				style: "currency",
				currency: "BRL",
			});
			this.dados.totalMes3 = mes3.toLocaleString("pt-br", {
				style: "currency",
				currency: "BRL",
			});
			this.dados.totalMes4 = mes4.toLocaleString("pt-br", {
				style: "currency",
				currency: "BRL",
			});
			this.dados.totalMes5 = mes5.toLocaleString("pt-br", {
				style: "currency",
				currency: "BRL",
			});
			this.dados.totalMes6 = mes6.toLocaleString("pt-br", {
				style: "currency",
				currency: "BRL",
			});
			this.dados.totalMes7 = mes7.toLocaleString("pt-br", {
				style: "currency",
				currency: "BRL",
			});
			this.dados.totalMes8 = mes8.toLocaleString("pt-br", {
				style: "currency",
				currency: "BRL",
			});
			this.dados.totalMes9 = mes9.toLocaleString("pt-br", {
				style: "currency",
				currency: "BRL",
			});
			this.dados.totalMes10 = mes10.toLocaleString("pt-br", {
				style: "currency",
				currency: "BRL",
			});
			this.dados.totalMes11 = mes11.toLocaleString("pt-br", {
				style: "currency",
				currency: "BRL",
			});
			this.dados.totalMes12 = mes12.toLocaleString("pt-br", {
				style: "currency",
				currency: "BRL",
			});
			this.dados.valor_total = valor_total.toLocaleString("pt-br", {
				style: "currency",
				currency: "BRL",
			});

			if (this.total == 0 && this.pagination.page > 1) {
				this.pagination.page = 1;
				this.filtrar();
			}

			this.carregando = false;
		},
		async exportar() {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var data = {};

			data.simulacao = this.simulacao;

			var response = await this.$http().post(
				"/simulacao/ecommerce/exportar",
				{
					data: data,
				}
			);

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		async novo() {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;
			var data = {
				id_fornecedor: this.simulacao.id_fornecedor,
				ano: this.simulacao.ano,
				usuarioTipo: this.lista.usuarioTipo,
			};
			this.simulacao.meses = []
			this.simulacao.plano = null;
			this.simulacao.bandeira = null;
			this.replicarValores = false;
			this.dialogo = true;

			for (let i = 1; i <= 12; i++) {
				const mes = this.meses[i - 1].toLowerCase();
				this.valores[mes]["valor"] = 0;
				this.valores[mes]["desconto"] = 0;
			}
			await this.carregarDescontos();
			this.carregando = false;
		},
		async editarSimulacao(item) {
            this.itemEdicao = item;
            this.simulacao.meses = [];
            this.carregando = true;
			this.simulacao.plano = item.plano;
            this.simulacao.bandeira = item.bandeira
            this.replicarValores = false;

            var data = {
                id_fornecedor: this.simulacao.id_fornecedor,
                ano: this.simulacao.ano,
                usuarioTipo: this.lista.usuarioTipo,
            };
            this.dialogo = true;
            this.carregando = false;
            // Habilita os meses para edição
			console.log(this.itemEdicao)
            for (let i = 1; i <= 12; i++) {
                const valor = (this.itemEdicao[`valor_${i}`] || 0).replace(/[^0-9,]*/g, '').replace(',', '.') ;
				console.log(valor)
				const desconto = this.itemEdicao[`desconto_${i}`];
				if(valor > 0 ){
					this.simulacao.meses.push(i);
				}

                const mes = this.meses[i - 1].toLowerCase();
                this.valores[mes]["valor"] = valor;
                this.valores[mes]["desconto"] = desconto;
            }
        },
		async confirmCriar() {
			if (!this.validaForm) return;
			const _response = await this.criar(true);

			if (!_response.data.sucesso) {
				this.$dialog.notify.error(
					"Falha ao calcular a simulação, por favor, tente novamente.",
					{
						position: "top-right",
						timeout: 5000,
					}
				);
				return;
			}

			let _valor = this.$helpers.roundDecimal(
				_response.data.result.total,
				2
			);
			const _text = `
				Deseja salvar a simulação no valor total de R$ ${_valor.toLocaleString()} de investimento?  
			`;

			const _dialogConfirm = await this.$dialog.confirm({
				text: _text,
				title: "Salvar simulação",
				actions: [
					{ text: "NÃO", color: "red" },
					{ text: "SIM", color: "teal" },
				],

				color: "teal lighten-2",
			});

			const _confirm = await _dialogConfirm.wait();

			if (_confirm == "SIM") this.criar();
		},
		async criar(flag_simulacao = false) {
			this.carregando = true;

			const data = this.simulacao;
			//       data["valoresMeses"] = this.simulacao.meses.map((item) => {
			//   const mes = this.meses[item - 1].toLowerCase();

			//   return {
			//     mes: item,
			//     valor: this.valores[mes]["valor"],
			//     desconto: this.valores[mes]["desconto"],
			//   };
			// });
			if (this.itemEdicao) {
                data["id_simulacao_ecommerce"] =
                    this.itemEdicao.id_simulacao_ecommerce;
            }
			this.simulacao.meses.forEach((item) => {
				const mes = this.meses[item - 1].toLowerCase();

				(data[`valor_${item}`] = this.valores[mes]["valor"]),
					(data[`desconto_${item}`] = this.valores[mes]["desconto"]);
			});

			data["flag_simulacao"] = flag_simulacao;

			var response = await this.$http().post(
				"/simulacao/ecommerce/criar",
				{
					data,
				}
			);

			this.carregando = false;

			if (flag_simulacao) return response;

			if (response.data.sucesso) {
				this.filtrar();

				(this.simulacao = {
					...this.simulacao,
					plano: null,
					bandeira: [],
					mes: null,
					valor_1: 0,
					valor_2: 0,
					valor_3: 0,
					valor_4: 0,
					valor_5: 0,
					valor_6: 0,
					valor_7: 0,
					valor_8: 0,
					valor_9: 0,
					valor_10: 0,
					valor_11: 0,
					valor_12: 0,
				}),
					(this.dialogo = false);
			} else {
				this.erros = response.data.erros;
				this.pagination.page = 1;
				this.erro = true;
			}
		},
		abrirEditar(item) {
			this.simulacao = { ...item };
			this.dialogoEditar = true;
		},

		editarTipo(item, tipo, titulo) {
			this.editar.dialogo = true;

			setTimeout(() => {
				this.editar.tipo = tipo;
				this.editar.titulo = titulo;

				setTimeout(() => {
					if (this.editar.tipo.split("_")[0] == "valor") {
						this.editar.valor = parseFloat(
							item[tipo]
								.replace(/[^0-9,]*/g, "")
								.replace(",", ".")
						);
					}
				}, 100);
			}, 100);

			this.item = { ...item };
		},
		async carregarDescontos() {
			const response = await this.$http().post(
				"/simulacao/pdv/listarDesconto",
				{
					data: {
						id_fornecedor: this.simulacao.id_fornecedor || 1,
						ano: this.simulacao.ano || 2022,
					},
				}
			);
			response.data.map((item) => {
				const mes = this.meses[item.mes - 1].toLowerCase();
				this.valores[mes]["desconto"] = item.desconto;
			});
		},
		async updateValor(item, campo, valor) {
			if (this.valorEdicao == valor) {
				return;
			}

			const [tipo, mes] = campo.split("_");
			if (tipo == "desconto" && valor > 100) {
				this.mostrarAlerta({
					texto:
						"O desconto deve ser um numero inteiro ente 0 e 100.",
					color: "error",
					timeout: 3000,
				});
				item[campo] = this.valorEdicao;
				return;
			}

			var data = {
				id_simulacao_ecommerce: item.id_simulacao_ecommerce,
				tipo: campo,
				obj: {},
			};

			data.obj[campo] = valor;

			var response = await this.$http().post(
				"/simulacao/ecommerce/editar",
				{
					data: data,
				}
			);

			this.carregando = false;

			if (response.data.sucesso) {
				this.filtrar();
				//   this.mostrarAlerta({
				//   texto:
				//     "Valores atualizados com sucesso.",
				//   color: "success",
				//   timeout: 3000,
				// });
				// this.editar.dialogo = false;
			} else {
				item[campo] = this.valorEdicao;
				this.alerta = true;
				this.alertaColor = "error";
				this.alertaTimeout = -1;
				this.alertaTexto = response.data.msg;
			}
		},
		async editarValor() {
			this.carregando = true;

			var data = {
				id_simulacao_ecommerce: this.item.id_simulacao_ecommerce,
				tipo: this.editar.tipo,
				obj: {},
			};
			switch (this.editar.tipo.split("_")[0]) {
				case "valor":
					data.obj[this.editar.tipo] = parseFloat(this.editar.valor);
					break;
				default:
					data.obj[this.editar.tipo] = this.editar.valor;
			}

			var response = await this.$http().post(
				"/simulacao/ecommerce/editar",
				{
					data: data,
				}
			);

			this.carregando = false;

			if (response.data.sucesso) {
				(this.simulacao = {
					...this.simulacao,
					plano: null,
					bandeira: null,
					mes: null,
					valor_1: 0,
					valor_2: 0,
					valor_3: 0,
					valor_4: 0,
					valor_5: 0,
					valor_6: 0,
					valor_7: 0,
					valor_8: 0,
					valor_9: 0,
					valor_10: 0,
					valor_11: 0,
					valor_12: 0,
				}),
					this.filtrar();
				this.editar.dialogo = false;
			} else {
				this.alerta = true;
				this.alertaColor = "error";
				this.alertaTimeout = -1;
				this.alertaTexto = response.data.msg;
			}
		},
	},
};
</script>
<style>
.involucro {
    height: 650px;
    display: flex;
  margin-top: 20px;
  /* flex-grow: 1; */
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
}

.involucro .flex-table {
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  height: 620px;
  width: 100%;
}

.involucro .flex-table > div {
  width: 100%;
}

.involucro thead {
	position: -webkit-sticky;
	position: sticky;
	overflow: hidden;
	z-index: 1;
	/* background-color: #ffff; */
}

.involucro .header0 {
	top: 0px;
}

.involucro .flex-table .v-data-table-header {
	top: 90px;
} 

.involucro .flex-table .v-data-table__wrapper {
	overflow-y: auto !important;
} 


</style>

