var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('v-badge',{attrs:{"bordered":"","color":"info","content":1,"value":_vm.ultimoHistorico.processo == 'Primeira Aprovação',"overlap":"","title":_vm.ultimoHistorico.nome + ' - ' + _vm.ultimoHistorico.atualizacao}},[_c('v-btn',{staticClass:"ml-3 my-1",attrs:{"color":"primary","disabled":_vm.lista.pendente.length == 0 &&
				(_vm.ultimoHistorico.processo == 'Importação' ||
					(_vm.ultimoHistorico.processo == 'Primeira Aprovação' &&
						_vm.ultimoHistorico.id_usuario !=
							this.$store.getters.getUsuario.id_usuario))
					? false
					: true},on:{"click":function($event){return _vm.showAprovacao()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Aprovar ")],1)],1),_c('Modal',{attrs:{"title":_vm.tileModal,"fullscreen":false,"maxWidth":'98%',"hideClose":true,"load":_vm.load},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(_vm.showModal)?_c('template',{slot:"body"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b',[_vm._v(" Analise o cenário consolidado abaixo ( a partir do mês "+_vm._s(this.ultimoHistorico.ano_mes)+"), que será executado após as duas aprovações, e clique no botão aprovar se estiver de acordo: ")])])],1),_c('v-row',_vm._l((_vm.cards),function(item){return _c('v-col',{key:item.key_value,attrs:{"cols":"3"}},[_c('v-alert',{staticStyle:{"cursor":"pointer"},attrs:{"color":item.color,"border":"left","elevation":"1","colored-border":"","align":"center"},on:{"click":function($event){return _vm.openDetails(item)}}},[_c('h2',{class:item.color + '--text'},[_vm._v(" "+_vm._s(_vm.consolidado[item.key_value])+" "+_vm._s(item.title)+" ")]),_c('h4',[_vm._v(_vm._s(item.subtitle))])])],1)}),1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"end"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"red","dark":""},on:{"click":function($event){_vm.showModal = false}}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.aprovar()}}},[_vm._v(" Aprovar importação ")])],1)],1)],1)],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }