<template>
    <v-form ref="form">
        {{ filtro.cluster }}
        <v-row class="mt-4">
            <v-col cols="4" class="py-0">
                <v-text-field
                    v-model="buscaFilial"
                    class="input-button input-cinza"
                    label="Pesquisar"
                    outlined
                    dense
                    hide-details
                >
                    <template>
                        <v-icon slot="append" color="white">mdi-magnify</v-icon>
                    </template>
                </v-text-field>
            </v-col>
            <v-col
                cols="4"
                class="py-0"
                v-if="disabledOrganicos && (acordo.idAcordos || []).length > 0"
            >
                <b
                    >Clusters da criação deste acordo ({{
                        acordo.idAcordos[0]
                    }}): </b
                >{{ clustersAcordoName }}
            </v-col>
            <v-col cols="4" class="py-0">
                <v-select
                    label="Cluster"
                    outlined
                    dense
                    multiple
                    class="input-cinza"
                    v-model="filtro.id_cluster"
                    :items="listaCluster"
                    item-value="value"
                    item-text="text_concat"
                    :menu-props="menuProps"
                >
                    <template v-slot:selection="{ item, index }">
                        <span
                            v-if="filtro.id_cluster.length === 1 && index === 0"
                            >{{ item.text }}</span
                        >
                        <span
                            v-if="filtro.id_cluster.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                            >{{ filtro.id_cluster.length }} selecionados</span
                        >
                    </template>
                </v-select>
            </v-col>
            <v-col cols="4" align="end" class="py-0">
                <v-btn
                    class="new-button px-5"
                    color="yellow"
                    dark
                    @click="limpar()"
                >
                    <v-icon left>mdi-filter-off</v-icon> Limpar
                </v-btn>
                <v-btn
                    class="ml-4 new-button px-5"
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>mdi-filter</v-icon> Filtrar
                </v-btn>
            </v-col>
        </v-row>
        <!-- <v-row>
			<v-col cols="4"><FilialLegendas /> </v-col>
		</v-row> -->

        <v-data-table
            :headers="listaHeader"
            :items="listaLojas"
            :search="buscaFilial"
            item-key="id_filial"
            :items-per-page="5"
            :footer-props="{
                'items-per-page-options': [5, 10, 50, 100, 500, -1],
                itemsPerPageText: 'Linhas por páginas',
                ofText: 'de',
                pageText: '{0}-{1} de {2}',
            }"
            :options.sync="pagination"
            class="tabela-filial"
        >
            <template v-slot:header="{}">
                <thead>
                    <tr>
                        <th colspan="2">
                            <!-- <div class="d-flex align-center">
                                <p class="text-filtro mr-3">
                                    Filtrar somente lojas com oportunidade
                                </p>
                                <v-switch
                                    v-model="filtro.filter_lojas_op"
                                    label=""
                                    class="mt-0"
                                />
                            </div> -->
                        </th>

                        <th colspan="3">
                            <FilialLegendas :organicos="disabledOrganicos" />
                        </th>

                        <th
                            v-for="p in getPeriodo()"
                            :key="p"
                            class="v-data-table-header text-center th-separador top-header"
                        >
                            <div
                                style="
                                    width: 24px;
                                    margin-left: auto;
                                    margin-right: auto;
                                    display: block;
                                "
                                class="text-center"
                            >
                                <v-checkbox
                                    :key="p"
                                    v-model="todasFiliais[p]"
                                    @click="selecionaTudo(p, acordo.fnEditar)"
                                    color="#fff"
                                    dark
                                    class="ma-0 pa-0"
                                    :disabled="disabledOrganicos"
                                    hide-details
                                />
                            </div>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:[`item.oportunidade_loja`]="{ item }">
                <v-chip
                    class="ma-2"
                    :color="item.oportunidade_loja ? 'green' : 'red'"
                    label
                    text-color="white"
                >
                    {{ item.oportunidade_loja ? "SIM" : "NÃO" }}
                </v-chip>
            </template>
            <template
                v-for="periodo in listaPeriodo"
                v-slot:[`item.quantidade${periodo}`]="{ item }"
                class="th-separador"
            >
                <v-simple-checkbox
                    :key="periodo"
                    :ripple="false"
                    :disabled="item['oportunidade' + periodo] == false"
                    :value="
                        item['quantidade' + periodo] ||
                        item['disponivel' + periodo]
                    "
                    :off-icon="iconOff(periodo, item).icon"
                    :title="iconOff(periodo, item).title"
                    :color="item['presente_acordo' + periodo] ? 'primary' : ''"
                    @input="checkFilial(item, `quantidade${periodo}`, $event)"
                    @click="alteraFilial(item)"
                />
            </template>
        </v-data-table>

        <v-row no-gutters class="mb-3 mt-1">
            <v-col cols="12" class="px-2 ml-auto" align="end">
                <v-btn
                    class="new-button px-5 mx-2"
                    color="error"
                    :disabled="disabledOrganicos"
                    @click="limpaSelecionadas(true)"
                >
                    <v-icon left>mdi-trash-can</v-icon> Limpar Filiais
                </v-btn>
                <v-btn
                    class="new-button px-5 mx-2"
                    color="primary"
                    @click="carregarSelecionadas()"
                >
                    <v-icon left>mdi-arrow-up</v-icon> Carregar Filiais Salvas
                </v-btn>
                <v-btn
                    class="new-button px-5 ml-2"
                    color="primary"
                    @click="salvarSelecionadas()"
                >
                    <v-icon left>mdi-arrow-down</v-icon> Salvar Filiais
                    Selecionadas
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :headers="listaHeaderFiliais"
            :items="somaFiliais"
            item-key="nome"
            class="elevation-0 tabela"
            hide-default-footer
            disable-sort
        >
        </v-data-table>
        <div v-if="dialogoCamposFaltando" class="dialogo-erro" width="700px">
            <v-card class="br-15">
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        Por favor retorne as etapas anteriores e preencha os
                        seguintes campos:</v-toolbar-title
                    >
                </v-toolbar>
                <v-card-text class="pa-5">
                    <v-list>
                        <v-list-item
                            v-for="campo in camposFaltando"
                            :key="campo"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    campo
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </div>
        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <!-- <importar-filiais
            ref="importarFiliais"
            @importar="importar"
            @exportarModelo="exportarModelo"
        ></importar-filiais> -->

        <v-overlay
            :value="semAcesso"
            :absolute="true"
            :opacity="0.1"
            :z-index="3"
        ></v-overlay>
        <v-overlay
            :value="mostraOverlay"
            :absolute="true"
            :opacity="0.6"
            :z-index="3"
        ></v-overlay>
    </v-form>
</template>

<script>
// import { emit } from "process";
// import importarFiliais from "./importarFiliais.vue";
import FilialLegendas from "./_components/FilialLegendas.vue";
// import PontaTematicas from "./_components/PontasTematicas.vue";

export default {
    inject: ["listaCampos"],
    components: {
        // importarFiliais,
        FilialLegendas,
    },
    props: ["value"],

    data() {
        return {
            carregando: false,
            pagination: {},
            dialogoCamposFaltando: false,
            lista: {
                bandeiraRegiao: [],
                bandeiraEstado: [],
                bandeiraRegiaoCidade: [],
                bandeiraEstadoCidade: [],
                bandeiraCluster: [],
                bandeiraTamanho: [],
                cluster: [],
                espaco: [],
            },
            acordo: {},
            filtro: {
                id_cluster: [],
                filter_lojas_op: false,
            },
            dados: [],
            dadosTemp: [],
            buscaFilial: "",
            filiais: [],
            filiaisSalvas: [],
            todasFiliais: [],
            somaFiliais: [],
            somaFiliaisValor: [],
            semAcesso: false,
            clustersAcordoName: "",
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
        };
    },
    async created() {
        var lista = await this.listaCampos;
        this.lista.bandeiraEstado = lista.bandeiraEstado;
        this.lista.bandeiraEstadoCidade = lista.bandeiraEstadoCidade;
        this.lista.bandeiraCluster = lista.bandeiraCluster;
        this.lista.bandeiraTamanho = lista.bandeiraTamanho;
        this.lista.check_out = lista.check_out;
        this.lista.espaco = lista.espaco;
        this.lista.cluster = lista.cluster;

    },
    watch: {
        "filtro.id_cluster": function (val) {
            if (this.value.clusterRede != "REDE") return;

            if (this.filtro.id_cluster.length != this.listaCluster.length) {
                this.$nextTick(() => {
                    this.filtro.id_cluster = this.listaCluster.map(
                        (item) => item.value
                    );
                });
            }
        },
    },
    computed: {
        disabledOrganicos() {
            return !this.value.flag_teste && this.espacoOrganico() > 0;
        },
        listaPeriodo() {
            return this.getPeriodo("");
        },
        listaRegiao() {
            return this.lista.bandeiraRegiao;
        },
        listaEstado() {
            return this.lista.bandeiraEstado;
        },
        listaCidade() {
            return this.lista.bandeiraEstadoCidade.filter(
                (obj) =>
                    (this.filtro.estado.length > 0 &&
                        this.filtro.estado.includes(obj.estado)) ||
                    this.filtro.estado.length == 0
            );
        },
        listaCluster() {
            return this.lista.cluster;
        },
        listaTamanho() {
            return []
            // return this.lista.bandeiraTamanho
            //     .filter(
            //         (obj) =>
            //             obj.bandeira == this.value.bandeira &&
            //             (obj.text || "").trim() != ""
            //     )
            //     .map((e) => ({
            //         bandeira: e.bandeira,
            //         text: e.text.toUpperCase(),
            //     }));
        },
        listaHeader() {
            var header = [
                {
                    text: "ID JAVA",
                    value: "id_java",
                    align: "center",
                    class: "th-separador th-id-java",
                },
                {
                    text: "Estado",
                    value: "estado",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Cidade",
                    value: "cidade",
                    align: "center",
                    class: "th-separador",
                },
                // {
                //     text: "Cluster Acordo",
                //     value: "cluster_acordo",
                //     align: "center",
                //     class: "th-separador",
                // },
                {
                    text: "Cluster Filial",
                    value: "cluster",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Tamanho",
                    value: "tamanho",
                    align: "center",
                    class: "th-separador",
                },
                /* {
					text: "Oportunidade",
					value: "oportunidade_loja",
					align: "center",
				}, */
            ];
            for (let mesAtual of this.value.periodo){
                mesAtual = new Date(mesAtual + "-15");
            // }

            // var mesAtual = new Date(this.value.mesInicial + "-15");
            // var mesFinal = new Date(this.value.mesFinal + "-15");

            // while (mesAtual <= mesFinal) {
                header.push({
                    text:
                        ("0" + (mesAtual.getMonth() + 1)).slice(-2) +
                        "/" +
                        mesAtual.getFullYear(),
                    value:
                        "quantidade_" +
                        mesAtual.getFullYear() +
                        "_" +
                        (mesAtual.getMonth() + 1),
                    align: "center",
                    class: "th-separador",
                });

                // mesAtual.setMonth(mesAtual.getMonth() + 1);
            }

            return header;
        },
        listaHeaderFiliais() {
            var header = [
                {
                    text: "",
                    value: "nome",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Total",
                    value: "total",
                    align: "center",
                    class: "th-separador",
                },
            ];
            for (let mesAtual of this.value.periodo){
                mesAtual = new Date(mesAtual + "-15");

                header.push({
                    text:
                        ("0" + (mesAtual.getMonth() + 1)).slice(-2) +
                        "/" +
                        mesAtual.getFullYear(),
                    value:
                        "quantidade_" +
                        mesAtual.getFullYear() +
                        "_" +
                        (mesAtual.getMonth() + 1),
                    align: "center",
                    class: "th-separador",
                });

                // mesAtual.setMonth(mesAtual.getMonth() + 1);
            }

            return header;
        },
        listaLojas() {
            //let _dados = this.$helpers.cloneObj(this.dados);
            let _filter = !this.filtro.filter_lojas_op
                ? this.dados
                : this.dados.filter(
                      (e) => e.oportunidade_loja == this.filtro.filter_lojas_op
                  );
            return _filter;
        },
        camposFaltando() {
            const campos = [];

            if (this.value.periodo.length == 0) {
                campos.push("Período");
            }
            // if (!this.value.mesInicial) {
            //     campos.push("Mês Inicial");
            // }
            // if (!this.value.mesFinal) {
            //     campos.push("Mês Final");
            // }
            // if (!this.value.bandeira) {
            //     campos.push("Bandeira");
            // }
            // if (!this.value.id_fornecedor) {
            //     campos.push("Fornecedor");
            // }
            if (!this.value.clusterRede) {
                campos.push("Cluster/Rede");
            }
            if (!this.value.cota) {
                campos.push("Cota");
            }
            if (!this.value.id_espaco_pdv && this.value.cota !== 'ADESÃO') {
                campos.push("Espaço PDV");
            }

            return campos;
        },
        mostraOverlay() {
            if (this.camposFaltando.length > 0) {
                return true;
            }
            return false;
        },
    },
    methods: {
        iconOff(periodo, item) {
            const _isDisable = item["oportunidade" + periodo] == false;
            const _inventario = item["inventario" + periodo];
            const _compOrganico = item["comprometido_organico" + periodo];
            var _result = {
                icon: "mdi-checkbox-blank-outline",
                title: "",
            };

            switch (true) {
                //Não tem inventário
                case _isDisable && _inventario == 0:
                    _result = {
                        icon: "mdi-minus-box-outline",
                        title: "Não possui inventário",
                    };
                    break;
                //Não tem oportunidade
                case _isDisable && _inventario > 0:
                    _result = {
                        icon: "mdi-checkbox-blank-off-outline",
                        title:
                            `Não possui oportunidade` +
                            (_compOrganico > 0
                                ? ` , cluster alocado no ID Acordo ${_compOrganico}`
                                : ""),
                    };
                    break;
            }

            return _result;
        },
        checkFilial(item, key, value) {
            if (!this.disabledOrganicos) item[key] = value;

            if (this.disabledOrganicos /* && this.value.fnEditar */) {
                this.$dialog.notify.warning(
                    `Para acordos do tipo campanha, não é permitido editar as filiais.`,
                    {
                        position: "top-right",
                        timeout: 15000,
                    }
                );
            }
        },
        async setAcordo() {
            if (this.camposFaltando.length > 0) {
                this.dialogoCamposFaltando = true;

                return;
            }
            this.dialogoCamposFaltando = false;

            if (this.dados.length === 0) {
                await this.filtrar(true, true);
            }
            if(this.value.clustersNoAcordo){
                this.filtro.id_cluster = this.value.clustersNoAcordo;
            }
        },
        setSemAcesso(semAcesso) {
            this.semAcesso = semAcesso;
        },
        getPeriodo(campo = "quantidade") {
            const periodo = this.value.periodo.map((e) => {
                const mes =  new Date(e + "-15");
                return `${campo}_${mes.getFullYear()}_${mes.getMonth() + 1}`;
            });

            return periodo;
        },
        limpaFiltro() {
            this.filtro = {
                estado: [],
                cidade: [],
                id_cluster: [],
                tamanho: [],
                filter_lojas_op: false,
            };
            this.buscaFilial = "";
        },
        async limpar() {
            this.limpaFiltro();

            await this.filtrar();
        },
        async filtrar(marcaOrganico = true, created = false) {
            this.carregando = !this.carregando;

            var data = {};
            data.filtro = {
                // id_cluster: this.value.clustersNoAcordo || [],
            };
            if (!created) {
                data.filtro.id_cluster = this.filtro.id_cluster || [];
            }
            data.acordo = this.value;
            /// faz o id_espaco_pdv virar id_espaco
            data.acordo.id_espaco = this.value.id_espaco_pdv;

            let response = [];

            response = await this.$http().post("/acordoCampanhas/filiais", {
                data: data,
            });
            // }

            if (created) this.filtro.id_cluster = this.value.clusters || [];

            const _dadosConverte = response.data.map((e) => {
                let _op =
                    this.getPeriodo("").filter(
                        (m) => e["oportunidade" + m] > 0 && !e["quantidade" + m]
                    ).length > 0;

                let _qtd =
                    this.getPeriodo("").filter(
                        (m) =>
                            e["quantidade" + m] &&
                            !this.filtro.id_cluster.includes(e.id_cluster) &&
                            !this.filtro.id_cluster.includes(
                                e.id_cluster_acordo
                            )
                    ).length > 0;

                for (var mes of this.getPeriodo()) {
                    e["orig_" + mes] = this.$helpers.cloneObj(e[mes]);
                }
                return {
                    ...e,
                    oportunidade_loja: _op,
                    comprometido_loja_foracluster: _qtd,
                };
            });

            this.dados = _dadosConverte.filter(
                (e) =>
                    this.filtro.id_cluster.includes(e.id_cluster) ||
                    this.filtro.id_cluster.includes(e.id_cluster_acordo) ||
                    this.filtro.id_cluster.length == 0 ||
                    e.comprometido_loja_foracluster
            );

            if (created) {
                var _clustersAcordoName = new Set();

                for (var i of this.listaCluster) {
                    var _countCompEdicao = 0;
                    let _count = _dadosConverte.filter((e) => {
                        var _op = false;
                        var _ck = false;
                        for (const m of this.getPeriodo("")) {
                            if (
                                e["oportunidade" + m] > 0 &&
                                !e["quantidade" + m]
                            ) {
                                _op = true;
                            }

                            if (
                                e["quantidade" + m] &&
                                e.id_cluster == i.value &&
                                !_ck
                            ) {
                                _countCompEdicao++;
                                _ck = true;
                            }
                        }

                        return e.id_cluster == i.value && _op;
                    }).length;

                    i.text_concat = `
						${i.text} - (Filiais - ${_count} disponíveis
						${_countCompEdicao > 0 ? "/ " + _countCompEdicao + " neste acordo" : ""})
					`;

                    if ((this.value.clusters_acordo || []).includes(i.value))
                        _clustersAcordoName.add(i.text);
                }

                this.clustersAcordoName = [..._clustersAcordoName].join("/");
            }

            for (const e of this.filiais) {
                var index = this.dados.findIndex(
                    (element) => element.id_filial == e.id_filial
                );

                if (index >= 0) {
                    this.dados.splice(index, 1, { ...e });
                }
            }

            for (const e of this.getPeriodo()) {
                this.todasFiliais[e] = false;
            }

            if (this.value.fnEditar && this.filiais.length == 0) {
                for (const dado of this.dados) {
                    dado.alterado = 1;
                    dado.comprometido = 0;

                    for (const e of this.getPeriodo()) {
                        if (dado[e]) {
                            dado.comprometido = 1;
                        }
                    }
                }

                if (created) this.salvarSelecionadas(true);
            }

            if (
                this.espacoOrganico() &&
                marcaOrganico &&
                !this.value.fnLeitura &&
                (!this.value.fnEditar ||
                    (this.value.fnEditar && this.disabledOrganicos))
            ) {
                for (const e of this.getPeriodo()) {
                    this.todasFiliais[e] = true;
                    this.selecionaTudo(e, true);
                }
            }

            this.pagination.page = 1;
            if (this.value.clusterRede == "REDE") {
                this.salvarSelecionadas();
            }
            this.carregando = !this.carregando;
        },
        selecionaTudo(mes, force = false) {
            if (this.disabledOrganicos && !force) return;

            for (const e of this.dados) {
                if (e[mes.replace("quantidade", "oportunidade")] * 1 > 0) {
                    e[mes] = this.todasFiliais[mes] ? true : false;
                    e.alterado = 1;
                    e.comprometido = 0;

                    for (const p of this.getPeriodo()) {
                        if (e[p]) {
                            e.comprometido = 1;
                        }
                    }
                }
            }
        },
        alteraFilial(item) {
            if (this.disabledOrganicos) return;

            var dado = this.dados.find(
                (element) => element.id_filial == item.id_filial
            );

            dado.alterado = 1;
            dado.comprometido = 0;

            for (const e of this.getPeriodo()) {
                if (dado[e]) {
                    dado.comprometido = 1;
                }
            }
        },
        getCusto(mes, arrayCusto = []) {
            const _mesArray = mes.replace("quantidade_", "").split("_");
            const _mes =
                _mesArray[0] +
                "-" +
                (_mesArray[1] * 1 < 10 ? "0" + _mesArray[1] : _mesArray[1]) +
                "-01";

            var _custo = arrayCusto.filter((e) => e.mes == _mes);

            return _custo.length > 0 ? _custo[0].custo : 0;
        },
        disableCheckCusto(mes, arrayCusto = []) {
            const _mesArray = mes.split("_");
            const _mes =
                _mesArray[1] +
                "-" +
                (_mesArray[2] * 1 < 10 ? "0" + _mesArray[2] : _mesArray[2]) +
                "-01";

            var _custo = arrayCusto.filter((e) => e.mes == _mes);

            return _custo.length == 0;
        },
        salvarSelecionadas(force = false) {
            if (!force) {
                this.$emit("limpaDescontos");
            }
            var _filiais = this.dados;


            var soma = [];
            const _clusters = new Set();
            const clustersNoAcordo = new Set();
            soma[0] = {};
            soma[0].total = 0;
            // soma[1] = {};
            // soma[1].nome = "Custos";
            // soma[1].total = 0;
            for (const p of this.getPeriodo()) {
                soma[0][p] = 0;
                // soma[1][p] = 0;
            }
            for (const e of _filiais) {
                var fnFilial = 0;
                for (const p of this.getPeriodo()) {
                    if (force) {
                        soma[0][p] += e[p] * 1;
                        if (e[p]) {
                            fnFilial = 1;
                        }
                    } else {
                        soma[0][p] += 1;
                        fnFilial = 1;
                    }
                }
                if (fnFilial > 0) _clusters.add(e.cluster);
                if (fnFilial > 0) clustersNoAcordo.add(e.id_cluster);
                soma[0].total += fnFilial;
            }

            soma[0].nome = `Filiais Salvas (${[..._clusters].join(" / ")})`;
            this.value.clustersNoAcordo = [...clustersNoAcordo];
            this.somaFiliais = soma;
            this.filiais = _filiais;
            // this.value.filiais = _filiais;
            //this.filiaisSalvas = _filiais;
        },
        carregarSelecionadas() {
            this.dados = this.$helpers.cloneObj(this.filiais);
            this.pagination.page = 1;
        },
        limpaSelecionadas(fnServidor = false) {
            if (this.disabledOrganicos && fnServidor) return;

            for (const e of this.getPeriodo()) {
                this.todasFiliais[e] = false;
            }

            this.dados = [];
            this.filiais = [];
            this.somaFiliais = [];
            this.clustersNoAcordo = [];
            this.limpaFiltro();

            if (fnServidor) {
                //this.limpaFiltro();

                this.filtrar(false);
            }
        },
        espacoOrganico() {
            /// Para a regra de campanhas, a seleção de filiais deve ser semelhante ao
            /// que é feito para espaços organicos.

            return 1;
            // if (this.value.id_espaco) {
            //     var espaco = this.lista.espaco.filter(
            //         (obj) => obj.value == this.value.id_espaco
            //     );

            //     return espaco[0].organico;
            // } else {
            //     return 0;
            // }
        },

        validate() {
            if (!this.acordo.fnLeitura) {
                if (this.somaFiliais.length == 0) {
                    return false;
                } else {
                    for (const p of this.getPeriodo()) {
                        if (this.somaFiliais[0][p] == 0) {
                            return false;
                        }
                    }
                }
            }
            return true;
        },
    },
};
</script>

<style>
.theme--light.v-icon.v-icon.v-icon--disabled {
    color: rgba(0, 0, 0, 0.15) !important;
}

/* .custom-color-disable .theme--light.v-icon.v-icon.v-icon--disabled {
	color: rgba(244, 149, 7, 0.25) !important;
} */

.text-filtro {
    color: #000;
    font-size: 0.9rem;
    margin: 0 !important;
    align-self: center;
}
.top-header {
    background-color: #24796e;
}
.tabela-filial .v-data-table__wrapper {
    border-radius: 10px;
    border-right: 1px solid #ccc;
}
.th-id-java {
    border-radius: 10px 0 0 0;
}

.tabela-filial tr > td:first-child {
    border-left: 1px solid #ccc;
}
</style>
