<template>
    <v-container fluid class="mt-0 pt-0 d-flex h-100">
        <v-card class="px-5 py-3 br-15 w-100" elevation="0">
            <v-row>
                <v-col cols="6">
                    <v-toolbar-title class="page-title"
                        >Campanha</v-toolbar-title
                    >
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="2" xl="2">
                    <v-btn
                        class="w-100 new-button"
                        color="primary"
                        @click="novo()"
                    >
                        <v-icon left>mdi-view-grid-plus</v-icon> Adicionar
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="2" xl="2">
                    <v-btn
                        class="w-100 new-button"
                        color="primary"
                        @click="exportar()"
                    >
                        <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                    </v-btn>
                </v-col>
                <v-col cols="2">
                    <v-btn
                        class="w-100 new-button"
                        color="primary"
                        @click="filtrar()"
                    >
                        <v-icon left>mdi-filter</v-icon> Filtrar
                    </v-btn>
                </v-col>
            </v-row>
            <!-- {{ lista.usuarioTipo }} -->

            <hr class="hr-cinza my-5" />

            <v-form ref="formFiltro">
                <v-row>
                    <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                        <v-text-field
                            v-if="lista.usuarioTipo == 'fornecedor'"
                            label="Fornecedor"
                            v-model="fornecedorProvider.fornecedor"
                            class="input-cinza"
                            disabled
                            outlined
                            dense
                        ></v-text-field>
                        <v-autocomplete
                            v-if="lista.usuarioTipo == 'rd'"
                            label="Fornecedor"
                            class="input-cinza"
                            v-model="fornecedorProvider.id_fornecedor"
                            :items="lista.fornecedor"
                            :rules="regra.id_fornecedor"
                            v-on:change="filtrar"
                            outlined
                            dense
                            :menu-props="menuProps"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                        <v-select
                            label="Ano"
                            class="input-cinza"
                            v-model="fornecedorProvider.ano"
                            :items="lista.anos"
                            :rules="regra.ano"
                            :menu-props="menuProps"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                            label="Cota"
                            class="input-cinza"
                            v-model="filtro.cota"
                            :items="cotas"
                            outlined
                            dense
                            :menu-props="menuProps"
                            clearable
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
                        <div class="total-investido w-100">
                            <div class="texto">Total Investido:</div>
                            <div class="valor">
                                {{ dados.valor_total || "R$ 0,00" }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
            <div class="involucro">
                <v-data-table
                    :headers="headers"
                    :items="dados"
                    v-model="simulacoesSelecionadas"
                    item-key="id_simulacao_campanha"
                    :items-per-page="5"
                    :footer-props="{
                        'items-per-page-options': [5, 10, 15, 25, 50, 100],
                        itemsPerPageText: 'Linhas por páginas',
                        ofText: 'de',
                        pageText: '{0}-{1} de {2}',
                    }"
                    :options.sync="pagination"
                    :header-props="{ sortIcon: 'mdi-chevron-down' }"
                    multi-sort
                    show-select
                    class="simulation-table flex-table"
                >
                    <template v-slot:header="{}">
                        <thead class="header0">
                            <tr>
                                <th
                                    v-for="(h, i) in headersGroup0"
                                    :key="i"
                                    :colspan="h.colspan"
                                    :class="h.class"
                                    style="height: 45px"
                                >
                                    <v-icon :color="h.color" class="mx-1" size="18">
                                        {{ h.icon }}
                                    </v-icon>
                                    {{ h.text }}
                                </th>
                            </tr>
                            <tr>
                                <th
                                    v-for="(h, i) in headersGroup"
                                    :key="i"
                                    :colspan="h.colspan"
                                    :class="h.class"
                                    style="height: 45px"
                                >
                                    {{ h.text }}
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:[`item.obs`]="{ item }" class="cell-editable">
                        <div
                            @click="
                                !statusAcesso(
                                    item.id_status_simulacao,
                                    'aprovar_reprovar'
                                )
                                    ? editarTipo(item, 'obs', 'Observação')
                                    : false
                            "
                            style="cursor: pointer"
                            class="cell-editable"
                        >
                            <div v-if="item.obs">
                                <v-icon color="#ccc" class="m-0" size="15" left
                                    >mdi-eye</v-icon
                                >
                                Visualizar
                            </div>
                            <div v-else>Adicionar</div>
                        </div>
                    </template>
                    <template v-slot:[`item.marca`]="{ item }">
                        <div class="cell-editable">
                            <div @click="viewMarca(item)">
                                <v-icon color="#ccc" class="m-0" size="15" left
                                    >mdi-eye</v-icon
                                >
                                Visualizar
                            </div>
                        </div>
                    </template>
                    <template
                        v-for="index in 12"
                        v-slot:[`item.desconto_${index}`]="{ item }"
                        ><div :key="index">
                            <v-tooltip
                                bottom
                                v-if="item['cota_' + index]"
                                :disabled="
                                    !comparaVersoes(
                                        item['va_desconto_' + index],
                                        item['desconto_' + index]
                                    )
                                "
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-bind="attrs"
                                        v-on="on"
                                        :value="item['desconto_' + index]"
                                        :class="{
                                            diff: comparaVersoes(
                                                item['va_desconto_' + index],
                                                item['desconto_' + index]
                                            ),
                                            'input-cinza text-center': true,
                                        }"
                                        v-mask="'###'"
                                        suffix="%"
                                        @blur="
                                            (e) =>
                                                updateValor(
                                                    item,
                                                    'desconto_' + index,
                                                    e.target.value
                                                )
                                        "
                                        @focus="
                                            (e) => (valorEdicao = e.target.value)
                                        "
                                        outlined
                                        dense
                                        hide-details
                                    >
                                    </v-text-field>
                                </template>
                                <span class="text-center"
                                    >Valor anterior:
                                    {{ item["va_desconto_" + index] }}
                                </span>
                            </v-tooltip>
                            <span v-if="!item['cota_' + index]" class="text-center">
                                {{ item["desconto_" + index] }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:[`body.append`]>
                        <tr>
                            <td class="pl-4 bg-1" :colspan="5">
                                <b>Valor total:</b>
                            </td>
                            <td
                                class="text-center bg-2"
                                :colspan="lista.usuarioTipo == 'rd' ? 3 : 2"
                            >
                                {{ dados.totalMensal1 }}
                            </td>
                            <td
                                class="text-center bg-1"
                                :colspan="lista.usuarioTipo == 'rd' ? 3 : 2"
                            >
                                {{ dados.totalMensal2 }}
                            </td>
                            <td
                                class="text-center bg-2"
                                :colspan="lista.usuarioTipo == 'rd' ? 3 : 2"
                            >
                                {{ dados.totalMensal3 }}
                            </td>
                            <td
                                class="text-center bg-1"
                                :colspan="lista.usuarioTipo == 'rd' ? 3 : 2"
                            >
                                {{ dados.totalMensal4 }}
                            </td>
                            <td
                                class="text-center bg-2"
                                :colspan="lista.usuarioTipo == 'rd' ? 3 : 2"
                            >
                                {{ dados.totalMensal5 }}
                            </td>
                            <td
                                class="text-center bg-1"
                                :colspan="lista.usuarioTipo == 'rd' ? 3 : 2"
                            >
                                {{ dados.totalMensal6 }}
                            </td>
                            <td
                                class="text-center bg-2"
                                :colspan="lista.usuarioTipo == 'rd' ? 3 : 2"
                            >
                                {{ dados.totalMensal7 }}
                            </td>
                            <td
                                class="text-center bg-1"
                                :colspan="lista.usuarioTipo == 'rd' ? 3 : 2"
                            >
                                {{ dados.totalMensal8 }}
                            </td>
                            <td
                                class="text-center bg-2"
                                :colspan="lista.usuarioTipo == 'rd' ? 3 : 2"
                            >
                                {{ dados.totalMensal9 }}
                            </td>
                            <td
                                class="text-center bg-1"
                                :colspan="lista.usuarioTipo == 'rd' ? 3 : 2"
                            >
                                {{ dados.totalMensal10 }}
                            </td>
                            <td
                                class="text-center bg-2"
                                :colspan="lista.usuarioTipo == 'rd' ? 3 : 2"
                            >
                                {{ dados.totalMensal11 }}
                            </td>
                            <td
                                class="text-center bg-1"
                                :colspan="lista.usuarioTipo == 'rd' ? 3 : 2"
                            >
                                {{ dados.totalMensal12 }}
                            </td>
                            <td
                                class="text-center bg-1"
                                :colspan="lista.usuarioTipo == 'rd' ? 3 : 2"
                            >
                                {{ dados.valor_total }}
                            </td>
                        </tr>
                    </template>

                    <template
                        v-for="index in 12"
                        v-slot:[`item.valormes_${index}`]="{ item }"
                    >
                        <div :key="index">
                            {{
                                Number(item["valormes_" + index]).toLocaleString(
                                    "pt-br",
                                    {
                                        style: "currency",
                                        currency: "BRL",
                                    }
                                )
                            }}
                        </div>
                    </template>

                    <template v-slot:[`item.valor_total`]="{ item, index }">
                        <div :key="index">
                            {{
                                Number(item["valor_total"]).toLocaleString(
                                    "pt-br",
                                    {
                                        style: "currency",
                                        currency: "BRL",
                                    }
                                )
                            }}
                        </div>
                    </template>
                    <template
                        v-for="index in 12"
                        v-slot:[`item.cota_${index}`]="{ item }"
                    >
                        <div :key="index">
                            <v-tooltip bottom>
                                <!-- :disabled="
                    !comparaVersoes(item[`va_cota_${index}`], item[`cota_${index}`])
                " -->
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        v-on="on"
                                        :class="
                                            comparaVersoes(
                                                item[`va_cota_${index}`],
                                                item[`cota_${index}`]
                                            ) && 'cota-alterada'
                                        "
                                    >
                                        <div
                                            v-if="item['cota_' + index] !== null"
                                            :key="index"
                                            @click="
                                                !statusAcesso(
                                                    item.id_status_simulacao,
                                                    'aprovar_reprovar'
                                                )
                                                    ? editarTipo(
                                                        item,
                                                        'cota_' + index,
                                                        'Cota '
                                                    )
                                                    : false
                                            "
                                            :class="
                                                'cota-button cota-' +
                                                item['cota_' + index].toLowerCase()
                                            "
                                        >
                                            {{
                                                item["cota_" + index].toLowerCase()
                                            }}
                                        </div>
                                        <div
                                            v-if="item['cota_' + index] == null"
                                            :key="index"
                                            @click="
                                                !statusAcesso(
                                                    item.id_status_simulacao,
                                                    'aprovar_reprovar'
                                                )
                                                    ? editarTipo(
                                                        item,
                                                        'cota_' + index,
                                                        'Cota '
                                                    )
                                                    : false
                                            "
                                            class="cota-button semcota"
                                            style="cursor: pointer"
                                        >
                                            Inserir cota
                                        </div>
                                    </div>
                                </template>
                                <span class="text-center"
                                    >Valor anterior:
                                    {{ item[`cota_${index}`] }}
                                </span>
                            </v-tooltip>
                        </div>
                    </template>

                    <template v-slot:[`item.botao`]="{ item }">
                        <v-icon
                            small
                            class="mr-2"
                            color="orange"
                            @click="editarSimulacao(item)"
                            title="Editar"
                        >
                            mdi-circle-edit-outline
                        </v-icon>
                    </template>
                </v-data-table>
            </div>

            <v-btn
                v-if="simulacoesSelecionadas.length > 0"
                class="new-button px-4"
                color="error"
                @click="dialogoExcluir = true"
                >Excluir simulações selecionadas</v-btn
            >
        </v-card>

        <v-dialog v-model="editar.dialogo" width="800">
            <v-card class="radius-10">
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text"
                >
                    <v-toolbar-title class="px-1">
                        {{
                            editar.tipo === "obs" && editar.valor === null
                                ? "Adicionar"
                                : "Editar"
                        }}
                        {{ editar.titulo }}</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        class="px-2"
                        fab
                        elevation="0"
                        x-small
                        color="#fff"
                        @click="editar.dialogo = false"
                    >
                        <v-icon color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="px-5 py-5">
                    <v-form ref="formEditar">
                        <v-row no-gutters>
                            <v-col cols="12" v-if="editar.tipo == 'obs'">
                                <v-textarea
                                    v-model="editar.valor"
                                    placeholder="Digite a observação…"
                                    hide-details
                                    rows="8"
                                    outlined
                                    class="input-cinza"
                                    dense
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-row
                            no-gutters
                            class="mb-5"
                            v-if="editar.tipo.split('_')[0] == 'cota'"
                        >
                            <v-col
                                v-for="(cota, index) in cotas"
                                class="px-2"
                                :key="index"
                            >
                                <div
                                    @click="editarCota(cota)"
                                    :class="
                                        'cota-button text-center cota-' +
                                        cota.toLowerCase() +
                                        ' ' +
                                        (item[editar.tipo] == cota
                                            ? 'cota-ativa'
                                            : '')
                                    "
                                >
                                    {{ cota.toLowerCase() }}
                                    <div class="cota-active-circle">
                                        <v-icon
                                            color="primary"
                                            v-if="item[editar.tipo] == cota"
                                        >
                                            mdi-check-circle
                                        </v-icon>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                    <div class="d-flex justify-end w-100 mt-5">
                        <v-btn
                            v-if="editar.tipo == 'obs'"
                            color="red"
                            dark
                            class="mr-4 new-button"
                            @click="
                                editar.valor = null;
                                editarValor();
                                editar.dialogo = false;
                            "
                        >
                            <v-icon left>mdi-close-circle</v-icon> Apagar
                            observação
                        </v-btn>
                        <v-btn
                            v-if="editar.tipo.startsWith('cota')"
                            color="red"
                            dark
                            class="new-button"
                            @click="editarCota(null)"
                        >
                            <v-icon left>mdi-close-circle</v-icon> Remover cota
                        </v-btn>
                        <v-btn
                            v-if="editar.tipo.split('_')[0] != 'cota'"
                            ref="salvarValor"
                            color="primary"
                            class="new-button"
                            @click="editarValor()"
                        >
                            <v-icon left>mdi-check-circle</v-icon> Salvar
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogoExcluir" content-class="br-15" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        Excluir simulações selecionadas
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoExcluir = false"
                    >
                        <v-icon size="18px" color="error">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                    Deseja realmente excluir as simulações selecionadas?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        class="pr-4 mr-2 mb-2 new-button"
                        @click="dialogoExcluir = false"
                    >
                        <v-icon left>mdi-close-circle</v-icon> Não, não quero
                        excluir
                    </v-btn>
                    <v-btn
                        class="pr-4 mb-2 new-button"
                        color="primary"
                        @click="excluirMultipos"
                    >
                        <v-icon left>mdi-check-circle</v-icon> Sim, quero
                        excluir
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogo" width="90%">
            <v-card class="radius-10">
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text"
                >
                    <v-toolbar-title class="px-1">
                        Nova simulação</v-toolbar-title
                    >

                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        elevation="0"
                        x-small
                        color="#fff"
                        @click="dialogo = false"
                    >
                        <v-icon color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="px-5 py-5">
                    <v-form ref="form" v-model="validaForm">
                        <v-row>
                            <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="3"
                                xl="3"
                                class="pb-0"
                            >
                                <v-autocomplete
                                    outlined
                                    dense
                                    item-text="nome"
                                    item-value="value"
                                    class="input-cinza"
                                    label="Cluster"
                                    v-model="simulacao.id_cluster"
                                    :items="ListaCluster"
                                    :rules="regra.cluster"
                                    :menu-props="menuProps"
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="3"
                                lg="3"
                                xl="3"
                                class="pb-0"
                            >
                                <v-select
                                    label="Ano"
                                    class="input-cinza"
                                    v-model="simulacao.ano"
                                    :items="lista.anos"
                                    :rules="regra.ano"
                                    :menu-props="menuProps"
                                    outlined
                                    dense
                                >
                                </v-select>
                            </v-col>

                            <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="3"
                                xl="3"
                                class="pb-0"
                            >
                                <v-select
                                    label="Período da ação"
                                    v-model="simulacao.meses"
                                    :items="lista.meses"
                                    class="input-cinza"
                                    :menu-props="menuProps"
                                    multiple
                                    outlined
                                    dense
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            ripple
                                            @mousedown.prevent
                                            @click="selecionarTodosMeses"
                                        >
                                            <v-list-item-action>
                                                <v-icon>
                                                    {{
                                                        simulacao.meses
                                                            .length >= 12
                                                            ? "mdi-close-box"
                                                            : simulacao.meses
                                                                  .length === 0
                                                            ? "mdi-checkbox-blank-outline"
                                                            : "mdi-minus-box"
                                                    }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{
                                                        simulacao.meses
                                                            .length >= 12
                                                            ? "Desmarcar"
                                                            : "Selecionar"
                                                    }}
                                                    todos os meses
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <span
                                            v-if="
                                                simulacao.meses.length === 1 &&
                                                index === 0
                                            "
                                            >{{ item.nome }}</span
                                        >
                                        <span
                                            v-if="
                                                simulacao.meses.length > 1 &&
                                                index === 0
                                            "
                                            class="grey--text caption mr-1"
                                            >{{
                                                simulacao.meses.length
                                            }}
                                            selecionados</span
                                        >
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                        <!-- Meses -->

                        <div class="d-flex align-center mt-n5 mb-1">
                            <h2 style="font-weight: 500">
                                Personalizar período
                            </h2>
                            <v-divider class="mx-5" />
                            <div class="d-flex align-center">
                                <v-checkbox
                                    color="primary"
                                    v-model="replicarValores"
                                ></v-checkbox>
                                <span
                                    >Replicar informações para todos os
                                    meses</span
                                >
                            </div>
                        </div>

                        <v-row>
                            <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="3"
                                xl="3"
                                class="py-1"
                                v-for="(mes, i) in Object.keys(valores)"
                                :key="i"
                            >
                                <MesBox
                                    :mes="mes"
                                    :replicarValores="replicarValores"
                                    :esconderDesconto="
                                        lista.usuarioTipo !== 'rd'
                                    "
                                    :active="simulacao.meses.includes(i + 1)"
                                    :icon="
                                        4 <= i && i <= 8 ? 'inverno' : 'verao'
                                    "
                                    :regra="{
                                        desconto: regra.desconto,
                                    }"
                                    v-model="valores"
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                    <div class="d-flex justify-end w-100">
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="itemEdicao"
                            color="red"
                            dark
                            class="pr-4 mr-4 new-button"
                            @click="confirmExcluir(itemEdicao)"
                        >
                            <v-icon left>mdi-close-circle</v-icon> Excluir
                            simulação
                        </v-btn>
                        <v-btn
                            color="primary"
                            class="pr-4 new-button"
                            @click="confirmSalvar()"
                        >
                            <v-icon left>mdi-check-circle</v-icon> Salvar
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirm.dialogo" width="600">
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{ confirm.titulo }}
                </v-card-title>
                <v-card-text class="mt-4">
                    {{ confirm.texto }}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="confirm.dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        :color="confirm.color"
                        class="pr-4"
                        @click="confirm.funcao"
                    >
                        <v-icon left>mdi-close</v-icon> {{ confirm.botao }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import MesBox from "./_components/mes_box.vue";

export default {
    components: {
        MesBox,
    },
    inject: ["fornecedorProvider"],
    data() {
        return {
            lista: {
                meses: [],
                macrofornecedor: [],
            },
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            headersGroup0: [{ text: null, colspan: 5, class: "valorTotal" }],
            headersGroup: [{ text: null, colspan: 5, class: "" }],
            cotas: ["DIAMANTE", "OURO", "PRATA", "BRONZE", "ADESAO"],
            itemEdicao: null,
            replicarValores: false,
            dialogoExcluir: false,
            simulacoesSelecionadas: [],
            filtro: {
                cota: null,
            },
            valores: {
                janeiro: {},
                fevereiro: {},
                março: {},
                abril: {},
                maio: {},
                junho: {},
                julho: {},
                agosto: {},
                setembro: {},
                outubro: {},
                novembro: {},
                dezembro: {},
            },
            meses: [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro",
            ],
            headers: [
                { text: "", value: "botao", align: "center", sortable: false },
                {
                    text: "Status",
                    value: "status",
                    align: "center",
                    sortable: true,
                    class: "th-separador min-width-150",
                },
                {
                    text: "Cluster",
                    value: "cluster",
                    align: "center",
                    sortable: true,
                    class: "th-separador min-width-150",
                },
                {
                    text: "Observação",
                    value: "obs",
                    align: "center",
                    sortable: true,
                    class: "min-width-150",
                },
            ],
            dados: [],
            mes: null,
            simulacao: {
                id_fornecedor: this.fornecedorProvider.id_fornecedor,
                ano: this.fornecedorProvider.ano,
                id_simulacao_campanha: null,
                meses: [],
                id_cluster: null,
            },
            listagem: {
                bandeira: ["Raia", "Drogasil"],
                plano: ["Anual", "Avulso"],
            },
            pagination: {},
            paginationErros: {},
            total: null,
            carregando: false,
            busca: null,
            dialogo: false,
            dialogoEditar: false,
            dialogoImportar: false,
            validaForm: false,
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
            arquivoCSV: null,
            options: {
                locale: "pt-BR",
                prefix: "R$",
                suffix: "",
                length: 11,
                precision: 2,
            },

            tipocargo: null,
            regra: {
                desconto: [
                    (v) =>
                        (!!v && v >= 0 && v <= 100) ||
                        "O desconto deve ser um numero inteiro ente 0 e 100.",
                ],
                csv: [
                    (v) => !!v || "Selecione um arquivo.",
                    (v) =>
                        (!!v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
                        "O arquivo deve ser do tipo CSV.",
                ],
                ano: [(v) => !!v || "O campo ano é obrigatório."],
                id_fornecedor: [
                    (v) => !!v || "O campo fornecedor é obrigatório.",
                ],
                cluster: [(v) => !!v || "O campo  é obrigatório."],

                numero_da_campanha: [(v) => !!v || "O campo é obrigatório."],
            },
            isCsv: false,
            headerErros: [
                { text: "Descrição do Erro", value: "msg", align: "left" },
            ],
            erros: [],
            errosLabels: { msg: "Descrição do Erro" },
            erro: false,
            confirm: {
                dialogo: false,
                titulo: null,
                texto: null,
                funcao: null,
                color: null,
                botao: null,
            },
            obj: {},
            obs: {
                dialogo: false,
                valor: null,
            },
            editar: {
                dialogo: false,
                valor: null,
                titulo: null,
                tipo: "",
                preco: "",
            },
            item: {},
            isDisabled: true,
            cluster: [],
        };
    },
    watch: {
        pagination: {
            handler() {
                if (this.lista.fornecedor) this.filtrar();
            },
            deep: true,
        },
        fornecedorProvider: {
            handler(val) {
				this.simulacao.id_fornecedor = val.id_fornecedor;
                this.simulacao.ano = val.ano;
            },
            deep: true,
        },
    },
    async created() {
        this.carregando = true;

        // this.simulacao.ano = this.anoProximo;

        var response = await this.$http().post("/simulacao/campanha/filtros", {
            data: {},
        });
        var response1 = await this.$http().post(
            "/simulacao/campanha/listarClusters",
            {
                data: {},
            }
        );

        this.cluster = response1.data;
        this.lista = response.data;
        if (this.lista.usuarioTipo == "fornecedor") {
            this.simulacao.id_fornecedor =
                this.lista.fornecedor[0].id_fornecedor;
            this.simulacao.fornecedor = this.lista.fornecedor[0].fornecedor;
        }

        var classe = "dark";
        var colspan = this.lista.usuarioTipo == 'rd' ? 3: 2;
        this.headersGroup0.push({
            text: "Verão",
            colspan: 3 * colspan,
            icon: "mdi-white-balance-sunny",
            class: "v-data-table-header text-center verao",
            color: "#c28f5b",
        });
        this.headersGroup0.push({
            text: "Inverno",
            colspan: 5 * colspan,
            icon: "mdi-snowflake",
            class: "v-data-table-header text-center inverno",
            color: "#1e7199",
        });
        this.headersGroup0.push({
            text: "Verão",
            colspan: 4 * colspan,
            icon: "mdi-white-balance-sunny",
            class: "v-data-table-header text-center verao",
            color: "#c28f5b",
        });

        for (let m = 1; m <= 12; m++) {
            const cellBg = `${
                4 <= m && m <= 8 ? "inverno" : "verao"
            }-${classe}`;
            this.headersGroup.push({
                text: this.meses[m - 1],
                colspan: colspan,
                class: "v-data-table-header text-center " + cellBg,
            });

            this.headers.push({
                text: "Cota",
                value: "cota_" + m,
                align: "center",
                sortable: true,
                class: cellBg + " min-width-150 th-separador",
            });

            if (this.lista.usuarioTipo == "rd") {
                this.headers.push({
                    text: "Desconto",
                    value: "desconto_" + m,
                    align: "center",
                    class: cellBg + "  th-separador min-width-150",
                });
            }

            this.headers.push({
                text: "R$",
                value: "valormes_" + m,
                align: "center",
                class: cellBg + " min-width-150",
            });

            classe = classe == "dark" ? "light" : "dark";
        }
        this.headers.push({
            text: "Total",
            colspan: 2,
            value: "valor_total",
            align: "center",
            class: "v-data-table-header text-center  min-width-150  " + classe,
        });
        this.lista.meses = this.meses.map((mes, index) => {
            return {
                value: index + 1,
                text:
                    mes +
                    " - " +
                    (4 <= index && index <= 8 ? "Inverno" : "Verão"),
                nome: mes,
            };
        });
        //       for (let m = 1; m <= 12; m++) {
        //     this.lista.meses.push({
        //       value: m,
        //       text:
        //         this.meses[m - 1] +
        //         " - " +
        //         (4 <= m && m <= 8 ? "Inverno" : "Verão"),
        //       nome: this.meses[m - 1],
        //     });
        //   }

        if (this.$refs.formFiltro.validate()) {
            this.filtrar();
        } else {
            setTimeout(() => {
                this.carregando = false;
            }, 1000);
        }
    },

    computed: {
        anoProximo() {
            return new Date().getFullYear();
        },
        mesProximo() {
            return this.$moment().add(1, "months").format("YYYY-MM");
        },
        cargo() {
            return this.$store.state.auth.cargo;
        },

        ListaCluster() {
            var resultado = this.cluster.filter(
                (v) => this.$moment(v.ano).format("YYYY") == this.simulacao.ano
            );
            return resultado.map((item) => {
                return {
                    value: item.id_cluster,
                    text: item.nome,
                    nome: item.nome,
                };
            });
        },
    },

    methods: {
        statusAcesso(id_status_simulacao, acesso) {
            if (this.lista.status && id_status_simulacao) {
                var status = this.lista.status.find(
                    (e) => e.id_status_simulacao == id_status_simulacao
                );

                if (
                    id_status_simulacao == 2 &&
                    this.lista.usuarioTipo == "rd"
                ) {
                    return false;
                } else if (
                    id_status_simulacao == 3 &&
                    this.lista.usuarioTipo == "rd"
                ) {
                    return false;
                } else {
                    return status[acesso];
                }
            } else {
                return false;
            }
        },
        selecionarTodosMeses() {
            if (this.simulacao.meses.length < 12) {
                this.simulacao.meses = Array.from(Array(12).keys()).map(
                    (i) => i + 1
                );
            } else {
                this.simulacao.meses = [];
            }
        },
        async confirmSalvar() {
            if (!this.$refs.form.validate()) return;
            const _response = await this.salvar(true);

            //console.log(_response);

            if (!_response.data.sucesso) {
                this.$dialog.notify.error(
                    "Falha ao calcular a simulação, por favor, tente novamente.",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
                return;
            }

            let _valor = this.$helpers.roundDecimal(
                _response.data.result.total,
                2
            );
            const _text = `
				Deseja salvar a simulação no valor total de R$ ${_valor.toLocaleString()} de investimento?  
			`;

            const _dialogConfirm = await this.$dialog.confirm({
                text: _text,
                title: "Salvar simulação",
                actions: [
                    { text: "NÃO", color: "red" },
                    { text: "SIM", color: "teal" },
                ],

                color: "teal lighten-2",
            });

            const _confirm = await _dialogConfirm.wait();

            if (_confirm == "SIM") this.salvar();
        },
        async salvar(flag_simulacao = false) {
            if (!this.$refs.form.validate()) return;

            this.carregando = true;
            const data = this.simulacao;

            data["valoresMeses"] = this.simulacao.meses.map((item) => {
                const mes = this.meses[item - 1].toLowerCase();

                return {
                    mes: item,
                    cota: this.valores[mes]["cota"],
                    desconto: this.valores[mes]["desconto"],
                };
            });
            if (this.itemEdicao) {
                data["id_simulacao_campanha"] =
                    this.itemEdicao.id_simulacao_campanha;
            }

            data["flag_simulacao"] = flag_simulacao;

            var response = await this.$http().post(
                "/simulacao/campanha/salvar",
                {
                    data,
                }
            );

            this.carregando = false;

            if (flag_simulacao) return response;

            if (response.data.sucesso) {
                this.filtrar();

                this.dialogo = false;
            } else {
                this.alertaTexto = response.data.msg;
                this.alerta = true;
                this.pagination.page = 1;
                this.erro = true;
            }
        },
        async buscarPreco({ cota }) {
            const [_, mes] = this.editar.tipo.split("_");
            var data = { ...this.item, coluna: this.editar.valor, mes };

            var response = await this.$http().post(
                "/simulacao/campanha/precos",
                {
                    data: data,
                }
            );

            this.editar.preco = response.data;

            this.isDisabled = false;
        },
        confirmExcluir(item) {
            this.obj = item;

            this.confirm.titulo = "Excluir Linha";
            this.confirm.texto =
                "Deseja realmente excluir a linha da simulação?";
            this.confirm.color = "error";
            this.confirm.botao = "Excluir";
            this.confirm.funcao = this.excluir;
            this.confirm.dialogo = true;
        },
        async excluir() {
            this.carregando = true;

            this.confirm.dialogo = false;

            var data = this.obj;

            var response = await this.$http().post(
                "/simulacao/campanha/excluir",
                {
                    data: data,
                }
            );

            this.carregando = false;

            if (response.data.sucesso) {
                this.filtrar();

                this.dialogo = false;
            } else {
                this.erros = response.data.msg;
                this.pagination.page = 1;
                this.erro = true;
            }
        },
		async excluirMultipos() {
            this.carregando = true;

            var data = {};
            data.id_simulacao_campanha = this.simulacoesSelecionadas.map(
                (item) => item.id_simulacao_campanha
            );

            await this.$http().post("/simulacao/campanha/excluir", { data: data });

            this.carregando = false;
            this.dialogoExcluir = false;
            this.filtrar();
        },
        async editarSimulacao(item) {
            this.itemEdicao = item;
            this.simulacao.meses = [];
            this.carregando = true;
            this.simulacao.id_cluster = item.id_cluster;
            this.replicarValores = false;

            var data = {
                id_fornecedor: this.simulacao.id_fornecedor,
                ano: this.simulacao.ano,
                usuarioTipo: this.lista.usuarioTipo,
            };
            //   var lista = (
            //     await this.$http().post("/simulacao/aprovacao/bloquearAcessos", {
            //       data: data,
            //     })
            //   ).data;

            //   if (
            //     lista.campanha.length > 0 ||
            //     lista.encommerce.length > 0 ||
            //     lista.pdv.length > 0 ||
            //     lista.tabloide.length > 0
            //   ) {
            //     this.alerta = true;
            //     this.alertaColor = "error";
            //     this.alertaTimeout = -1;
            //     this.alertaTexto =
            //       "Existe simulações aprovadas pelo fornecedor para este ano";
            //   } else {
            //     this.dialogo = true;
            //   }
            this.dialogo = true;
            this.carregando = false;
            // Habilita os meses para edição
            for (let i = 1; i <= 12; i++) {
                const cota = this.itemEdicao[`cota_${i}`] || null;
                if (cota !== null) {
                    this.simulacao.meses.push(i);
                }

                const mes = this.meses[i - 1].toLowerCase();
                this.valores[mes]["cota"] = cota;
                this.valores[mes]["desconto"] = 0;
            }
        },
        async filtrar() {
            if(!this.$refs.formFiltro.validate()) return

            this.carregando = true;
            this.pagination.page = 1;

            const data = {};

            data.simulacao = this.simulacao;
            data.pagination = this.pagination;

            if (this.fornecedorProvider.id_fornecedor) {
                data.id_fornecedor = this.fornecedorProvider.id_fornecedor;
            }

            if (this.fornecedorProvider.ano) {
                data.ano = this.fornecedorProvider.ano;
            }
            data.cota = this.filtro.cota;

            const response = await this.$http().post(
                "/simulacao/campanha/lista",
                {
                    data: data,
                }
            );

            this.dados = response.data;

            this.total = response.data.length ? response.data[0].total : 0;

            const mensal1 = response.data.reduce(
                (a, b) => a + Number(b.valormes_1),
                0
            );

            const mensal2 = response.data.reduce(
                (a, b) => a + Number(b.valormes_2),
                0
            );

            const mensal3 = response.data.reduce(
                (a, b) => a + Number(b.valormes_3),
                0
            );

            const mensal4 = response.data.reduce(
                (a, b) => a + Number(b.valormes_4),
                0
            );
            const mensal5 = response.data.reduce(
                (a, b) => a + Number(b.valormes_5),
                0
            );
            const mensal6 = response.data.reduce(
                (a, b) => a + Number(b.valormes_6),
                0
            );
            const mensal7 = response.data.reduce(
                (a, b) => a + Number(b.valormes_7),
                0
            );
            const mensal8 = response.data.reduce(
                (a, b) => a + Number(b.valormes_8),
                0
            );
            const mensal9 = response.data.reduce(
                (a, b) => a + Number(b.valormes_9),
                0
            );
            const mensal10 = response.data.reduce(
                (a, b) => a + Number(b.valormes_10),
                0
            );
            const mensal11 = response.data.reduce(
                (a, b) => a + Number(b.valormes_11),
                0
            );
            const mensal12 = response.data.reduce(
                (a, b) => a + Number(b.valormes_12),
                0
            );

            const valor_total = response.data.reduce(
                (a, b) => a + Number(b.valor_total),
                0
            );

            const brlCurrency = {
                style: "currency",
                currency: "BRL",
            };
            this.dados.totalMensal1 = mensal1.toLocaleString(
                "pt-br",
                brlCurrency
            );
            this.dados.totalMensal2 = mensal2.toLocaleString(
                "pt-br",
                brlCurrency
            );
            this.dados.totalMensal3 = mensal3.toLocaleString(
                "pt-br",
                brlCurrency
            );
            this.dados.totalMensal4 = mensal4.toLocaleString(
                "pt-br",
                brlCurrency
            );
            this.dados.totalMensal5 = mensal5.toLocaleString(
                "pt-br",
                brlCurrency
            );
            this.dados.totalMensal6 = mensal6.toLocaleString(
                "pt-br",
                brlCurrency
            );
            this.dados.totalMensal7 = mensal7.toLocaleString(
                "pt-br",
                brlCurrency
            );
            this.dados.totalMensal8 = mensal8.toLocaleString(
                "pt-br",
                brlCurrency
            );
            this.dados.totalMensal9 = mensal9.toLocaleString(
                "pt-br",
                brlCurrency
            );
            this.dados.totalMensal10 = mensal10.toLocaleString(
                "pt-br",
                brlCurrency
            );
            this.dados.totalMensal11 = mensal11.toLocaleString(
                "pt-br",
                brlCurrency
            );
            this.dados.totalMensal12 = mensal12.toLocaleString(
                "pt-br",
                brlCurrency
            );

            this.dados.valor_total = valor_total.toLocaleString(
                "pt-br",
                brlCurrency
            );

            if (this.total == 0 && this.pagination.page > 1) {
                this.pagination.page = 1;
                this.filtrar();
            }

            this.carregando = false;
        },
        async exportar() {
            if (!this.$refs.formFiltro.validate()) return;

            this.carregando = true;

            var data = {};

            data.simulacao = this.simulacao;

            var response = await this.$http().post(
                "/simulacao/campanha/exportar",
                {
                    data: data,
                }
            );

            window.location = this.$http("baseURL") + response.data.url;

            this.carregando = false;
        },
        async novo() {
            this.itemEdicao = null;
            this.simulacao.id_cluster = null;
            this.replicarValores = false;
            if (!this.$refs.formFiltro.validate()) return;

            this.carregando = true;

            var data = {
                id_fornecedor: this.simulacao.id_fornecedor,
                ano: this.simulacao.ano,
                usuarioTipo: this.lista.usuarioTipo,
            };
            this.simulacao.meses = [];
            this.dialogo = true;
            this.carregando = false;
            for (let i = 1; i <= 12; i++) {
                const mes = this.meses[i - 1].toLowerCase();
                this.valores[mes]["cota"] = null;
                this.valores[mes]["desconto"] = 0;
            }
        },

        abrirEditar(item) {
            this.simulacao = { ...item };
            this.dialogoEditar = true;
        },

        editarTipo(item, tipo, titulo) {
            this.item = item; //para buscar id_precocampanha e demais outras coisas
            this.editar.dialogo = true;

            setTimeout(() => {
                this.editar.tipo = tipo;
                this.editar.titulo = titulo;

                setTimeout(() => {
                    this.editar.valor = item[tipo];
                }, 100);
            }, 100);

            this.item = { ...item };
        },
        async editarCota(cota) {
            this.editar.valor = cota;
            await this.buscarPreco({
                cota,
            });
            await this.editarValor();
        },

        async editarValor() {
            this.carregando = true;

            var data = {
                id_simulacao_campanha: this.item.id_simulacao_campanha,
                tipo: this.editar.tipo,
                obj: {},
            };
            const [tipo, index] = this.editar.tipo.split("_");

            data.obj[this.editar.tipo] = this.editar.valor;
            if (tipo === "cota") {
                data.obj["valormes_" + index] = this.editar.preco || 0;
            }
            var response = await this.$http().post(
                "/simulacao/campanha/editar",
                {
                    data: data,
                }
            );

            this.carregando = false;

            if (response.data.sucesso) {
                this.filtrar();

                this.editar.dialogo = false;
            } else {
                this.alerta = true;
                this.alertaColor = "error";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;
            }
        },
        comparaVersoes(anterior, atual) {
            if (anterior === null) return false;
            return anterior != atual;
        },
        async updateValor(item, campo, valor) {
            if (this.valorEdicao == valor) {
                return;
            }

            const [tipo, mes] = campo.split("_");
            if (tipo == "desconto" && valor > 100) {
                this.mostrarAlerta({
                    texto: "O desconto deve ser um numero inteiro ente 0 e 100.",
                    color: "error",
                    timeout: 3000,
                });
                item[campo] = this.valorEdicao;
                return;
            }

            var data = {
                id_simulacao_campanha: item.id_simulacao_campanha,
                tipo: campo,
                obj: {},
            };

            data.obj[campo] = valor;

            var response = await this.$http().post(
                "/simulacao/campanha/editar",
                {
                    data: data,
                }
            );

            this.carregando = false;

            if (response.data.sucesso) {
                this.filtrar();
                //   this.mostrarAlerta({
                //   texto:
                //     "Valores atualizados com sucesso.",
                //   color: "success",
                //   timeout: 3000,
                // });
                // this.editar.dialogo = false;
            } else {
                item[campo] = this.valorEdicao;
                this.alerta = true;
                this.alertaColor = "error";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;
            }
        },
        mostrarAlerta({ color, timeout, texto }) {
            this.alerta = true;
            this.alertaColor = color || "error";
            this.alertaTimeout = timeout || -1;
            this.alertaTexto = texto || "";
        },
    },
};
</script>

<style>
.cota-button {
    padding: 8px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    text-transform: capitalize;
    position: relative;
}
.semcota {
    background-color: #f5f5f5;
    font-weight: 500;
}
.cota-diamante {
    background-color: #cbe3f0;
    color: #6d8fa1;
}
.cota-ouro {
    background-color: #efd67f;
    color: #a58a29;
}
.cota-prata {
    background-color: #dcdcdc;
    color: #949494;
}
.cota-bronze {
    background-color: #e7b992;
    color: #a56b3a;
}
.cota-adesao {
    background-color: #b1e3c8;
    color: #669079;
}
.bg-2 {
    background-color: #f5f5f5;
}

.cota-ativa {
    border: 3px solid var(--v-primary-base);
    padding: 6px 20px;
}
.cota-active-circle {
    position: absolute;
    top: -15px;
    right: -15px;
    background: #fff;
    border-radius: 50%;
}
.cota-alterada .cota-button {
    border: 3px solid pink;
}
.involucro {
    height: 650px;
    display: flex;
  margin-top: 20px;
  /* flex-grow: 1; */
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
}

.involucro .flex-table {
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  height: 620px;
  width: 100%;
}

.involucro .flex-table > div {
  width: 100%;
}

.involucro thead {
	position: -webkit-sticky;
	position: sticky;
	overflow: hidden;
	z-index: 1;
	/* background-color: #ffff; */
}

.involucro .header0 {
	top: 0px;
}

.involucro .flex-table .v-data-table-header {
	top: 90px;
} 

.involucro .flex-table .v-data-table__wrapper {
	overflow-y: auto !important;
} 
</style>
