<template>
  <v-form ref="formFiltro">
    <v-row no-gutters>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
        <v-text-field
          label="Período Inicial"
          v-model="filtro.dataInicial"
          :rules="regra.dataInicial"
          required
          outlined
          dense
          type="month"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
        <v-text-field
          label="Período Final"
          v-model="filtro.dataFinal"
          :rules="regra.dataInicial"
          required
          outlined
          dense
          type="month"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
        <v-select
          label="Visão"
          :items="lista.visao"
          v-model="filtro.visao"
          :rules="regra.visao"
          multiple
          outlined
          dense
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="filtro.visao.length === 1 && index === 0">{{
              item.text
            }}</span>
            <span
              v-if="filtro.visao.length > 1 && index === 0"
              class="grey--text caption mr-1"
              >{{ filtro.visao.length }} selecionados</span
            >
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
        <v-select
          label="Colunas Agrupadas"
          :items="lista.cols_agrupado"
          v-model="filtro.agrupar"
          multiple
          outlined
          dense
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="filtro.agrupar.length === 1 && index === 0">{{
              item.text
            }}</span>
            <span
              v-if="filtro.agrupar.length > 1 && index === 0"
              class="grey--text caption mr-1"
              >{{ filtro.agrupar.length }} selecionados</span
            >
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
        <v-select
          label="Bandeira"
          v-model="filtro.bandeira"
          :items="lista.bandeira"
          multiple
          outlined
          dense
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="filtro.bandeira.length === 1 && index === 0">{{
              item.text
            }}</span>
            <span
              v-if="filtro.bandeira.length > 1 && index === 0"
              class="grey--text caption mr-1"
              >{{ filtro.bandeira.length }} selecionados</span
            >
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
        <v-autocomplete
          label="Fornecedor"
          v-model="filtro.id_fornecedor"
          :items="lista.fornecedor"
          :rules="regra.fornecedor"
          outlined
          dense
          multiple
        >
          <template v-slot:selection="{ item, index }">
            <div
              v-if="filtro.id_fornecedor.length === 1 && index === 0"
              style="
                width: 70% !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
                overflow: hidden;
              "
            >
              {{ item.text }}
            </div>
            <span
              v-if="filtro.id_fornecedor.length > 1 && index === 0"
              class="grey--text caption mr-1"
              >{{ filtro.id_fornecedor.length }} selecionados</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
        <v-autocomplete
          label="Espaço"
          v-model="filtro.id_espaco"
          :items="lista.espaco"
          multiple
          outlined
          dense
        >
          <template v-slot:selection="{ item, index }">
            <div
              v-if="filtro.id_espaco.length === 1 && index === 0"
              style="
                width: 70% !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
                overflow: hidden;
              "
            >
              {{ item.text }}
            </div>
            <span
              v-if="filtro.id_espaco.length > 1 && index === 0"
              class="grey--text caption mr-1"
              >{{ filtro.id_espaco.length }} selecionados</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
        <v-autocomplete
          label="Espaço Macro"
          v-model="filtro.espaco_macro"
          :items="lista.espaco_macro"
          multiple
          outlined
          dense
        >
          <template v-slot:selection="{ item, index }">
            <div
              v-if="filtro.espaco_macro.length === 1 && index === 0"
              style="
                width: 70% !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
                overflow: hidden;
              "
            >
              {{ item.text }}
            </div>
            <span
              v-if="filtro.espaco_macro.length > 1 && index === 0"
              class="grey--text caption mr-1"
              >{{ filtro.espaco_macro.length }} selecionados</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
        <v-select
          label="Cluster"
          :items="lista.cluster"
          v-model="filtro.id_cluster"
          multiple
          outlined
          dense
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="filtro.id_cluster.length === 1 && index === 0">{{
              item.text
            }}</span>
            <span
              v-if="filtro.id_cluster.length > 1 && index === 0"
              class="grey--text caption mr-1"
              >{{ filtro.id_cluster.length }} selecionados</span
            >
          </template>
        </v-select>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        xl="3"
        class="px-2 ml-auto pb-2"
        align="end"
      >
        <v-btn class="ml-2 mb-1" color="primary" @click="limpar()">
          <v-icon left>mdi-filter-off</v-icon> Limpar
        </v-btn>
        <v-btn class="ml-2 mb-1" color="primary" @click="filtrar()">
          <v-icon left>mdi-filter</v-icon> Filtrar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  inject: ["listaCampos"],
  data() {
    return {
      lista: {
        cols_agrupado: [
          { text: "Bandeira", value: "bandeira" },
          { text: "Espaço", value: "espaco" },
          { text: "Espaço Macro", value: "espaco_macro" },
          { text: "Cluster", value: "cluster" },
          { text: "Fornecedor", value: "fornecedor" },
          { text: "Grupo", value: "grupo" },
        ],
        visao: [
          { text: "PDV", value: "pdv" },
          { text: "Campanha", value: "campanha" },
          { text: "Tablóide", value: "tabloide" },
          { text: "E-commerce", value: "ecommerce" },
        ],
        bandeira: [
          { text: "DROGASIL", value: "DROGASIL" },
          { text: "RAIA", value: "RAIA" },
        ],
      },

      filtro: {
        visao: "pdv",
        id_fornecedor: [],
      },
      regra: {
        dataInicial: [(v) => !!v || "O campo período inicial é obrigatório"],
        dataFinal: [
          (v) => !!v || "O campo período final é obrigatório",
          (v) =>
            (!!v && v >= this.filtro.dataInicial) ||
            "O período final deve ser igual ou posterior ao período inicial",
        ],
        // fornecedor: [(v) => !!v || "O campo fornecedor é obrigatório"],
        visao: [(v) => v.length > 0 || "O campo visão é obrigatório"],
      },
    };
  },
  async created() {
    var lista = await this.listaCampos;

    this.lista.espaco = lista.espaco;
    this.lista.espaco_macro = lista.espaco_macro;
    this.lista.cluster = lista.cluster;
    this.iniciaFiltro();

    if (lista.usuarioTipo == "fornecedor") {
      const [fornecedor] = lista.fornecedor;
      this.lista.fornecedor = [
        {
          value: fornecedor.id_fornecedor,
          text: fornecedor.fornecedor,
        },
      ];
      this.filtro.id_fornecedor = [fornecedor.id_fornecedor];
    } else {
      this.lista.fornecedor = lista.fornecedor;
    }
  },
  computed: {},
  methods: {
    iniciaFiltro() {
      this.filtro = {
        dataInicial: this.$moment().format("YYYY-MM"),
        dataFinal: this.$moment().format("YYYY-MM"),
        bandeira: [],
        id_espaco: [],
        espaco_macro: [],
        id_cluster: [],
        visao: ["pdv"], // lembrar de remover o pdv
      };

      this.resetValidation();
    },
    limpar() {
      this.iniciaFiltro();
    },
    filtrar() {
      if (!this.validate()) return;

      this.$emit("filtrar", this.filtro);
    },
    validate() {
      return this.$refs.formFiltro.validate();
    },
    resetValidation() {
      try {
        this.$refs.formFiltro.resetValidation();
      } catch (error) {
        //console.log(error)
      }
    },
  },
};
</script>
