import {http} from '@/config/http'
import router from '@/router'
import jwtDecode from 'jwt-decode'


export const auth = {
  state: {
    logado: false,
    token: null,   
    usuario: null,
    alertaMesagem: null,
    fornecedor:null,
    packageVersion: process.env.PACKAGE_VERSION || '0',
  },
  mutations: {
    login(state, data){
        state.logado = true
        state.token = data.token
        state.usuario = data.usuario
        state.alertaMesagem = null
        localStorage.setItem('token', data.token)
        localStorage.setItem('usuario', JSON.stringify(data.usuario))
    },
    logout(state){
        state.logado = false
        state.token = null
        state.usuario = null
        state.alertaMesagem = null
        localStorage.setItem('token', null)
        localStorage.setItem('usuario', null)
    },
 
    erroLogin(state, erro){
        state.logado = false
        state.token = null
        state.usuario = null
        localStorage.setItem('token', null)
        localStorage.setItem('usuario', null)
        if(erro){
            state.alertaMesagem = erro
        }
        else{
            state.alertaMesagem = 'Erro de requisição, entre em contato com o suporte.'
        }     
    },
  },
  actions: {
    async login({commit}, [login, senha]){

        commit('logout')

        try {

            var response = await http().post('/login', {
                login: login,
                senha: senha
            })

            if(response.data.erro) { 

                commit('erroLogin', response.data.erro)
            }
            if(response.data.token) { 

                commit('login', response.data)            
                router.push({name: "main"})
            }

        } catch (e) {
            commit('erroLogin')
        }
    },
    logout({commit}){

        commit('logout')
        router.push({name: "login"})
    },
    sessaoExpirou({commit}){

        commit('erroLogin', 'Sua sessão expirou. Faça novamente o login.')
        router.push({name: "login"})
    },
   
  },
  getters : {
    getLogado: state => state.token||localStorage.getItem('token'),
    getToken: state => state.token ? state.token : localStorage.getItem('token'),
    getUsuario: state => state.usuario ? state.usuario : JSON.parse(localStorage.getItem('usuario')),
    getAlertaMesagem: state => state.alertaMesagem,
  
    getSenhaPadrao: (state) => {
        var token = state.token ? state.token : localStorage.getItem('token');
        var decode = jwtDecode(token) 
        state.fornecedor = decode.fornecedor    

        if(state.fornecedor){
            return decode.senha == '$2b$10$FYQ.lp5nPIQwomoWwSRvwOdEMWEMvRFZOLBqmuUTsbutDyW500La2'
        }else{
            return false
        }
      },
      appVersion: (state) => {
          return state.packageVersion
      }
  }
}