<template>
    <v-form ref="form">
        <v-row class="mt-4">
            <v-col cols="4" class="py-0">
                <MesInput
                    label="Período Inicial"
                    v-model="value.mesInicial"
                    :rules="regra.mesInicial"
                    :change="periodoInicialChange"
                    :min="mesAtual"
                    :disabled="value.fnEditar || value.fnReplicar"
                    required
                ></MesInput>
            </v-col>
            <v-col cols="4" class="py-0">
                <MesInput
                    label="Período Final"
                    v-model="value.mesFinal"
                    :rules="regra.mesFinal"
                    :change="periodoFinalChange"
                    :min="mesAtual"
                    :disabled="value.fnEditar || value.fnReplicar"
                    required
                ></MesInput>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-autocomplete
                    label="Produto"
                    outlined
                    class="input-cinza"
                    dense
                    v-model="id_produtoBusca"
                    :items="lista.produto"
                    :search-input.sync="buscaProdutoFornecedor"
                    :loading="isLoadingProduto"
                    @change="produtoChange()"
                    :disabled="value.fnEditar"
                    :menu-props="menuProps"
                    clearable
                >
                    <template v-slot:no-data>
                        <p
                            v-if="
                                !buscaProdutoFornecedor ||
                                (buscaProdutoFornecedor &&
                                    buscaProdutoFornecedor.length <= 4)
                            "
                            class="ml-4 mt-4"
                        >
                            Insira pelo menos 4 caracteres para listar os
                            produtos.
                        </p>
                        <p v-else-if="isLoadingProduto" class="ml-4 mt-4">
                            Buscando produtos...
                        </p>
                        <p v-else class="ml-4 mt-4">Nada encontrado</p>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-text-field
                    label="Fornecedor"
                    class="input-cinza"
                    v-model="value.fornecedor"
                    :rules="regra.fornecedor"
                    :disabled="value.fnEditar || value.fnReplicar"
                    outlined
                    dense
                    readonly
                    required
                ></v-text-field>
            </v-col>
			<v-col cols="4" class="py-0">
                <v-select
                    label="Bandeira"
                    outlined
                    dense
                    class="input-cinza"
                    v-model="value.bandeira"
                    :items="lista.bandeira"
                    :rules="regra.bandeira"
                    @change="
                    buscarPontasTematicas()
                    limpaFiliais()
                    "
                    :disabled="value.fnEditar || value.fnReplicar"
                    :menu-props="menuProps"
                    required
                ></v-select>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-autocomplete
                    label="Espaço"
                    class="input-cinza"
                    outlined
                    dense
                    v-model="acordo.value"
                    :items="lista.espaco"
                    :rules="regra.espaco"
                    @change="
                        setInformacoesAdicionais($event)
                        limpaFiliais($event)
                    "
                    :disabled="value.fnEditar || value.fnReplicar"
                    :menu-props="menuProps"
                    required
                ></v-autocomplete>
            </v-col>
            <v-col cols="4" class="py-0" v-if="activeModelo">
                <v-select
                    label="Modelo Check-out"
                    class="input-cinza"
                    outlined
                    dense
                    v-model="value.modelo_ck"
                    :items="lista.modelo"
                    :rules="regra.modelo"
                    @change="limpaFiliais()"
                    :disabled="(value.fnEditar || value.fnReplicar) && value.modelo_ck !== null"
                    :menu-props="menuProps"
                    required
                />
            </v-col>
            <v-col
                cols="4"
                class="py-0 d-flex align-top"
                v-if="activeFlagTeste"
            >
                <v-checkbox
                    v-model="value.flag_teste"
                    label="Acordo de teste"
                    class="my-0"
                    @change="limpaFiliais()"
                    :disabled="value.fnEditar || value.fnReplicar"
                />
                <div class="ml-5">
                    <InfoBox>
                        <p>
                            Se ativar essa opção, será possível selecionar as
                            lojas disponíveis para criar um o acordo de teste na
                            próxima etapa, caso contrário, por se tratar de um
                            espaço orgânico, as lojas já serão selecionadas
                            automaticamente, sem a opção de editá-las.
                        </p></InfoBox
                    >
                </div>
            </v-col>
            <!-- <v-col
                cols="12"
                sm="12"
                md="3"
                lg="4"
                xl="4"
                class="pt-0"
                v-if="activeFlagTeste"
            >
                <v-icon color="primary" dark @click="showFlagTeste()">
                    mdi-help-circle
                </v-icon>
            </v-col> -->



            <v-col cols="12" class="py-0">
                <v-textarea
                    outlined
                    v-model="value.obs_departamento"
                    class="input-cinza"
                    dense
                    rows="3"
                    label="Observação Departamento"
                ></v-textarea>
            </v-col>
            <v-col cols="12" class="py-0">
                <v-textarea
                    outlined
                    v-model="value.obs_contrato"
                    class="input-cinza"
                    dense
                    rows="3"
                    label= "Observação do Contrato"
                ></v-textarea>
            </v-col>
        </v-row>
        <v-overlay
            :value="semAcesso"
            :absolute="true"
            :opacity="0.1"
            :z-index="3"
        ></v-overlay>
    </v-form>
</template>

<script>
import smartId from "smart-id"
export default {
    inject: ["listaCampos"],
    props: ["value"],
    data() {
        return {
            acordo: {
                mesInicial: null,
                mesFinal: null,
                id_espaco: null,
                id_fornecedor: null,
                fornecedor: null,
                bandeira: null,
                modelo_ck: null,
                flag_teste: false,
                obs_departamento: null,
                obs_contrato: null,
                visivel: null,
                planograma: null,
                comentario: null,
                fnEditar: false,
                fnLeitura: false,
            },
            lista: {
                bandeira: [],
                espaco: [],
                modelo: ["ANTIGO", "NOVO"],
            },
            id_produtoBusca: null,
            buscaProdutoFornecedor: null,
            isLoadingProduto: false,
            regra: {
                mesInicial: [
                    (v) => !!v || "O campo período inicial é obrigatório",
                    (v) =>
                        (v && v >= this.mesAtual) ||
                        this.value.fnLeitura ||
                        "O período inicial deve ser igual ou posterior ao mês atual",
                ],
                mesFinal: [
                    (v) => !!v || "O campo período final é obrigatório",
                    (v) =>
                        (v && v >= this.value.mesInicial) ||
                        "O período final deve ser igual ou posterior ao período inicial",
                ],
                espaco: [(v) => !!v || "O campo espaço é obrigatório"],
                fornecedor: [(v) => !!v || "O campo fornecedor é obrigatório"],
                bandeira: [(v) => !!v || "O campo bandeira é obrigatório"],
                modelo: [
                    (v) =>
                        !!v ||
                        this.value.fnLeitura ||
                        //this.value.fnReplicar ||
                        "O campo modelo check-out é obrigatório",
                ],
            },
            semAcesso: false,
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
        };
    },
    async created() {
        var lista = await this.listaCampos;
        this.lista.bandeira = lista.bandeira;
        this.lista.espaco = lista.espaco;
        this.buscarPontasTematicas()
    },
    computed: {
        mesAtual() {
            return this.$moment().format("YYYY-MM");
        },
        activeModelo() {
            if (!this.value.fnEditar) this.clearVarAcordo("modelo_ck");

            let _espacoMacro =
                this.lista.espaco
                    .filter((e) => e.value == this.value.id_espaco)
                    .map((e) => e.espaco_macro)[0] || "";

            return _espacoMacro.toLowerCase() == "check-out";
        },
        activeFlagTeste() {
            let _organico =
                this.lista.espaco
                    .filter((e) => e.value == this.value.id_espaco)
                    .map((e) => e.organico)[0] || 0;

            return _organico;
        },
    },
    watch: {
        async buscaProdutoFornecedor(val) {
            if (!val) {
                this.id_produtoBusca = null;
                return;
            }

            if (val.length < 4) return;

            if (this.isLoadingProduto) return;

            this.isLoadingProduto = true;

            var data = { produto: val };

            var response = await this.$http().post("/acordo/buscaProdutos", {
                data: data,
            });

            this.lista.produto = response.data;

            this.isLoadingProduto = false;
        },
        id_produtoBusca(val) {
            var produto = this.lista.produto.find((e) => e.value == val);

            this.value.id_fornecedor = produto ? produto.id_fornecedor : null;
            this.value.fornecedor = produto ? produto.fornecedor : null;
        },
    },
    methods: {
        showFlagTeste() {
            const text = `
				Se ativar essa opção, será possível selecionar as lojas disponíveis para criar um o acordo\n
				de teste na próxima etapa, caso contrário, por se tratar de um espaço orgânico,\n
				as lojas já serão selecionadas automaticamente,
				sem a opção de editá-las.
			`;

            this.$dialog.confirm({
                text: text,
                title: "Ajuda",
                actions: [{ text: "OK", color: "teal" }],
                color: "teal lighten-2",
            });
        },
        clearVarAcordo(key) {
            this.acordo[key] = null;
        },
        setAcordo(acordo) {
            this.acordo = { ...acordo, value: acordo.id_espaco };
        },
        setSemAcesso(semAcesso) {
            this.semAcesso = semAcesso;
        },
        limpaCadastro() {
            this.id_produtoBusca = null;
            this.buscaProdutoFornecedor = null;
            this.lista.produto = [];
            this.acordo = {
                mesInicial: null,
                mesFinal: null,
                id_espaco: null,
                id_fornecedor: null,
                modelo_ck: null,
                flag_teste: false,
                fornecedor: null,
                bandeira: null,
                obs_departamento: null,
                obs_contrato: null,
                visivel: null,
                planograma: null,
                comentario: null,
                fnEditar: false,
            };
        },
        async buscarPontasTematicas() {
            console.log('ponta tematica')
			if ((!this.value.mesInicial) || (!this.value.mesFinal)) {
				return;
			}

			if (this.isLoadingPontasTematicas) return;

			this.isLoadingPontasTematicas = true;

			let espaco = this.lista.espaco.filter(item => !item.ponta_tematica)

            response = {data: {lista:[]}}
            if (this.value.bandeira) {
                var data = { 
                    mesInicial: this.value.mesInicial + '-01',
                    mesFinal: this.value.mesFinal + '-01',
                    bandeira: this.value.bandeira
                };

                var response = await this.$http().post("/pontatematica/pontasTematicasNoPeriodo", {
                    data: data,
                });
            } else {
                console.log('vo nao')
            }	

			this.lista.espaco = [ ...espaco, ...response.data.lista.map(item => {
				var id = smartId.make()
				return {...item, value: id }
			})]

			this.isLoadingPontasTematicas = false;
		},
		setInformacoesAdicionais(event) {
			const espacoSeleciondo = this.lista.espaco.filter(item => (item.value == event))
			this.value.id_espaco = espacoSeleciondo[0].id_espaco
			this.value.id_espaco_tematico = espacoSeleciondo[0].id_espaco_tematico
			this.value.ponta_tematica = espacoSeleciondo[0].ponta_tematica
			this.value.prateleira_nome = espacoSeleciondo[0].ponta_tematica ? espacoSeleciondo[0].text : null
			this.value.prateleira = espacoSeleciondo[0].prateleira
			this.value.tema = espacoSeleciondo[0].tema
            this.value.id_ponta_tematica = espacoSeleciondo[0].id_ponta_tematica
            console.log(espacoSeleciondo[0].mecanismo_preco_id)
            this.value.mecanismo_preco_id = espacoSeleciondo[0].mecanismo_preco_id
		},
        limpaFiliais() {
            this.$emit("limpaFiliais");
        },
        limpaProdutos() {
            this.$emit("limpaProdutos");
        },
        limpaDesconto() {
            this.$emit("limpaDescontos");
        },
        validate() {
            return this.$refs.form.validate();
        },
        produtoChange() {
            this.limpaDesconto();
            this.limpaProdutos();
        },
        resetValidation() {
            try {
                this.$refs.form.resetValidation();
            } catch (error) {
                //console.log(error)
            }
        },

        periodoInicialChange(valor) {
            this.buscarPontasTematicas()
            this.limpaDesconto();
            this.limpaFiliais();
            if (this.value.mesFinal < valor) {
                this.value.mesFinal = valor;
            }
        },
        periodoFinalChange(valor) {
            console.log('periodo final')
            this.buscarPontasTematicas()
            this.limpaDesconto();
            this.limpaFiliais();
            if (this.value.mesInicial > valor) {
                this.value.mesInicial = valor;
            }
        },
    },
};
</script>
