<template>
    <v-container class="pt-0" fluid >
        <v-card class="px-5 py-3 br-15 w-100" elevation="0" style="border-top-left-radius: 0 !important">
            <v-row>
                <v-col cols="6">
                    <v-toolbar-title class="page-title">Acordos Comerciais</v-toolbar-title>
                </v-col>
                <v-col cols="2">
                    <v-btn class="new-button w-100" color="primary" @click="$refs.formulario.novo()"
                        v-if="usuarioTipo == 'rd'">
                        <v-icon left>mdi-plus-thick</v-icon> Criar acordo
                    </v-btn>
                </v-col>
                <v-col cols="2">
                    <v-btn class="new-button w-100" color="primary" @click="$refs.guiapdf.guiaPDF()">
                        <v-icon left>mdi-file-pdf-box</v-icon> Guia PDF
                    </v-btn>
                </v-col>
                <v-col cols="2">
                    <v-btn class="new-button w-100" color="primary" @click="exportar()">
                        <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                    </v-btn>
                </v-col>
            </v-row>
            <filtro ref="filtro" @filtrar="filtrar" @limpar="limpar"></filtro>
        </v-card>
        <indicadores v-if="usuarioTipo == 'rd'" ref="indicadores" :indicadores="indicadores" :filtro="$refs.filtro">
        </indicadores>
        <v-card elevation="0" class="br-15 pa-5 involucroAcordo">
            <v-data-table
                v-model="acordoSelecionado"
                :headers="headers"
                :items="acordos"
                :server-items-length="totalAcordos"
                item-key="id_acordo"
                show-select
                fixed-header
                multi-sort
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100, 500, -1],
                    itemsPerPageText: 'Linhas por páginas',
                    ofText: 'de',
                    pageText: '{0}-{1} de {2}',
                }" :options.sync="pagination" class="tabela paginacao-esquerda flex-table">
                <template v-slot:[`item.data-table-select`]="{
                    item,
                    isSelected,
                    select,
                }">
                    <v-simple-checkbox :ripple="false" :value="isSelected" :disabled="(!fnLiberar &&
                        !statusAcesso(
                            item.id_status,
                            'aprovar_reprovar'
                        )) ||
                        (fnLiberar &&
                            (!statusAcesso(item.id_status, 'liberar') ||
                                item.mes_acordo != mesAtual))
                        " :on-icon="(!fnLiberar &&
        !statusAcesso(
            item.id_status,
            'aprovar_reprovar'
        )) ||
        (fnLiberar &&
            (!statusAcesso(item.id_status, 'liberar') ||
                item.mes_acordo != mesAtual))
        ? 'mdi-checkbox-blank-outline'
        : 'mdi-checkbox-marked'
        " @input="select($event)"></v-simple-checkbox>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-menu offset-y min-width="180" content-class=" acordo-menu">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn dark color="#f2f2f2" v-bind="attrs" v-on="on" small class="px-1"
                                style="min-width: unset">
                                <v-icon color="#CCC">mdi-menu</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-list dense nav>
                                <v-list-item v-if="statusAcesso(
                                    item.id_status,
                                    'aprovar_reprovar'
                                ) &&
                                    fnAprovar &&
                                    usuarioTipo == 'rd'
                                    " link @click="$refs.dialogo.reprovar(item)">
                                    <v-list-item-icon>
                                        <v-icon color="error">mdi-close</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Reprovar Acordo</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="statusAcesso(
                                    item.id_status,
                                    'aprovar_reprovar'
                                ) &&
                                    fnAprovar &&
                                    usuarioTipo == 'rd'
                                    " link @click="$refs.dialogo.aprovar(item)">
                                    <v-list-item-icon>
                                        <v-icon color="success">mdi-check</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Aprovar Acordo</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="item.mes_acordo > mesAtual &&
                                    statusAcesso(
                                        item.id_status,
                                        'editar'
                                    ) &&
                                    usuarioTipo == 'rd'
                                    " link @click="$refs.formulario.editar(item)">
                                    <v-list-item-icon>
                                        <v-icon color="primary">mdi-pencil</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Editar Acordo</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="item.mes_acordo >= mesAtual &&
                                    statusAcesso(
                                        item.id_status,
                                        'replicar_bandeira'
                                    ) &&
                                    usuarioTipo == 'rd'
                                    " link @click="
        $refs.formulario.replicarAcordo(
            item,
            'bandeira'
        )
        ">
                                    <v-list-item-icon>
                                        <v-icon color="primary">mdi-autorenew</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>Replicar
                                            Bandeira</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="item.mes_acordo >= mesAtual &&
                                    statusAcesso(
                                        item.id_status,
                                        'replicar_checkout'
                                    ) &&
                                    item.espaco_macro == 'Check-out'
                                    &&
                                    usuarioTipo == 'rd'
                                    " link @click="
        $refs.formulario.replicarAcordo(
            item,
            'checkout'
        )
        ">
                                    <v-list-item-icon>
                                        <v-icon color="primary">mdi-autorenew</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>Replicar
                                            Tipo Check-out</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="item.mes_acordo >= mesAtual &&
                                    statusAcesso(
                                        item.id_status,
                                        'replicar_cancelado'
                                    ) &&
                                    usuarioTipo == 'rd'
                                    " link @click="
        $refs.formulario.replicarAcordo(
            item,
            'cancelado'
        )
        ">
                                    <v-list-item-icon>
                                        <v-icon color="primary">mdi-autorenew</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>Replicar
                                            Cancelado</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item link @click="$refs.dialogo.historico(item)" v-if="usuarioTipo == 'rd'">
                                    <v-list-item-icon>
                                        <v-icon color="primary">mdi-file-document-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Histórico</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item link @click="$refs.formulario.editar(item, true)">
                                    <v-list-item-icon>
                                        <v-icon color="primary">mdi-file-document-edit</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Exibir Acordo</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="item.termino == item.mes_acordo &&
                                    item.termino >= mesAnterior &&
                                    statusAcesso(
                                        item.id_status,
                                        'renovar'
                                    ) &&
                                    usuarioTipo == 'rd'
                                    " link @click="$refs.dialogo.renovacao(item)">
                                    <v-list-item-icon>
                                        <v-icon color="primary">mdi-update</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Renovar Acordo</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="item.planograma" link @click="$refs.dialogo.planograma(item)">
                                    <v-list-item-icon>
                                        <v-icon color="primary">mdi-image</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Planograma</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="statusAcesso(
                                    item.id_status,
                                    'editar_planograma'
                                ) && usuarioTipo == 'rd'
                                    " link @click="
                                    editarPlanograma(item)
        
                                    ">
                                    <v-list-item-icon>
                                        <v-icon color="primary">mdi-image-edit</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Editar
                                            Planograma</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="item.obs_departamento &&
                                    usuarioTipo == 'rd'
                                    " link @click="
        $refs.dialogo.dialogDepartamento(item)
        ">
                                    <v-list-item-icon>
                                        <v-icon color="primary">mdi-clipboard-text-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Obs.
                                            Departamento</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="item.obs_contrato && usuarioTipo == 'rd'
                                    " link @click="$refs.dialogo.dialogContrato(item)">
                                    <v-list-item-icon>
                                        <v-icon color="primary">mdi-clipboard-text</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Obs. Contrato</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="statusAcesso(
                                    item.id_status,
                                    'cancelar'
                                ) && usuarioTipo == 'rd'
                                    " link @click="$refs.dialogo.cancelar(item)">
                                    <v-list-item-icon>
                                        <v-icon color="error">mdi-close</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Cancelar Acordo</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        statusAcesso(
                                            item.id_status,
                                            'liberar'
                                        ) &&
                                        item.mes_acordo == mesAtual &&
                                        usuarioTipo == 'rd'
                                    "
                                    link
                                    @click="$refs.dialogo.liberar([item.id_acordo],parseFloat(item.investimento.replace('R$ ','').replace('.','').replace(',','.')))"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="success">mdi-check</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Liberar Acordo</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                </template>
                <!-- <template v-slot:[`item.oportunidade`]="{ item }">
					<v-btn
						small
						color="#f2f2f2"
						@click="oportunidadeAcordo(item)"
						class="grey--text"
					>
						<v-icon left>mdi-eye</v-icon> Visualizar
					</v-btn>
				</template> -->
            </v-data-table>

            <div class="
                    table-footer-prepend
                    d-flex
                    pl-2
                    pt-4
                    align-center
                    justify-end
                ">
                <v-btn v-if="!fnLiberar" class="new-button ml-5" :disabled="!acordoSelecionado.filter((e) =>
                    statusAcesso(e.id_status, 'aprovar_reprovar')
                ).length || !fnAprovar
                    " color="primary" @click="$refs.dialogo.aprovar()">
                    <v-icon left>mdi-check-circle</v-icon> Aprovar Acordos
                </v-btn>
                <v-btn v-if="!fnLiberar" class="new-button ml-5" :disabled="!acordoSelecionado.filter((e) =>
                    statusAcesso(e.id_status, 'aprovar_reprovar')
                ).length || !fnAprovar
                    " color="error" @click="$refs.dialogo.reprovar()">
                    <v-icon left>mdi-close-circle</v-icon> Reprovar acordos
                </v-btn>
                <v-btn
                    v-if="fnLiberar"
                    class="new-button ml-5"
                    :disabled="
                        !acordoSelecionado.filter(
                            (e) =>
                                statusAcesso(e.id_status, 'liberar') &&
                                e.mes_acordo == mesAtual  &&
                                        usuarioTipo == 'rd'
                        ).length
                    "
                    color="primary"
                    @click="dialogoLiberarAcordos()"
                >
                    <v-icon left>mdi-check</v-icon> Liberar acordos
                </v-btn>
            </div>

            <dialogo
                ref="dialogo"
                @aprovarAcordo="aprovarAcordo" 
                @liberarAcordo="liberarAcordo"
                @filtrar="filtrar"
                @renovar="renovar"
            ></dialogo>

            <formulario ref="formulario" @filtrar="filtrar"></formulario>

            <v-overlay :value="carregando">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>

            <v-snackbar v-model="alerta" :color="alertaColor" :timeout="alertaTimeout" top style="z-index: 400">
                <v-alert :type="alertaColor">
                    {{ alertaTexto }}
                </v-alert>
                <v-btn dark text class="float-right" @click="alerta = false">
                    Fechar
                </v-btn>
            </v-snackbar>

            <guiapdf ref="guiapdf"></guiapdf>
        </v-card>
    </v-container>
</template>

<script>
import Filtro from "./Filtro.vue";
import Dialogo from "./Dialogo.vue";
import Indicadores from "./Indicadores.vue";
import Formulario from "./formulario/Formulario.vue";
import Guiapdf from "./GuiaPDF.vue";

export default {
    components: {
        Filtro,
        Dialogo,
        Indicadores,
        Formulario,
        Guiapdf,
    },
    data() {
        return {
            headers: [
                {
                    text: "Menu",
                    value: "actions",
                    sortable: false,
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Acordo",
                    value: "id_acordo",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Acordo RD",
                    value: "acordo_rd",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Fornecedor",
                    value: "fornecedor",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Espaço",
                    value: "espaco",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Espaço Macro",
                    value: "espaco_macro",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Bandeira",
                    value: "bandeira",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Mês",
                    value: "mes",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Ano",
                    value: "ano",
                    align: "center",
                    class: "th-separador   ",
                },
                // {
                //     text: "Oportunidade",
                //     value: "oportunidade",
                //     sortable: false,
                //     align: "center",
                //     class: "th-separador   ",
                // },
                {
                    text: "Contrato",
                    value: "id_contrato",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Contrato RD",
                    value: "contrato",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Parcela",
                    value: "parcela",
                    sortable: false,
                    align: "center",
                    class: "th-separador   ",
                },

                {
                    text: "Acor. Teste",
                    value: "flag_teste",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Status",
                    value: "status",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Just. Desconto",
                    value: "justificativa",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Just. Reprov.",
                    value: "justificativa_reprovacao",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Just. Cancel.",
                    value: "justificativa_cancelamento",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Produtos",
                    value: "produtos",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Prod. Edit.",
                    value: "produtos_alterados",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "VB",
                    value: "vb",
                    sortable: false,
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Filiais",
                    value: "filiais",
                    sortable: false,
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Filiais Edit.",
                    value: "filiais_alteradas",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Visivel",
                    value: "visivel",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Valor s/ Desc.",
                    value: "valor",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "% Padrão",
                    value: "desconto_padrao",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "% Fora",
                    value: "desconto_percentual",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Desconto",
                    value: "desconto",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Total Desc.",
                    value: "desconto_total",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Invest.",
                    value: "investimento",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Invest.Hist.",
                    value: "investimento_historico",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Cluster Acordo",
                    value: "cluster_acordo_nome",
                    sortable: false,
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Cluster Filiais",
                    value: "cluster",
                    sortable: false,
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Modelo Check-out Acordo",
                    value: "check_out_modelo",
                    sortable: false,
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Modelo Check-out Filiais",
                    value: "check_out_organico",
                    sortable: false,
                    align: "center",
                    class: "th-separador   ",
                },
            ],
            acordos: [],
            indicadores: {},
            planograma: [],
            statusIndicadores: [],
            totalAcordos: 0,
            pagination: {},
            carregando: false,
            acordoSelecionado: [],
            justificativas: [],
            status: [],
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
            fnAprovar: false,
            fnLiberar: false,
        };
    },
    provide() {
        return {
            listaCampos: this.listaCampos(),
            usuarioTipo: this.usuarioTipo,
        };
    },
    watch: {
        pagination: {
            handler() {
                this.filtrar(false);
            },
            deep: true,
        },
    },
    async beforeCreate() {
        var response = await this.$http().post("/acordo/status", {});

        this.status = response.data;

        response = await this.$http().post("/acordo/permissao", { id_menu: 2 });

        this.fnAprovar = response.data;
    },
    computed: {
        mesAtual() {
            return this.$moment().format("YYYY-MM");
        },
        mesAnterior() {
            return this.$moment().subtract(1, "months").format("YYYY-MM");
        },
        mesProximo() {
            return this.$moment().add(1, "months").format("YYYY-MM");
        },
        usuarioTipo() {
            if (this.$store.getters.getUsuario.id_fornecedor) {
                return "fornecedor";
            }
            return "rd";
        },

    },
    methods: {
        async listaCampos() {
            var response = await this.$http().post("/acordo/listaCampos", {});

            return response.data;
        },
        async editarPlanograma(item) {
            await this.$refs.dialogo.planogramaEditar(item)
        },
        limpar() {
            this.planograma = [];
            this.statusIndicadores = [];
            // this.fnLiberar = false;

            this.filtrar();
        },
        async filtrar(paginaInicial = true) {
            if (!this.$refs.filtro.validate()) return;

            this.carregando = true;

            this.acordoSelecionado = [];

            var filtro = this.$refs.filtro.filtro;

            var data = {};

            data.mesInicial = filtro.mesInicial
                ? filtro.mesInicial
                : this.$moment().format("YYYY-MM");
            data.mesFinal = filtro.mesFinal
                ? filtro.mesFinal
                : this.$moment().format("YYYY-MM");
            data.tipoPeriodo = filtro.tipoPeriodo ? filtro.tipoPeriodo : -1;

            data.bandeira = filtro.bandeira;
            data.id_espaco = filtro.id_espaco;
            data.espaco_macro = filtro.espaco_macro;
            data.id_fornecedor = filtro.id_fornecedor;
            data.id_status = filtro.id_status ? filtro.id_status : [];
            data.id_acordo = filtro.id_acordo ? [filtro.id_acordo] : [];
            data.acordo_rd = filtro.acordo_rd ? [filtro.acordo_rd] : [];
            data.id_contrato = filtro.id_contrato ? [filtro.id_contrato] : [];
            data.id_cluster = filtro.id_cluster ? filtro.id_cluster : [];
            data.contrato = filtro.contrato ? [filtro.contrato] : [];
            data.planograma = filtro.planograma;
            data.statusIndicadores = this.statusIndicadores;

            if (paginaInicial) this.pagination.page = 1;

            data.pagination = this.pagination;

            var response = await this.$http().post("/acordo/lista", {
                data: data,
            });
            // console.log('paaoun aqui')
            this.acordos = response.data.lista //.map(item => {
            //     return {...item,espaco:item.prateleira_nome ? item.prateleira_nome : item.espaco}
            // });
            this.totalAcordos = response.data.total;
            // this.$refs.indicadores.setIndicadores(response.data.indicadores);
            // if (this.usuarioTipo == "rd") {
            //     this.$refs.indicadores.setIndicadores(
            //         response.data.indicadores
            //     );
            // }
            this.indicadores = response.data.indicadores;

            if (this.acordos.length == 0 && this.pagination.page > 1) {
                this.filtrar();
            }
            this.fnLiberar = ((data.id_status[0] == 29) && (([1,2].includes(this.$store.getters.getUsuario.id_cargo)))) ? true : false;

            this.carregando = false;
        },
        async exportar() {
            if (!this.$refs.filtro.validate()) return;

            this.carregando = true;

            var filtro = this.$refs.filtro.filtro;

            var data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesFinal;
            data.tipoPeriodo = filtro.tipoPeriodo ? filtro.tipoPeriodo : -1;

            data.bandeira = filtro.bandeira;
            data.id_espaco = filtro.id_espaco;
            data.espaco_macro = filtro.espaco_macro;
            data.id_fornecedor = filtro.id_fornecedor;
            data.id_status = filtro.id_status ? filtro.id_status : [];
            data.id_acordo = filtro.id_acordo ? [filtro.id_acordo] : [];
            data.acordo_rd = filtro.acordo_rd ? [filtro.acordo_rd] : [];
            data.id_contrato = filtro.id_contrato ? [filtro.id_contrato] : [];
            data.id_cluster = filtro.id_cluster ? filtro.id_cluster : [];
            data.contrato = filtro.contrato ? [filtro.contrato] : [];
            data.planograma = this.planograma;
            data.statusIndicadores = this.statusIndicadores;

            var response = await this.$http().post("/acordo/exportar", {
                data: data,
            });

            window.location = this.$http("baseURL") + response.data.url;

            this.carregando = false;
        },
        async aprovarAcordo(data) {
            this.carregando = true;

            data.acordos = data.id_acordo
                ? [data.id_acordo]
                : this.acordoSelecionado
                    .filter((e) =>
                        this.statusAcesso(e.id_status, "aprovar_reprovar")
                    )
                    .map((a) => a.id_acordo);

            var response = await this.$http().post("/acordo/aprovarAcordo", {
                data: data,
            });

            if (response.data.sucesso) {
                this.filtrar(false);
            } else {
                this.alerta = true;
                this.alertaColor = "error";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;
            }

            this.acordoSelecionado = [];
            this.carregando = false;
        },
        dialogoLiberarAcordos(acordo = null) {
            let acordos = acordo ? [acordo] : this.acordoSelecionado.filter((e) =>
                    this.statusAcesso(e.id_status, "liberar") &&
                    e.mes_acordo == this.mesAtual
            )
            const total = acordos.reduce((a,e) => 
                    a += parseFloat(e.investimento.replace('R$ ','').replace('.','').replace(',','.'))
                ,0)
            acordos = acordos.map((a) => a.id_acordo);
            this.$refs.dialogo.liberar(acordos,total)
        },
        async liberarAcordo(acordos) {
            this.carregando = true;

            // data.acordos = data.id_acordo
            //     ? [data.id_acordo]
            //     : this.acordoSelecionado
            //           .filter(
            //               (e) =>
            //                   this.statusAcesso(e.id_status, "liberar") &&
            //                   e.mes_acordo == this.mesAtual
            //           )
            //           .map((a) => a.id_acordo);

            var response = await this.$http().post("/acordo/liberarAcordo", {
                data: {
                    acordos: acordos,
                },
            });

            if (response.data.sucesso) {
                await this.filtrar(false);
            } else {
                this.alerta = true;
                this.alertaColor = "error";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;
            }

            this.acordoSelecionado = [];
            this.carregando = false;
        },
        statusAcesso(id_status, acesso) {
            if (this.status) {
                if (id_status === 30 && acesso === "editar_planograma")
                    return false;

                var status = this.status.find((e) => e.id_status == id_status);

                return status[acesso];
            } else {
                return false;
            }
        },
        renovar(data) {
            this.$refs.formulario.editar(data.item);
        },
        async oportunidadeAcordo(item) {
            this.carregando = true;

            var response = await this.$http().post(
                "/acordo/oportunidadeAcordo",
                {
                    data: {
                        id_espaco: item.id_espaco,
                        mes_competencia: item.mes_acordo + "-01",
                        id_acordo: item.id_acordo,
                    },
                }
            );

            this.carregando = false;

            if (!response.data.success) {
                this.$dialog.notify.error(
                    "Ops! Falha ao buscar a oportunidade do acordo, por favor, tente novamente.",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );

                return;
            }

            this.$dialog.confirm({
                text: `Oportunidade: ${response.data.data}`,
                title: "Oportunidade Acordo (" + item.id_acordo + ")",
                actions: [{ text: "OK", color: "teal" }],

                color: "teal lighten-2",
            });
        },
    },
};
</script>

<style >
.theme--light.v-data-table.v-data-table--fixed-header thead th {
    background: var(--v-primary-base) !important;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 12%);
}

.involucroAcordo {
    height: 450px;
    display: flex;
    margin-top: 20px;
    /* flex-grow: 1; */
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
}

.involucroAcordo .flex-table {
    display: flex;
    flex-direction: column;
    /* flex-grow: 1; */
    height: 420px;
    width: 100%;
}

.involucroAcordo .flex-table>div {
    width: 100%;
}

.involucroAcordo header {
    top: 0px;
    background-color: #ffff;
    z-index: 4;
}
</style>