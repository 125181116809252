<template>
  <!-- <v-dialog
        v-model="dialogo"
        width="90%"            
    >  
        <v-card>
            <v-card-title class="subtitle-1 primary white--text">
                
            </v-card-title> -->
  <v-dialog content-class="br-15" v-model="dialogo" width="900">
    <v-card>
      <v-toolbar
        elevation="0"
        dark
        class="primary-degrade white--text fix-flex"
      >
        <v-toolbar-title> Importar Base de Produtos </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          fab
          width="24px"
          height="24px"
          elevation="0"
          light
          @click="dialogo = false"
        >
          <v-icon size="18px" color="primary">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-5 pb-0">
        <div class="instrucoes">
          <h4 color="primary">
            Para importar uma base de produtos, siga as instruções abaixo
          </h4>
          <ul>
            <li>
              A base utilizada para importação deve ser parecido ao modelo
              extraída abaixo (Modelo de Importação).
            </li>
            <li>
              As colunas <strong>codigo</strong> (Código do Produto) é
              obrigatório, as demais colunas podem ser excluidas.
            </li>

            <li>
              Para adicionar um <strong>produto</strong> ao
              <strong>acordo</strong>, adicione
              <strong>o código do produto</strong> na coluna
              <strong>codigo</strong> para o <strong>produto</strong> desejado.
            </li>
            <li>
              Para remover um <strong>produto</strong> do
              <strong>acordo</strong>, simplesmente remova a linha com o
              <strong>produto</strong>.
            </li>
          </ul>
        </div>
        <div v-if="!file">
          <div class="drop" @drag="onChange">
            <div :class="['dropZone', dragging ? 'dropZone-over' : '']">
              <div class="dropZone-info">
                <span class=""
                  >Arraste seu Arquivo CSV, ou então
                  <span class="clickable">clique aqui</span> para
                  selecionar.</span
                >
              </div>
              <input
                @dragenter="dragging = true"
                @dragleave="dragging = false"
                type="file"
                @change="onChange"
              />
            </div>
          </div>
        </div>
        <div v-else class="dropZone-uploaded">
          <div class="dropZone-uploaded-info d-flex">
            <span> {{ file.name }} </span>
            <span class="removeFile ml-3" @click="removeFile">
              <v-icon size="18" color="#ccc">mdi-close-circle</v-icon>
            </span>
          </div>
        </div>
        <v-alert
          type="error"
          transition="scale-transition"
          dismissible
          v-model="alerta"
        >
          {{ alertaMesagem }}
        </v-alert>
        <v-expand-transition>
          <div v-show="erroModelo">
            <v-row no-gutters class="mb-0">
              <v-col cols="6">
                <download-csv
                  :data="listaErro"
                  :labels="listaErroLabels"
                  name="erros.csv"
                  delimiter=";"
                >
                  <v-btn color="primary">
                    <v-icon left>mdi-microsoft-excel</v-icon>
                    Exportar Erros
                  </v-btn>
                </download-csv>
              </v-col>
              <v-col cols="6" align="end">
                <v-btn color="primary" @click="ignorarErrosProdutos()">
                  <v-icon left>mdi-alert-remove</v-icon>
                  Ignorar Erros
                </v-btn>
              </v-col>
            </v-row>
            <v-data-table
              :headers="listaHeaderErro"
              :items="listaErro"
              :items-per-page="5"
              :options.sync="paginationErro"
              class="elevation-0 data-table-2"
            ></v-data-table>
          </div>
        </v-expand-transition>

        <!-- </v-expand-transition> -->
      </v-card-text>

      <v-card-actions class="pb-4 pt-0">
        <v-btn
          color="yellow"
          dark
          @click="exportarModelo()"
          class="new-button ml-2"
        >
          <v-icon left>mdi-microsoft-excel</v-icon>
          Baixar modelo de importação
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="new-button mr-2"
          @click="importarProdutos()"
        >
          <v-icon left>mdi-upload</v-icon>
          Importar Produtos
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="carregando" :z-index="300">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      carregando: false,
      dadosTemp: [],
      produtoServidor: [],
      dialogo: false,
      csvModelo: null,
      isCsv: false,
      alerta: false,
      alertaMesagem: null,
      listaHeaderErro: [
        { text: "Descrição do Erro", value: "msg", align: "left" },
      ],

      listaErro: [],
      erroModelo: false,
      listaErroLabels: { msg: "Descrição do Erro" },
      paginationErro: {},
      file: "",
      dragging: false,
      regra: {
        csv: [
          (v) => !!v || "Selecione um arquivo.",
          (v) =>
            (v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
            "O arquivo deve ser do tipo CSV.",
        ],
      },
    };
  },
  methods: {
    exportarModelo() {
      this.$emit("exportarModelo");
    },
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      
      if (!files.length) {
        this.dragging = false;
        return;
      }
      
      this.createFile(files[0]);
    },
    createFile(file) {
        this.file = file;
      if (this.extension !== "csv") {
        console.log(file)
        this.alerta = true;
        this.alertaMesagem = "O arquivo deve ser do tipo CSV.";
        this.dragging = false;
        this.file = '';
        return;
      }
    
      console.log(this.file);
      this.dragging = false;
    },
    removeFile() {
      this.file = "";
    },
    abrir(produtoServidor) {
      this.produtoServidor = produtoServidor.map((a) => ({ ...a }));

      this.csvModelo = null;
      this.alerta = false;
      this.alertaMesagem = null;
      this.erroModelo = false;
      this.listaErro = [];
      this.dialogo = true;
      this.file = "";
      this.dadosTemp = [];
    },
    async importarProdutos() {
      this.alerta = false;
      this.alertaMesagem = null;
      this.erroModelo = false;
      this.listaErro = [];
      this.dadosTemp = [];

      const parseCsv = async (csv) => {
        return new Promise((resolve) => {
          this.$papa.parse(csv, {
            delimiter: ";",
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: function (results) {
              resolve(results);
            },
          });
        });
      };

      var csv = await parseCsv(this.file);

      if (csv.errors.length) {
        this.alerta = true;
        this.alertaMesagem =
          "Erro ao ler o arquivo CSV. Exporte novamente o modelo e importe o modelo editado.";
      } else {

         csv.data.forEach(element => {
            const produto = this.produtoServidor.find(p => p.codigo == element.codigo);
            if (produto) {
              produto.selecionado = true;
            } 
            // check if product exists in dadosTemp
            const produtoTemp = this.dadosTemp.find(p => p.codigo == element.codigo);
            if(!produtoTemp){

              this.dadosTemp.push(produto);
            }
         });


        if (this.listaErro.length > 0) {
          this.paginationErro.page = 1;
          this.erroModelo = true;
        } else {
          this.$emit("importar");

          this.dialogo = false;
        }
      }
    },
    ignorarErrosProdutos() {
      this.$emit("importar");

      this.dialogo = false;
    },
  },
  computed: {
    extension() {
      return this.file ? this.file.name.split(".").pop().toLowerCase() : "";
    },
  },
};
</script>
