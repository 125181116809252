import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import VueGtag from "vue-gtag";

import Login from "../views/Login.vue";
import Main from "../views/Main.vue";
import Home from "../views/Home.vue";
import Perfil from "../views/Perfil.vue";
import AcordoAPI from "../views/acordo/Api.vue";
import AcordoPDV from "../views/acordo/Lista.vue";
import AcordoEcommerce from "../views/acordo/ecommerce/Lista.vue";
import AcordoCampanha from "../views/acordo/campanha/Lista.vue";

import Oportunidade from '../views/gerencial/oportunidade/Lista.vue'
import Auditoria from '../views/gerencial/auditoria/Lista.vue'
import Investimento from '../views/gerencial/investimento/Lista.vue'
import Extrato from '../views/gerencial/extrato/Lista.vue'
import Vb from '../views/gerencial/vb/Lista.vue'
import Acessos from '../views/gerencial/acessos/Lista.vue'
import PontasTematicas from '../views/gerencial/pontas-tematicas/Lista.vue'
import BasesSFTP from '../views/administrativo/bases-sftp/Lista.vue'

import Espaco from '../views/cadastro/espaco/Lista.vue';
import EspacoEcommerce from '../views/cadastro/espacoEcommerce/Lista.vue';
import Custo from '../views/cadastro/custo/Lista.vue';
import Filial from '../views/cadastro/filial/Lista.vue';
import Fornecedor from '../views/cadastro/fornecedor/Lista.vue';
import Inventario from '../views/cadastro/inventario/Lista.vue';
import Usuario from '../views/cadastro/usuario/Lista.vue';
import Macrofornecedor from '../views/cadastro/macrofornecedor/Lista.vue';
import Permissao from '../views/cadastro/permissao/Lista.vue';
import manual from '../views/cadastro/manual/Lista.vue';
import lembrete from '../views/cadastro/lembrete/Lista.vue';
import usuarioFornecedor from '../views/cadastro/usuarioFornecedor/Lista.vue';
import LojasEcommerce from '../views/cadastro/LojasEcommerce/Lista.vue';
import precoCampanha from '../views/cadastro/precoCampanha/Lista.vue';
import precoTabloide from '../views/cadastro/precoTabloide/Lista.vue';
import simulacaoResumo from '../views/simulacao/resumo/Lista.vue';
import simulacaoPdv from '../views/simulacao/pdv/Lista.vue';
import simulacaoTabloide from '../views/simulacao/tabloide/Lista.vue';
import simulacaoCampanha from '../views/simulacao/campanha/Lista.vue';
import simulacaoEcommerce from '../views/simulacao/ecommerce/Lista.vue';
import justificativasApp from '../views/app/justificativas/Lista.vue'
import avisosApp from '../views/app/avisos/Lista.vue'
import logsApp from '../views/app/logs/Lista.vue'
import materialCampanhas from '../views/app/materialCampanhas/Lista.vue'
import plantasApp from '../views/app/plantas/Lista.vue'
import simulacaoGerencial from '../views/gerencial_simulador/Lista.vue';
import Arquivo from '../views/arquivo.vue';
import planejamentoAtivacao from '../views/planejamento_ecommerce/cadastro/ativacao/Lista.vue';
import planejamentoCategoria from '../views/planejamento_ecommerce/cadastro/categoria/Lista.vue';
import planejamentoArea from '../views/planejamento_ecommerce/cadastro/areas/Lista.vue';
import PlanejamentoInventario from '../views/planejamento_ecommerce/inventario/Lista.vue';
import PlanejamentoEcommerce from '../views/planejamento_ecommerce/planejamento/Lista.vue';
import PlanejamentoGerencial from '../views/planejamento_ecommerce/gerencial/Lista.vue';
import pesquisas from '../views/app/pesquisas/Lista.vue'
import bibliotecaTermos from '../views/planejamento_ecommerce/cadastro/termos/Lista.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: { name: 'login' } },
  { path: '/login', name: 'login', component: Login },
  { path: '/arquivo', name: 'arquivo', component: Arquivo },
  {
    path: '/main',
    name: 'main',
    component: Main,
    children: [
      { path: "/api", name: "api", component: AcordoAPI },
      { path: "/home", name: "home", component: Home },
      { path: "/perfil", name: "perfil", component: Perfil },

      { path: '/oportunidade', name: 'oportunidade', component: Oportunidade },
      { path: '/auditoria', name: 'auditoria', component: Auditoria },
      { path: '/investimento', name: 'investimento', component: Investimento },
      { path: '/vb', name: 'vb', component: Vb },
      {path: '/pontastematicas', name: 'pontastematicas', component: PontasTematicas},
      { path: '/basessftp', name: 'basessftp', component: BasesSFTP},
      { path: '/acessos', name: 'acessos', component: Acessos },
      { path: '/espaco', name: 'espaco', component: Espaco },
      { path: '/espacoEcommerce', name: 'espacoEcommerce', component: EspacoEcommerce },
      { path: '/custo', name: 'custo', component: Custo },
      { path: '/filial', name: 'filial', component: Filial },
      { path: '/fornecedor', name: 'fornecedor', component: Fornecedor },
      { path: '/inventario', name: 'inventario', component: Inventario },
      { path: '/extrato', name: 'extrato', component: Extrato },
      { path: '/usuario', name: 'usuario', component: Usuario },
      { path: '/lojasecommerce', name: 'lojasecommerce', component: LojasEcommerce },
      {path: '/app/justificativas', name: 'appJustificativas', component: justificativasApp},
      {path: '/app/avisos', name: 'appAvisos', component: avisosApp},
      {path: '/app/plantas', name: 'appPlantas', component: plantasApp},
      {path: '/app/logs', name: 'appLogs', component: logsApp},
      { path: '/simulacaoGerencial/gerencial', name: 'simulacaoGerencial', component: simulacaoGerencial },
      {path: '/app/material_campanhas', name: 'materialCampanhas', component: materialCampanhas},
      {path: '/app/pesquisas', name: 'pesquisas', component: pesquisas},

      {
        path: '/macrofornecedor',
        name: 'macrofornecedor',
        component: Macrofornecedor,
      },
      { path: '/permissao', name: 'permissao', component: Permissao },
      { path: '/manual', name: 'manual', component: manual },
      { path: '/lembrete', name: 'lembrete', component: lembrete },
      {
        path: '/usuarioFornecedor',
        name: 'usuarioFornecedor',
        component: usuarioFornecedor,
      },
      {
        path: '/precoCampanha',
        name: 'precoCampanha',
        component: precoCampanha,
      },
      {
        path: '/precoTabloide',
        name: 'precoTabloide',
        component: precoTabloide,
      },
      // {path: '/acordo', name: 'acordo', component: Acordo},
      {
        path: '/acordo',
        name: 'acordo',
        component: () => import('@/views/acordo/Main.vue'),
        children: [
          { path: 'pdv', name: 'acordoPdv', component: AcordoPDV },
          { path: 'ecommerce', name: 'acordoEcommerce', component: AcordoEcommerce },
          { path: 'campanha', name: 'acordoCampanha', component: AcordoCampanha },

        ],
      },
      {
        path: '/simulacao',
        name: 'simulacao',
        component: () => import('@/views/simulacao/Main.vue'),
        children: [
          {
            path: 'resumo',
            name: 'simulacaoResumo',
            component: simulacaoResumo,
          },
          {
            path: 'pdv',
            name: 'simulacaoPdv',
            component: simulacaoPdv,
          },
          {
            path: 'tabloide',
            name: 'simulacaoTabloide',
            component: simulacaoTabloide,
          },
          {
            path: 'campanha',
            name: 'simulacaoCampanha',
            component: simulacaoCampanha,
          },
          {
            path: 'ecommerce',
            name: 'simulacaoEcommerce',
            component: simulacaoEcommerce,
          },
        ],
      },

      {
        path: '/simuladorBR',
        name: 'simuladorBR',
        redirect: { name: "simuladorInformacoesGerais" },
        component: () => import('@/views/informacoes-orientacoes/Main.vue'),
        children: [
          {
            path: 'informacoesGerais',
            name: 'simuladorInformacoesGerais',
            component: () => import('@/views/informacoes-orientacoes/InformacoesGerais.vue')
          },
          {
            path: 'orientacoes',
            name: 'simuladorOrientacoes',
            component: () => import('@/views/informacoes-orientacoes/Orientacoes.vue'),
          },
        ]
      },
      // {path: '/planejamentoEcommerce/ativacao', name: 'planejamento_ativacao', component: planejamentoAtivacao},
      // {path: '/planejamentoEcommerce/categoria', name: 'planejamento_categoria', component: planejamentoCategoria},
      {path: '/planejamentoEcommerce/inventario', name: 'planejamento_inventario', component: PlanejamentoInventario},
      {path: '/planejamentoEcommerce', name: 'planejamento', component: PlanejamentoEcommerce},
      {path: '/planejamentoEcommerce/gerencial', name: 'planejamento_gerencial', component: PlanejamentoGerencial},
    
      {
        path: '/planejamentoEcommerce/cadastro',
        name: 'planejamentoEcommerce',
        component: () => import('@/views/planejamento_ecommerce/cadastro/Main.vue'),
        children: [
          {
            path: 'ativacao',
            name: 'planejamento_ativacao',
            component: planejamentoAtivacao,
          },
          {
            path: 'categoria',
            name: 'planejamento_categoria',
            component: planejamentoCategoria,
          },
          {
            path: 'area',
            name: 'planejamento_areas',
            component: planejamentoArea,
          },
          {
            path: 'termos',
            name: 'biblioteca_termos',
            component: bibliotecaTermos,
          }
        ]
        }
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

Vue.use(VueGtag, {
  config: { 
    id: "G-X79J63FTYH",
  },
  pageTrackerTemplate(to) {
		return {
			page_title: to.name,
			page_path: to.path
		};
	}
  

}, router);


router.beforeEach((to, from, next) => {
  if (store.getters.getLogado) {
    next();
  } else {
    if (to.path != '/' && to.path != '/login') {
      next({ name: 'login' });
    } else {
      next();
    }
  }
});

export default router;