<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Usuários</v-toolbar-title>
            </v-col>   
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"
                >
                    <v-icon left>mdi-plus-thick</v-icon> Criar usuário
                </v-btn>
                <v-btn
                    class="ml-2 my-1"
                    color="primary"                     
                    @click="exportar()"   
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar                    
                </v-btn> 
                <v-btn
                    class="ml-2 my-1"
                    color="primary"                     
                    @click="dialogoConfigEnvioGuia = true"   
                >
                    <v-icon left>mdi-cog</v-icon> Config Envio Guia                    
                </v-btn> 
                <v-btn
                    class="ml-2 my-1"
                    color="primary"   
                            
                    @click="dialogoImportar = true "   
                >
                    <v-icon left>mdi-upload</v-icon> Importar                    
                </v-btn>                 
            </v-col>               
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">  
            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="12" md="6" lg="8" xl="8">
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="4" xl="4" align="end">                     
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        dense 
                        outlined
                        hide-details
                    ></v-text-field>     
                </v-col>            
            </v-row>          
            <v-data-table
                :headers="headers"
                :items="dados"
                :items-per-page="5"
                :search="busca"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >   
                <template v-slot:[`item.botao`]="{ item }"> 
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"   
                        @click="editar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        class="ml-4 mr-2"
                        color="primary"   
                        @click="dialogoRedefinir=true; usuario={...item};"
                        title="Redefinir Senha"
                    >
                        mdi-lock-reset
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

         <v-dialog
            v-model="dialogoImportar"
            width="90%"            
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Importar CSV 
                </v-card-title>
                <v-card-text>
                    <v-alert
                        class="mt-4"
                        text
                        type="info"
                        border="left"
                        style="font-size: 12px;"
                    >
                        <ul>
                            <li>
                                A base utilizada para importação deve ser parecida com base exportada. As colunas <strong>codigo_rd</strong> (Código RD) , <strong>Nome</strong> , <strong>Login</strong> , <strong>Ativo</strong>  são obrigatórias (<strong>e os nomes devem ser identicos aos da base exportada</strong>).
                            </li>
                           
                        </ul>  
                    </v-alert> 
                    <v-row no-gutters>  
                        <v-col cols="8" class="px-2">
                            <v-form ref="form" v-model="isCsv">
                                <v-file-input 
                                    label="Arquivo CSV" 
                                    outlined 
                                    dense
                                    v-model="arquivoCSV"
                                    :rules="regra.csv"
                                    accept=".csv"
                                ></v-file-input>
                            </v-form>
                        </v-col> 
                        <v-col cols="4" class="px-2">
                            <v-btn
                                color="primary"
                                :disabled="!isCsv"
                                @click="importar()"
                            >
                                <v-icon left>mdi-upload</v-icon> 
                                Importar Arquivo
                            </v-btn>
                        </v-col> 
                    </v-row> 
                    <v-expand-transition>
                        <div v-show="erro">                            
                            <v-row no-gutters class="mb-0">  
                                <v-col cols="6">
                                    <download-csv
                                        :data="erros"
                                        :labels="errosLabels"
                                        name= "erros.csv"
                                        delimiter=";"
                                    >
                                        <v-btn color="primary">
                                            <v-icon left>mdi-microsoft-excel</v-icon>
                                            Exportar Erros
                                        </v-btn>
                                    </download-csv>
                                </v-col>
                            </v-row> 
                               <v-data-table                            
                                :headers="headerErros"
                                :items="erros"
                                :items-per-page="5"
                                :options.sync="paginationErros" 
                                class="elevation-0 data-table-2"
                            ></v-data-table>
                          
                        </div>
                    </v-expand-transition>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>                    
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoImportar=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogoConfigEnvioGuia"
            width="60%"            
        >  
            <ConfigEnvioGuia v-model="dialogoConfigEnvioGuia"/>
                    
            
        </v-dialog>

        <v-dialog
            v-model="dialogo"
            width="600"            
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Usuário
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-form v-model="validaForm"> 
                        <v-row no-gutters class="mt-4">                                
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2">
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    label="Id Usuário"
                                    v-model="usuario.id_usuario"
                                ></v-text-field> 
                            </v-col>                                          
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2">
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Nome"
                                    v-model="usuario.nome"
                                    :rules="regra.nome"
                                    required
                                ></v-text-field> 
                            </v-col>                                          
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Login"
                                    v-model="usuario.login"
                                    :rules="regra.login"
                                    required
                                ></v-text-field> 
                            </v-col>                                             
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    label="CPF"
                                    v-model="usuario.cpf"
                                    v-mask="'###########'"
                                ></v-text-field> 
                            </v-col>                                             
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    label="E-mail"
                                    v-model="usuario.email"
                                ></v-text-field> 
                            </v-col> 
                           <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    placeholder="(11)999999999"
                                    label="Telefone"
                                    v-model="usuario.telefone"
                                    v-mask="'(##)#########'"
                                ></v-text-field> 
                            </v-col>                                                 
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                              
                                <v-select
                                 label="Cargo"
                                :items="lista.cargo"
                                 v-model="usuario.id_cargo"
                                 
                                 outlined
                                 dense 
                                 append-outer-icon="mdi-plus"
                                @click:append-outer="dialogoAddCargo=true"
                                >
                                   <template v-slot:item="{item}">                                 
                                         <v-container>
                                             <v-row>
                                                 <v-col>
                                                    {{item.text}}
                                         
                                                 </v-col>

                                                    <v-col justify="end" align="end">
                                                     <v-icon                                         
                                                        small
                                                        class="mr-2"
                                                        color="error"   
                                                        @click="excluirCargo(item)"
                                                        title="Excluir"
                                                    >
                                                        mdi-delete-forever
                                                    </v-icon>
                                         
                                                 </v-col>
                                             </v-row>
                                         </v-container>
                                       
                                        
                                   </template>    
                        
                                </v-select>

                           <v-dialog
                                v-model="dialogoAddCargo"
                                width="600"            
                            >  
                                <v-card>
                                    <v-card-title class="subtitle-1 primary white--text">
                                        Adicionar um cargo
                                    </v-card-title>
                                    <v-card-text class="mt-4"> 
                                           <v-text-field
                                                v-model="cargo.nome"
                                                append-icon="mdi-plus"
                                                label="Novo cargo"
                                                dense 
                                                outlined
                                                hide-details
                                            ></v-text-field>  
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="primary"
                                            class="pr-4"
                                            @click="dialogoAddCargo = false"
                                        >
                                            <v-icon left>mdi-close</v-icon> Fechar
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            class="pr-4"
                                            @click="adicionarCargo()"
                                        >
                                            <v-icon left>mdi-content-save</v-icon> Salvar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                                
                            </v-col> 
                                                                              
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2">                                
                                <v-autocomplete
                                    
                                    label="Fornecedor"
                                    :items="lista.fornecedor"
                                    item-text="label"
                                    item-value="id_fornecedor"
                                    v-model="usuario.id_fornecedor"
                                    :rules="regra.codigo"
                                    outlined
                                    dense 
                                >
                                </v-autocomplete>
                            </v-col>                                                      
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                                <v-select
                                    label="Ativo"
                                    :items="lista.ativo"
                                    v-model="usuario.ativo"
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>         
                        </v-row>  
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        :disabled="!validaForm"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogoRedefinir"
            width="600"            
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Redefinir Senha
                </v-card-title>
                <v-card-text class="mt-4"> 
                    Você realmente deseja redefinir a senha para o padão inicial? 
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoRedefinir = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="redefinirSenha()"
                    >
                        <v-icon left>mdi-lock-reset</v-icon> Redefinir Senha
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>        
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>  

    </v-container>
</template>

<script>
import ConfigEnvioGuia from './ConfigEnvioGuia.vue'
export default {
    
    data() {
        return {
            
            dialogoImportar: false,
            dialogoConfigEnvioGuia: false,
            arquivoCSV: null,
            isCsv: false,            
            headerErros: [
                { text: 'Descrição do Erro', value: 'msg', align: 'left'},
            ],
            erros: [],
            paginationErros: {}, 
            errosLabels: {msg: 'Descrição do Erro'}, 
            erro: false,
            headers: [
                { text: 'ID Usuário', value: 'id_usuario', align: 'center'},
                { text: 'Nome', value: 'nome', align: 'center'},
                { text: 'Login', value: 'login', align: 'center'},
                { text: 'CPF', value: 'cpf', align: 'center'},
                { text: 'E-mail', value: 'email', align: 'center'},
                { text: 'Cargo', value: 'cargo', align: 'center'},
                { text: 'Ativo', value: 'ativo_nome', align: 'center'},
                { text: '', value: 'botao', align: 'center', sortable: false },
            ],
            dados: [], 
            lista: {
                ativo: [
                    { text: 'Sim', value: 1},
                    { text: 'Não', value: 0},
                ],
                cargo: [],
                fornecedor:[]
            },
            fornecedor:[],
            usuario: {
                id_usuario: null,
                nome: null,
                login: null,
                cpf: null,
                email: null,
                cargo: null,
                ativo: null,
            },
            pagination: {}, 
            carregando: false, 
            busca: null, 
            dialogo: false,
            dialogoRedefinir: false,
            dialogoAddCargo: false,
            validaForm: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            regra: { 
                nome: [v => !!v || 'O campo nome é obrigatório.'],
                login: [v => !!v || 'O campo login é obrigatório.'],
                codigo: [v => !!v || 'O campo Fornecedor é obrigatório.'],
              
            },  
            cargo:{},
        }
    },
    components: {
        ConfigEnvioGuia
    },
    async created() { 
        
        this.buscarCargo()
       
        
        this.filtrar()      
    },
    methods: {
        
        configEnvioGuia() {
            this.dialogoConfigEnvioGuia = true;
        },
         async importar() {
            
            this.carregando = true
            
          
            
            var formData = new FormData()

            formData.append("files", this.arquivoCSV)
         


            this.arquivoCSV = null
        
          

            var response = await this.$http().post('/usuario/fornecedor/importar', formData)  
            
            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogoImportar = false
            }
            else{

                this.filtrar()
                this.erros = response.data.erros
                this.pagination.page = 1
                this.erro = true
            }  
        }, 
      
        async filtrar() {

            this.carregando = true

            this.pagination.page = 1

            var data = {}


            var response = await this.$http().post('/usuario/fornecedor/lista', {data: data})   

            this.lista.fornecedor = (await this.$http().post('/fornecedor/usuarioFornecedor/lista')).data 
         
            this.lista.fornecedor =  this.lista.fornecedor.map((val) => ({
                ...val,
                label: val.codigo +"-" + val.nome,
                }));
            
            this.dados = response.data           

            this.carregando = false
        },
        async exportar() {
           
            this.carregando = true
            
            var data = {}

            var response = await this.$http().post('/usuario/fornecedor/exportar', {data: data})   
          
            window.location = this.$http('baseURL')+response.data.url
            
            this.carregando = false
        }, 
        novo() {
           
            this.usuario = {
                id_usuario: null,
                nome: null,
                login: null,
                cpf: null,
                email: null,
                cargo: null,
                ativo: 1,
            }
            
            this.dialogo = true          
        }, 
        editar(item) {
           
            this.usuario = {...item}
            
            this.dialogo = true          
        }, 
        async salvar() {
            
            this.carregando = true

            var data = this.usuario

            var response = await this.$http().post('/usuario/fornecedor/salvar', {data: data})   
            
            if(response.data.sucesso){

                this.filtrar()

                this.dialogo = false  
            }
            else{

                this.carregando = false
                
                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        }, 
        async redefinirSenha() {
            
            this.carregando = true

            var data = this.usuario

            var response = await this.$http().post('/usuario/redefinirSenha', {data: data})   
            
            this.carregando = false
            this.dialogoRedefinir = false
            
            if(response.data.sucesso){

                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = 1000
                this.alertaTexto = response.data.msg  
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        },
        
        async buscarCargo(){
         var response = await this.$http().post('/usuario/fornecedor/cargo', {}) 

          this.lista.cargo = response.data
       
        },
        async excluirCargo(item){
            this.carregando = true
           
            var data = {}
            
            data.id_cargo = item.value

            var response = await this.$http().post('/usuario/excluir/fornecedor/cargo', {data: data})   

             if(response.data.sucesso){
                      
                 this.buscarCargo() 
                this.carregando = false
                this.dialogoAddCargo = false
                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = 1000
                this.alertaTexto = response.data.msg  
            }
            else{
                
                this.carregando = false
           
                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        },
        async adicionarCargo(){
            this.carregando = true

            var data = this.cargo

            var response = await this.$http().post('/usuario/novo/fornecedor/cargo', {data: data})   

       
             if(response.data.sucesso){
                      
                 this.buscarCargo() 
                this.carregando = false
                this.dialogoAddCargo = false
                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = 1000
                this.alertaTexto = response.data.msg  
            }
            else{
                
                this.carregando = false
           
                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        }
    },
}
</script>