<template>
  <v-card class="my-0 br-15 pa-5 pt-3 overflow-hidden" elevation="0">
    <div style="height: 400px; position: relative">
      <InfoBox
        ><span class="titulo">Ativação do perfil loja: </span> Gráfico com os
        percentuais e valores de invenstimentos por cluster de lojas.
      </InfoBox>
      <h2 class="text-center" v-if="empty" style="font-weight: normal">
        Ativação do <br />Perfil Loja ({{ ano }})
      </h2>
      <div class="perfil-frame" v-if="mostrarInfo"></div>

      <highcharts :options="grafico" ref="chartPerfilLoja" v-if="mostrarInfo" />
      <Overlay :overlay="overlay" :error="error" :empty="empty"></Overlay>
    </div>
  </v-card>
</template>

<script>
import colors from "vuetify/es5/util/colors";
import Overlay from "./Overlay.vue";
import InfoBox from "./InfoBox.vue";

export default {
  components: {
    Overlay,
    InfoBox,
  },
  props: {
    ano: Number,
    fornecedor: Object,
  },
  data() {
    return {
      overlay: true,
      error: false,
      empty: false,
      grafico: {
        chart: {
          type: "column",
          backgroundColor: "transparent",

          //plotBackgroundColor: "#FFFFFF",
          //plotBorderWidth: "#FFFFFF",
          //plotShadow: false,
        },
        title: {
          align: "center",
          text: `Ativação do <br>Perfil Loja (${this.ano})`,
        },

        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },

        xAxis: {
          type: "category",
          labels: {},
        },
        yAxis: {
          visible: false,
        },
        exporting: {
          chartOptions: {
            legend: {
              enabled: true,
            },
          },
          buttons: {
            contextButton: {
              menuItems: [
                "viewFullscreen",
                "print",
                "downloadPNG",
                "downloadJPEG",
                "downloadPDF",
                "downloadCSV",
                "downloadXLS",
              ],
            },
          },
        },
        legend: {
          enabled: false,
        },
        colors: [
          "#b1e3c8",
          "#6eca97",
          "#2f988b",
          "#24786f",
          "#fbc17a",
          "#2d9ac7",
        ],
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: "8px",
            borderRadiusTopRight: "8px",
            borderWidth: 0,
            pointPadding: 0,
            dataLabels: {
              enabled: true,
              align: "center",
              //format:
              //'<span style="text-align: center; color: {point.color}">{point.y:.0f}%</span><br><span style="color:#808080" >{point.valor}</span>',
              // format: '{point.y:.1f}% <br> {point°.valor}'
              // format: '<span>{point.y:.1f}% </span><br> {point.valor}'
              formatter: function () {
                return `
                  <span style="text-align: center; color: ${this.color}">${
                  this.y
                }%</span><br>
                  <span style="color:#808080" >${this.point.valor.toLocaleString()}</span>
                `;
              },
            },
          },
        },

        tooltip: {
          enabled: false,
          headerFormat: '<span style="color:{point.color}">{point.name}</span>',
          pointFormat: "<b>{point.y:.2f}%</b> of total<br/>",
        },

        series: [
          {
            name: "Perfil Loja",
            colorByPoint: true,
            data: [],
          },
        ],
      },
      graficoOld: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          height: 210,
        },
        title: {
          text: "",
        },
        tooltip: {
          pointFormatter: function () {
            return (
              "<b>" +
              this.y.toLocaleString() +
              " - </b> (" +
              this.percentage.toFixed(2) +
              " %)"
            );
          },
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            //colors: Object.values(colors.teal),
            colors: [
              colors.pink.darken2,
              colors.grey.base,
              colors.teal.lighten1,
            ],

            dataLabels: {
              enabled: true,
              //format: "<br>{point.p} %",
              distance: -20,
              filter: {
                property: "percentage",
                operator: ">",
                value: 3,
              },
              formatter: function () {
                return "<br> " + this.percentage.toFixed(2) + " %";
              },
            },
            showInLegend: true,
          },
        },
        legend: {
          align: "left",
          floating: false,
          layout: "horizontal",
          labelFormatter: function () {
            return this.name + " - " + this.y.toLocaleString();
          },
        },
        series: [
          {
            name: "Share",
            data: [],
          },
        ],
      },
    };
  },
  methods: {
    async getDados() {
      this.overlay = true;
      this.empty = false;
      this.error = false;

      const response = await this.$http().post(
        "/simulacao/resumo/ativacaoLoja",
        {
          data: {
            id_fornecedor: this.fornecedor.value,
            ano_atual: this.ano,
          },
        }
      );

      if ((response || { status: 500 }).status != 200) {
        this.overlay = false;
        return;
      }

      if (response.data.length == 0) {
        this.empty = true;
        return (this.overlay = false);
      }

      const _tt = response.data.reduce(function (total, num) {
        return {
          valor: parseFloat(total.valor) + parseFloat(num.valor),
        };
      });

      const _toData = response.data.map((e) => {
        return {
          name: e.name + "<br> ",
          valor: parseFloat(e.valor),
          y:
            _tt.valor > 0
              ? this.$helpers.roundDecimal(
                  (parseFloat(e.valor) / _tt.valor) * 100,
                  2
                )
              : 0,
        };
      });

      this.grafico.series[0].data = _toData;

      this.overlay = false;
    },
  },
  computed: {
    mostrarInfo() {
      return !this.error && !this.empty && !this.overlay;
    },
  },
  watch: {
    $props: {
      handler() {
        if (this.ano != null) this.getDados();
      },
      deep: true,
      immediate: false,
    },
  },
};
</script>
<style scoped>
.perfil-frame {
  top: 60px;
  width: 100%;
  height: calc(100% - 60px);
  border: 2px solid #f2f2f2;
  border-radius: 15px;
  position: absolute;
  overflow: hidden;
}
.perfil-frame::before {
  content: "";
  width: 100%;
  height: 50px;
  background: #f2f2f2;
  bottom: 0;
  position: absolute;
}
</style>
